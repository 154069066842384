import { AsYouType, parsePhoneNumber } from 'libphonenumber-js';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
export var inputHasText = function (input) {
    var correctedInput = isString(input) || isNumber(input) ? input : '';
    var removeLineBreaks = correctedInput.toString().replace(/(\r\n|\n|\r)/gm, ' ');
    return Boolean(removeLineBreaks.trim());
};
export var ellipsisOnLongText = function (text, maxLength) {
    var correctedText = isString(text) ? text : '';
    var removeLineBreaks = correctedText.replace(/(\r\n|\n|\r)/gm, ' ');
    var removeExtraSpaces = removeLineBreaks.replace(/\s\s+/g, ' ');
    var removeExtraSpaces2 = removeExtraSpaces.trim();
    var textLength = removeExtraSpaces2.length;
    var ellipsis = textLength > maxLength ? '...' : '';
    var textToShow = textLength > maxLength
        ? removeExtraSpaces2.substring(0, maxLength - ellipsis.length) + ellipsis
        : removeExtraSpaces2;
    return textToShow;
};
export var containsText = function (text, searchText) {
    var correctedText = isString(text) ? text : '';
    var correctedSearchText = isString(searchText) ? searchText : '';
    var removeLineBreaks = correctedText.replace(/(\r\n|\n|\r)/gm, ' ');
    var removeExtraSpaces = removeLineBreaks.replace(/\s\s+/g, ' ');
    var removeExtraSpaces2 = removeExtraSpaces.trim();
    return removeExtraSpaces2.toLocaleLowerCase().includes(correctedSearchText.toLocaleLowerCase());
};
export var formatCurrency = function (value, currency, lang) {
    if (currency === void 0) { currency = 'USD'; }
    if (lang === void 0) { lang = 'en-US'; }
    return new Intl.NumberFormat(lang, {
        style: 'currency',
        currency: currency,
    }).format(value);
};
export var capitalizeFirstLetter = function (string, lowercase) {
    if (lowercase === void 0) { lowercase = true; }
    string = lowercase ? string.toLowerCase() : string;
    return string.charAt(0).toUpperCase() + string.slice(1);
};
export var textLinkHTML = function (text, links) {
    if (links === void 0) { links = []; }
    var result = links.reduce(function (acc, _a, index) {
        var linkText = _a[0], linkHref = _a[1];
        return acc.replace("$[".concat(index, "]"), "<a href=\"".concat(linkHref, "\" target=\"_blank\">").concat(linkText, "</a>"));
    }, text);
    if (links.length && result === text)
        console.warn('text does not contain any interpolations $[0]');
    if (result.includes('$['))
        console.warn('text contians incorrect interpolation');
    return result;
};
export var formatPhoneNumber = function (value, countryCallingCode, useCountryCode) {
    if (useCountryCode === void 0) { useCountryCode = true; }
    return value ? new AsYouType(useCountryCode ? countryCallingCode : undefined).input(value) : '';
};
export var getCountryFromPhoneNumber = function (phoneNumber) {
    try {
        var asYouType = new AsYouType();
        asYouType.input(String(phoneNumber));
        return asYouType.country || parsePhoneNumber(String(phoneNumber)).country;
    }
    catch (_a) {
        return;
    }
};
export var removeAllSpaces = function (value) {
    return value.replace(/\s/g, '');
};
export var removeAllSpecialCharacter = function (str) {
    return str.replace(/[^a-zA-Z ]/g, '');
};
export var removeDashesAndParentheses = function (text) {
    var correctedText = isString(text) ? text : '';
    var removeLineBreaks = correctedText.replace(/(\r\n|\n|\r)/gm, ' ');
    return removeLineBreaks.replace(/[-()]/g, '').trim();
};
export var removePhoneCharacters = function (text) {
    text = removeDashesAndParentheses(text);
    text = removeAllSpaces(text);
    return text;
};
export var numberWithCommas = function (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
