//THIS FILE CONTAINS BUSINESS LOGIC AND DATA VALIDATION FUNCTIONS
//SHOULD BE MOVED TO SERVER SIDE IN FUTURE VERSIONS
import { WareHouseIds } from '../config';
var enabledDropoffIds = [WareHouseIds.CarlsbadWarehouse, WareHouseIds.FashionIslandStudio];
var enabledPickupIds = enabledDropoffIds;
export var isShowroomAssistantDropoffEnabled = function (id) {
    return !!id && enabledDropoffIds.includes(id);
};
export var isShowroomAssistantPickupEnabled = function (queryParams) {
    var warehouseId = Number(queryParams['warehouse-id']);
    var isPickupParamFound = queryParams['pickup'] && queryParams['pickup'] === 'true';
    return !!warehouseId && enabledPickupIds.includes(warehouseId) && isPickupParamFound;
};
