//THIS api interceptor is used for admin (Google) authentication, not the normal lapis admin page
//We should use the cookie (portal-token) instead the user token in the headers for all these routes
import axios from 'axios';
import { transformErrors } from 'vue2/helpers';
import Vue from 'vue';
var localBaseURL = '/admin/';
var adminInstance = axios.create({
    baseURL: localBaseURL,
});
// a response interceptor
adminInstance.interceptors.response.use(function (resp) { return resp; }, function (error) {
    var _a, _b;
    return Promise.reject(transformErrors(((_a = error.response) === null || _a === void 0 ? void 0 : _a.data) || error, (_b = error.response) === null || _b === void 0 ? void 0 : _b.status));
});
// a request interceptor interceptor
adminInstance.interceptors.request.use(function (config) {
    config.headers['Api-Key'] = process.env.MIX_API_KEY;
    config.headers['Accept'] = 'application/x.fashionphile.v1+json';
    config.headers['authToken'] = Vue.$cookies.get(PORTAL_REFRESH_TOKEN);
    return config;
});
export default adminInstance;
export var PORTAL_REFRESH_TOKEN = 'portal-token';
