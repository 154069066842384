var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import isEmpty from 'lodash/isEmpty';
import first from 'lodash/first';
import pickBy from 'lodash/pickBy';
import { capitalizeFirstLetter } from 'vue2/helpers';
import UnknownImage from 'main/assets/images/unknown.png';
export var getPredictions = function (imageResponse) {
    return (imageResponse === null || imageResponse === void 0 ? void 0 : imageResponse.prediction) || [];
};
export var getProducts = function (imagePrediction) {
    var _a;
    return (_a = imagePrediction === null || imagePrediction === void 0 ? void 0 : imagePrediction.priceData) === null || _a === void 0 ? void 0 : _a.product;
};
export var getMatchingProduct = function (imageResponse, productId) {
    var _a;
    var foundPrediction = getMatchingPrediction(imageResponse, productId);
    return (_a = foundPrediction === null || foundPrediction === void 0 ? void 0 : foundPrediction.priceData) === null || _a === void 0 ? void 0 : _a.product;
};
export var getMatchingPrediction = function (imageResponse, productId) {
    var predictions = getPredictions(imageResponse);
    var foundPrediction = predictions.find(function (prediction) {
        var product = getProducts(prediction);
        return product && product.id === productId;
    });
    return foundPrediction;
};
export var compareColors = function (color1, color2, loose) {
    if (loose === void 0) { loose = false; }
    var formattedColor1 = color1.toLocaleLowerCase().replace(/ /g, '');
    var formattedColor2 = color2.toLocaleLowerCase().replace(/ /g, '');
    return loose
        ? formattedColor1.includes(formattedColor2) || formattedColor2.includes(formattedColor1)
        : formattedColor1 == formattedColor2;
};
export var extractThumbnails = function (imagesArray) {
    return imagesArray.map(function (_a) {
        var thumb = _a.thumb;
        return thumb;
    }).filter(function (img) { return img; });
};
export var extractMainImages = function (imagesArray) {
    return imagesArray.map(function (_a) {
        var main = _a.main;
        return main;
    }).filter(function (img) { return img; });
};
export var extractLargeImages = function (imagesArray) {
    return imagesArray.map(function (_a) {
        var large = _a.large;
        return large;
    }).filter(function (img) { return img; });
};
export var extractHighestQualityImages = function (imagesArray) {
    var images = extractLargeImages(imagesArray);
    images = isEmpty(images) ? extractMainImages(imagesArray) : images;
    images = isEmpty(images) ? extractThumbnails(imagesArray) : images;
    return images;
};
export var extractImagesByRecord = function (productId, imagesById) {
    var foundImages = productId && imagesById[productId];
    return foundImages || [];
};
export var extractThumbnailsByImageRecord = function (productId, imagesById) {
    var foundImages = extractImagesByRecord(productId, imagesById);
    return foundImages ? extractThumbnails(foundImages) : [];
};
export var extractMainImagesByImageRecord = function (productId, imagesById) {
    var foundImages = extractImagesByRecord(productId, imagesById);
    return foundImages ? extractMainImages(foundImages) : [];
};
export var extractLargeImagesByImageRecord = function (productId, imagesById) {
    var foundImages = extractImagesByRecord(productId, imagesById);
    return foundImages ? extractLargeImages(foundImages) : [];
};
export var getMatchingImageObject = function (imgUrl, imagesById) {
    var matchingLightboxImage = imagesById.find(function (_a) {
        var main = _a.main, thumb = _a.thumb, large = _a.large;
        return [main, thumb, large].includes(imgUrl);
    });
    return matchingLightboxImage;
};
export var createPrimaryImageForMatcher = function (imageUrl, onClick, height) {
    if (height === void 0) { height = 400; }
    return {
        src: imageUrl,
        width: '100%',
        height: "".concat(height, "px"),
        bgSize: 'cover',
        bgColor: '#f7f7f7',
        onClick: function () { return onClick(); },
    };
};
export var getSelectedProductColor = function (colorId, colors) {
    return colors.find(function (color) { return color.code === colorId; });
};
export var productHasImages = function (match) {
    return match && match[0] && !isEmpty(match[0].images);
};
export var isParentStyle = function (potential) {
    return !isEmpty(potential.products);
};
var selectHoverImages = function (potential) {
    var getParenStyleHoverImages = function (potential) { var _a, _b; return ((_b = ((_a = potential.products) === null || _a === void 0 ? void 0 : _a.data)[0]) === null || _b === void 0 ? void 0 : _b.images) || []; };
    var getSubStlyeHoverImages = function (potential) { var _a, _b, _c; return (potential.subStyles && ((_c = (_b = (_a = potential.subStyles[0]) === null || _a === void 0 ? void 0 : _a.products) === null || _b === void 0 ? void 0 : _b.data[0]) === null || _c === void 0 ? void 0 : _c.images)) || []; };
    return isParentStyle(potential) ? getParenStyleHoverImages(potential) : getSubStlyeHoverImages(potential);
};
export var reducePotentialMatches = function (potentials) {
    return potentials.map(function (potential) {
        var hoverImages = selectHoverImages(potential);
        return __assign(__assign({}, potential), { isParentStyle: isParentStyle(potential), hoverImages: extractThumbnails(hoverImages), primaryImage: first(extractMainImages(hoverImages)), title: potential.styleName || potential.parentName || '' });
    });
};
export var reducePotentialSubMatches = function (potentials, potentialParentId) {
    var parent = getParentPotentialMatch(potentials, potentialParentId);
    var subStyles = (parent === null || parent === void 0 ? void 0 : parent.subStyles) || [];
    var filteredStyles = subStyles.filter(function (subStyle) { var _a; return !isEmpty((_a = subStyle.products) === null || _a === void 0 ? void 0 : _a.data); });
    return filteredStyles.map(function (subStyle) {
        var hoverImages = subStyle.products.data[0].images;
        return __assign(__assign({}, subStyle), { hoverImages: extractThumbnails(hoverImages), primaryImage: first(extractMainImages(hoverImages)), title: subStyle.styleName || '', subStyleDifferences: getSubStyleDifferences(subStyle) });
    });
};
export var createSubHoverImages = function (subStyle) {
    var hoverImages = subStyle.products.data[0].images;
    return extractThumbnails(hoverImages);
};
export var getParentPotentialMatch = function (potentials, potentialParentId) {
    return potentials.find(function (potential) { return potential.styleId === potentialParentId; });
};
export var isSelectedIdParentStyle = function (potentials, selectedId) {
    var parent = selectedId && getParentPotentialMatch(potentials, selectedId);
    return !!parent && !isEmpty(parent.products);
};
export var getSelectPotentialMatchProducts = function (potentials, selectedId) {
    var _a, _b;
    var matchSubStyle = function (subStyles) { return subStyles === null || subStyles === void 0 ? void 0 : subStyles.some(function (_a) {
        var styleId = _a.styleId;
        return styleId === selectedId;
    }); };
    var matchParent = function (styleId) { return styleId === selectedId; };
    var parentStyle = potentials.find(function (_a) {
        var styleId = _a.styleId, subStyles = _a.subStyles;
        return matchParent(styleId) || matchSubStyle(subStyles);
    });
    if (!parentStyle)
        return [];
    if (parentStyle && isParentStyle(parentStyle))
        return (_a = parentStyle.products) === null || _a === void 0 ? void 0 : _a.data;
    return (((_b = (parentStyle === null || parentStyle === void 0 ? void 0 : parentStyle.subStyles).find(function (_a) {
        var styleId = _a.styleId;
        return Number(styleId) === Number(selectedId);
    })) === null || _b === void 0 ? void 0 : _b.products.data) || []);
};
export var getSelectedProduct = function (products, selectedId) {
    return products.find(function (product) { return product.id === selectedId; });
};
export var parentContainsMatchingSubStyle = function (potentials, parentId, childId) {
    if (!childId || !parentId)
        return false;
    var parent = potentials.find(function (potential) { return potential.styleId === parentId; });
    if (!parent)
        return false;
    var subStyles = parent === null || parent === void 0 ? void 0 : parent.subStyles;
    if (!subStyles)
        return false;
    return subStyles.find(function (_a) {
        var styleId = _a.styleId;
        return styleId === childId;
    });
};
export var getSubStyleDifferences = function (subStlye) {
    var removedKeys = ['hoverImages', 'primaryImage', 'title', 'styleName', 'products', 'styleId'];
    var filteredObject = pickBy(subStlye, function (value, key) { return !removedKeys.includes(key); });
    return Object.entries(filteredObject).map(function (_a) {
        var key = _a[0], value = _a[1];
        return ({
            title: capitalizeFirstLetter(key),
            value: value,
        });
    });
};
export var noColorOption = {
    id: 0,
    thumbnailUrl: UnknownImage,
    title: 'Unknown Color',
    brand: '',
    brandId: 0,
    styleId: 0,
    colorId: 0,
    color: 'Unknown Color',
    price: 0,
    warehouseId: 0,
    condition: 'very_good',
    location: '',
    year: 0,
    dateCode: '',
    authenticationStatus: '',
    approvedAt: new Date(),
    categoryId: null,
    images: [
        {
            thumb: UnknownImage,
            main: UnknownImage,
            large: UnknownImage,
        },
    ],
    purchasePrice: 1,
    soldAt: new Date(),
};
