export var defaultStoreStilettoEdit = function (id) {
    return {
        id: id,
        loadingActions: [],
        editDisabled: false,
        editedImage: null,
        openEditType: null,
        editHistory: [],
        editedImageIsFullResolution: false,
        isPerformingEdit: false,
    };
};
