import { doesCSSClassExists } from 'vue2/helpers';
import Muuri from 'muuri';
export var disableDragClass = 'disableDragClass';
export var disableClickBubbleClass = 'disableClickBubbleClass';
export var defaultMuuriConfig = {
    showDuration: 500,
    dragEnabled: true,
    dragPlaceholder: {
        enabled: true,
        createElement: function (item) {
            var _a;
            return (_a = item === null || item === void 0 ? void 0 : item.getElement()) === null || _a === void 0 ? void 0 : _a.cloneNode(true);
        },
    },
    dragStartPredicate: function (item, event) {
        var element = item.getElement();
        if (element && doesCSSClassExists(element, disableDragClass))
            return false;
        //if (event.deltaTime > 5)
        return Muuri.ItemDrag.defaultStartPredicate(item, event);
    },
};
