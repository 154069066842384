var baseRoute = '/api/showroom-assistant';
//showroom assistant routes
export var GET_TOUR_DATA = "".concat(baseRoute, "/tour-data");
export var GET_CUSTOMER_ITEMS = "".concat(baseRoute, "/customer-items");
export var REQUEST_PICKUP_ITEMS = GET_CUSTOMER_ITEMS; //POST
export var SHOWROOM_ASSISTANT_GENERATE_QR = "".concat(baseRoute, "/seller-drop-off");
//dropoff
export var PREDICT_PRICE = "api/predict/price";
export var CHANGE_PENDING_ITMES = function (formData) { return "account/pending-items/".concat(formData); };
export var PRICING_TARGET_PRICING = "api/pricing/target-price";
export var PREDICT_IDENTIFICATION = "api/predict/identification";
export var STYLES_SEARCH = "api/styles/search";
export var STYLES_GET_STYLE = function (styleId) { return "api/styles/".concat(styleId); };
export var ADDRESS_SEARCH = "api/address-search";
export var RECEIVE_QUOTES = function (pendingItemId) { return "api/showroom-assistant/receive-quotes/".concat(pendingItemId); };
//other
export var GET_CATEGORIES = "categories";
export var UPLOAD_VISION_PREDICT = 'vision/predict';
export var GET_WAREHOUSES = function (warehouseId) { return "/api/warehouses/".concat(warehouseId); };
