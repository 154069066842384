import { RouteNames } from './routeNames';
var IS_PRODUCTION = process.env.NODE_ENV === 'production';
export var stilettoRoutes = [
    {
        path: "/".concat(RouteNames.Stiletto),
        component: function () { return import('../views/Stiletto.vue'); },
        meta: {
            requiresAuth: false,
            requiresAdminAuth: IS_PRODUCTION,
            title: 'Stiletto',
        },
        children: [
            {
                path: '/',
                name: RouteNames.Stiletto,
                component: function () { return import('../controllers/Stiletto/StilettoDashboardController.vue'); },
                meta: {
                    title: 'Stiletto - Dashboard',
                },
            },
            {
                path: RouteNames.StilettoEdit,
                name: RouteNames.StilettoEdit,
                component: function () { return import('../controllers/Stiletto/StilettoController.vue'); },
                meta: {
                    title: 'Stiletto - Edit',
                },
            },
            {
                path: RouteNames.StilettoPreview,
                name: RouteNames.StilettoPreview,
                component: function () { return import('../controllers/Stiletto/StilettoPreviewController.vue'); },
                meta: {
                    title: 'Stiletto - Preview',
                },
            },
            {
                path: RouteNames.StilettoFlaggedProducts,
                name: RouteNames.StilettoFlaggedProducts,
                component: function () { return import('../controllers/Stiletto/StilettoFlaggedProductsController.vue'); },
                meta: {
                    title: 'Stiletto - Flagged Products',
                },
            },
        ],
    },
];
export default stilettoRoutes;
