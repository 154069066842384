import { ChartTypes } from './interfaces';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement, Filler, ArcElement, } from 'chart.js';
var registeredCharts = [];
var containsType = function (type) { return registeredCharts.includes(type); };
export var registerChart = function (chartType) {
    if (containsType(chartType))
        return;
    registeredCharts.push(chartType);
    !registeredCharts.length && ChartJS.register(Title, Tooltip, Legend, CategoryScale, LinearScale);
    if (chartType === ChartTypes.Bar) {
        return ChartJS.register(BarElement, LinearScale);
    }
    if (chartType === ChartTypes.Line) {
        return ChartJS.register(LinearScale, LineElement, PointElement, Filler);
    }
    if (chartType === ChartTypes.Doughnut || chartType === ChartTypes.Pie) {
        return ChartJS.register(ArcElement, CategoryScale);
    }
    console.warn('chart type not supported - ', chartType);
    return null;
};
