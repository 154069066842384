var StyleParams;
(function (StyleParams) {
    StyleParams["initial"] = "initial";
    StyleParams["auto"] = "auto";
    StyleParams["fixed"] = "fixed";
    StyleParams["hidden"] = "hidden";
    StyleParams["null"] = "";
})(StyleParams || (StyleParams = {}));
export var IGNORE_OVERFLOW_CLASS = 'is-ignoring-overflow';
var basePositionValue = '0px';
var useIgnoreOverflow = function (binding) {
    var _a;
    //eslint-disable-next-line
    var isBoolean = function (value) { return typeof value === 'boolean'; };
    if (isBoolean(binding.value)) {
        return binding.value;
    }
    if (isBoolean((_a = binding.value) === null || _a === void 0 ? void 0 : _a.useIgnoreOverflow)) {
        return binding.value.useIgnoreOverflow;
    }
    return true;
};
var inserted = function (el, binding, vnode) {
    var _a;
    if (!useIgnoreOverflow(binding))
        return false;
    var originalWidth;
    var originalPosition;
    var originalZIndex;
    var elPosition;
    var selectIsOpen = false;
    // will be used as a placeholder, in order to fix parent height issue (due to position relative)
    var clone = el.cloneNode(true);
    // @ts-ignore
    clone.style.visibility = StyleParams.hidden;
    var changePosition = function () {
        elPosition = el.parentElement.getBoundingClientRect();
        el.style.left = "".concat(el.getBoundingClientRect().left, "px");
        el.style.top = "".concat(elPosition.top, "px");
    };
    // @ts-ignore
    var setElementPosition = function (el, binding, vnode, isShown) {
        var _a, _b, _c, _d;
        selectIsOpen = isShown;
        if (isShown) {
            var offsetWidth = el.offsetWidth;
            var zIndex = ((_a = binding.value) === null || _a === void 0 ? void 0 : _a.zIndex) ? binding.value.zIndex : 11; //TODO make css var
            originalWidth = el.style.width || StyleParams.null;
            originalPosition = originalPosition || ((_b = getComputedStyle(el)) === null || _b === void 0 ? void 0 : _b.position) || StyleParams.null;
            originalZIndex = el.style.zIndex || StyleParams.null;
            elPosition = el.getBoundingClientRect();
            el.style.width = "".concat(offsetWidth, "px");
            el.style.left = "".concat(elPosition.left, "px");
            el.style.top = "".concat(elPosition.top, "px");
            el.style.margin = basePositionValue;
            el.style.right = StyleParams.auto;
            el.style.bottom = StyleParams.auto;
            el.style.position = StyleParams.fixed;
            el.style.zIndex = zIndex;
            el.classList.add(IGNORE_OVERFLOW_CLASS);
            (_c = el.parentNode) === null || _c === void 0 ? void 0 : _c.insertBefore(clone, el.nextSibling); // insert after el
        }
        else {
            el.style.position = originalPosition;
            el.style.left = StyleParams.null;
            el.style.top = StyleParams.null;
            el.style.margin = StyleParams.null;
            el.style.right = StyleParams.null;
            el.style.bottom = StyleParams.null;
            el.style.transform = StyleParams.null;
            el.style.width = originalWidth;
            el.style.zIndex = originalZIndex;
            el.classList.remove(IGNORE_OVERFLOW_CLASS);
            (_d = clone.parentNode) === null || _d === void 0 ? void 0 : _d.removeChild(clone);
        }
    };
    // what data value to watch in parent
    // <div v-ignore-overflow:showMenu />
    if (binding.arg) {
        // @ts-ignore
        setElementPosition(el, binding, vnode, vnode === null || vnode === void 0 ? void 0 : vnode.context[binding.arg]);
        (_a = vnode === null || vnode === void 0 ? void 0 : vnode.context) === null || _a === void 0 ? void 0 : _a.$watch(binding.arg, function (isOpen) {
            setElementPosition(el, binding, vnode, isOpen);
        });
    }
    // @ts-ignore
    // <div v-ignore-overflow />
    if (binding.value === undefined && !binding.arg) {
        setElementPosition(el, binding, vnode, true);
    }
    // @ts-ignore
    // used for Multiselect components
    if (vnode.child) {
        // @ts-ignore
        vnode.child.$watch('isOpen', function (isOpen) {
            setElementPosition(el, binding, vnode, isOpen);
        });
    }
    window.addEventListener('scroll', function () {
        if (selectIsOpen) {
            // disabled outside scroll when select is open
            // and getting parent position to stay on same place if user scroll
            changePosition();
        }
    }, true);
    window.addEventListener('resize', function () {
        if (selectIsOpen)
            changePosition();
    }, true);
};
var ignoreOverflow = {
    inserted: inserted,
};
export default ignoreOverflow;
