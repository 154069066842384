export var extractLightboxImage = function (currentImage) {
    return (currentImage === null || currentImage === void 0 ? void 0 : currentImage.src) || currentImage;
};
export var CoordinateIds;
(function (CoordinateIds) {
    CoordinateIds["top"] = "top";
    CoordinateIds["bottom"] = "bottom";
    CoordinateIds["left"] = "left";
    CoordinateIds["right"] = "right";
})(CoordinateIds || (CoordinateIds = {}));
export var handleXDrag = function (params) {
    var id = params.id, e = params.e, preventOverflow = params.preventOverflow, left = params.left, parentWidth = params.parentWidth, dragElementPercentLeft = params.dragElementPercentLeft, dragElementPercentRight = params.dragElementPercentRight;
    var x = e.clientX - left;
    var width = parentWidth;
    var hoverPercentFromLeft = 100 - (x / width) * 100;
    if (hoverPercentFromLeft > 100)
        hoverPercentFromLeft = 100;
    if (hoverPercentFromLeft < 0)
        hoverPercentFromLeft = 0;
    if (id === CoordinateIds.left) {
        if (preventOverflow && hoverPercentFromLeft < dragElementPercentRight) {
            hoverPercentFromLeft = dragElementPercentRight;
        }
        return 100 - hoverPercentFromLeft;
    }
    if (preventOverflow && hoverPercentFromLeft > 100 - dragElementPercentLeft) {
        hoverPercentFromLeft = 100 - dragElementPercentLeft;
    }
    return hoverPercentFromLeft;
};
export var handleYDrag = function (params) {
    var id = params.id, e = params.e, preventOverflow = params.preventOverflow, top = params.top, parentHeight = params.parentHeight, dragElementPercentTop = params.dragElementPercentTop, dragElementPercentBottom = params.dragElementPercentBottom;
    var y = e.clientY - top;
    var hoverPercentFromBottom = 100 - (y / parentHeight) * 100;
    if (hoverPercentFromBottom > 100)
        hoverPercentFromBottom = 100;
    if (hoverPercentFromBottom < 0)
        hoverPercentFromBottom = 0;
    if (id === CoordinateIds.bottom) {
        if (preventOverflow && hoverPercentFromBottom > 100 - dragElementPercentTop) {
            hoverPercentFromBottom = 100 - dragElementPercentTop;
        }
        return hoverPercentFromBottom;
    }
    else {
        if (preventOverflow && hoverPercentFromBottom < dragElementPercentBottom) {
            hoverPercentFromBottom = dragElementPercentBottom;
        }
        return 100 - hoverPercentFromBottom;
    }
};
