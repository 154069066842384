import axios from 'axios';
import { transformErrors } from 'vue2/helpers';
export var webAppApiBaseUrl = "".concat(process.env.MIX_FASHIONPHILE_API_URL, "/api");
var webAppInstance = axios.create({
    baseURL: webAppApiBaseUrl,
});
// a response interceptor
webAppInstance.interceptors.response.use(function (resp) { return resp; }, function (error) {
    var _a, _b;
    return Promise.reject(transformErrors(((_a = error.response) === null || _a === void 0 ? void 0 : _a.data) || error, (_b = error.response) === null || _b === void 0 ? void 0 : _b.status));
});
// a request interceptor interceptor
webAppInstance.interceptors.request.use(function (config) {
    config.headers['Accept'] = 'application/x.fashionphile.v1+json';
    return config;
});
export default webAppInstance;
