import { library } from '@fortawesome/fontawesome-svg-core';
import { faBagShopping, faChevronLeft, faChevronRight, faRedo, faSpinner, faMinus, faPlus, faTimes, faSquareArrowUpRight, faMagnifyingGlassMinus, faMagnifyingGlassPlus, faChevronUp, faCheck, faChevronDown, faUndo, faVideo, faPenToSquare, faCircleXmark, faUpRightFromSquare, faMagnifyingGlass, faStar, faDownload, faFlag, faPenSquare, faComputerMouse, faArrowRightArrowLeft, faCheckCircle, faArrowLeft, faTrash, faFileCirclePlus, faArrowsSplitUpAndLeft, faArrowUpRightFromSquare, faAngleDown, faAngleUp, faCropSimple, faCrop, faRotate, faDroplet, faTarpDroplet, faBarcode, faLayerGroup, faObjectUngroup, faPaintBrush, faPalette, faPaintbrush, faSun, faCircleChevronDown, faSearch, faCaretDown, faCaretUp, faList, faListSquares, faComment, faExternalLinkAlt, faExternalLink, faPaintRoller, faCloud, faBrush, faPallet, faUpload, faFileDownload, faEraser, faWarning, faClock, faMoon, faCloudSun, faBorderAll, faBorderNone, } from '@fortawesome/free-solid-svg-icons';
var icons = [
    faBorderAll,
    faBorderNone,
    faMoon,
    faCloudSun,
    faWarning,
    faEraser,
    faFileDownload,
    faUpload,
    faPallet,
    faBrush,
    faPaintRoller,
    faCloud,
    faList,
    faListSquares,
    faSearch,
    faCaretDown,
    faCaretUp,
    faCircleChevronDown,
    faSun,
    faPaintBrush,
    faPalette,
    faPaintbrush,
    faObjectUngroup,
    faLayerGroup,
    faBarcode,
    faBagShopping,
    faChevronRight,
    faChevronLeft,
    faRedo,
    faSpinner,
    faMinus,
    faPlus,
    faTimes,
    faMagnifyingGlassMinus,
    faMagnifyingGlassPlus,
    faChevronUp,
    faChevronDown,
    faCheck,
    faUndo,
    faVideo,
    faCircleXmark,
    faPenToSquare,
    faUpRightFromSquare,
    faMagnifyingGlass,
    faStar,
    faDownload,
    faFlag,
    faPenSquare,
    faComputerMouse,
    faArrowRightArrowLeft,
    faCheckCircle,
    faTrash,
    faFileCirclePlus,
    faArrowsSplitUpAndLeft,
    faArrowUpRightFromSquare,
    faAngleDown,
    faAngleUp,
    faCropSimple,
    faCrop,
    faRotate,
    faDroplet,
    faTarpDroplet,
    faArrowLeft,
    faSquareArrowUpRight,
    faComment,
    faExternalLinkAlt,
    faExternalLink,
    faClock,
];
export default {
    install: function () {
        icons.forEach(function (icon) {
            library.add(icon);
        });
    },
};
