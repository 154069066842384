export var distanceToScroll = function (contactsContainerOffset, widthOfContactCard, marginOfContactCard) {
    var contactCardSpacing = widthOfContactCard + marginOfContactCard;
    var numberOfTilesInView = Math.round(contactsContainerOffset / contactCardSpacing);
    return numberOfTilesInView * contactCardSpacing;
};
export var calculateScrollDistance = function (forward, distanceToScroll, distanceScrolled, offset) {
    var scrollInThisDirection = forward ? distanceToScroll : -distanceToScroll;
    var movement = scrollInThisDirection + distanceScrolled;
    return offset ? movement + offset : movement;
};
