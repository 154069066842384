var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { fabric } from "fabric";
import first from "lodash/first";
import last from "lodash/last";
import { addSupportForLargeImages } from "./filters";
import { canvasFromJSON } from "./save";
export var createFabricCanvas = function (id, options) {
    if (options === void 0) { options = { interactive: false, backgroundColor: "white" }; }
    return __awaiter(void 0, void 0, void 0, function () {
        var canvasElement;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, addSupportForLargeImages()];
                case 1:
                    _a.sent();
                    canvasElement = new fabric.Canvas(id, options);
                    return [2 /*return*/, canvasElement];
            }
        });
    });
};
export var setCanvasBackgroundColor = function (canvas, color) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve) {
                canvas.setBackgroundColor(color, function (canvas) {
                    resolve(canvas);
                });
            })];
    });
}); };
export var setCanvasSizeToImage = function (canvas, img) {
    var height = img.height;
    var width = img.width;
    canvas.setHeight(height);
    canvas.setWidth(width);
    img.scaleToWidth(canvas.getWidth());
    img.center();
    img.applyFilters();
    canvas.renderAll();
};
export var setCanvasSize = function (canvas, _a) {
    var width = _a.width, height = _a.height;
    canvas.setDimensions({ width: width, height: height });
    canvas.renderAll();
};
var setScales = function (canvas, img) {
    return {
        scaleX: (canvas === null || canvas === void 0 ? void 0 : canvas.height) / (img === null || img === void 0 ? void 0 : img.height),
        scaleY: canvas.width / (img === null || img === void 0 ? void 0 : img.width),
    };
};
export var setImageToCanvasSize = function (canvas, img) {
    img.set(__assign({ top: 0, left: 0 }, setScales(canvas, img)));
    canvas.renderAll();
};
export var addImageToBackgroundByURL = function (canvas, url) {
    return new Promise(function (resolve) {
        var returnImg = null;
        var loaded = false;
        fabric.Image.fromURL(url, function (img) {
            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas, { crossOrigin: "anonymous" }), __assign({ selectable: false, hasControls: false, evented: false }, setScales(canvas, img)));
            returnImg = img;
            img.scaleToWidth(canvas.getWidth());
            img.center();
            img.applyFilters();
            canvas.renderAll();
            if (loaded)
                resolve(returnImg);
        }, { crossOrigin: "anonymous" });
        checkForImageLoadFabric(url, function () {
            loaded = true;
            if (returnImg)
                resolve(returnImg);
        });
    });
};
export var addImageToBackgroundByDOMNode = function (canvas, imgElement, options) {
    var imgInstance = new fabric.Image(imgElement, options);
    canvas.add(imgInstance);
};
var checkForImageLoadFabric = function (imageUrl, callback) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve) {
                var image = new Image();
                image.crossOrigin = "anonymous";
                image.addEventListener("load", function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        callback && callback(image);
                        //wait for lifecycle to finish
                        setTimeout(function () { return resolve(true); }, 0);
                        return [2 /*return*/];
                    });
                }); });
                image.src = imageUrl;
            })];
    });
}); };
export var getCanvasDimensions = function (canvas) {
    var canvasWidth = canvas.getWidth();
    var canvasHeight = canvas.getHeight();
    return {
        canvasWidth: canvasWidth,
        canvasHeight: canvasHeight,
    };
};
export var undoEdit = function (canvas, filters, isReversed) {
    if (isReversed === void 0) { isReversed = false; }
    return __awaiter(void 0, void 0, void 0, function () {
        var chosenFilter, newCanvas, _a, clonedFilters;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    chosenFilter = isReversed ? first(filters) : last(filters);
                    if (!!chosenFilter) return [3 /*break*/, 1];
                    _a = canvas;
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, canvasFromJSON(canvas, chosenFilter)];
                case 2:
                    _a = _b.sent();
                    _b.label = 3;
                case 3:
                    newCanvas = _a;
                    clonedFilters = __spreadArray([], filters, true);
                    if (clonedFilters.length && isReversed)
                        clonedFilters.shift();
                    else if (clonedFilters.length)
                        clonedFilters.pop();
                    return [2 /*return*/, { canvas: newCanvas, filters: clonedFilters }];
            }
        });
    });
};
export var setCanvasToState = function (canvas, state) { return __awaiter(void 0, void 0, void 0, function () {
    var oldCanvas;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, canvasFromJSON(canvas, state, true)];
            case 1:
                oldCanvas = _a.sent();
                canvas = oldCanvas;
                return [2 /*return*/, canvas];
        }
    });
}); };
export var toCanvasElement = function (canvas, options) {
    return canvas.toCanvasElement(options === null || options === void 0 ? void 0 : options.multiplier, options === null || options === void 0 ? void 0 : options.cropping);
};
export var disposeFabricCanvas = function (canvas) {
    return canvas.dispose();
};
