var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { resetMessageIds } from '../../createMessageHelpers';
import { refreshUserItems } from '../shared';
import { DropoffKeys } from './config';
export var initialShowroomDropoffMessages = function () {
    var messages = __spreadArray([], refreshUserItems(DropoffKeys.loadedPendingActiveItems), true);
    resetMessageIds();
    return messages;
};
