export var defaultQuoteData = {
    brand: '',
    brandId: '',
    color: '',
    colorId: '',
    name: '',
    condition: '',
    categoryId: 233,
    style: '',
    styleId: '',
    productPrice: '',
    matchedProductID: '',
    description: 'Item submitted from quote assistant',
    buyoutQuote: undefined, //TODO ask Jessie what this is? Wasn't on original object but referenced later in code
};
export var defaultUserData = {
    addressData: null,
    dropoffItems: [],
    paymentMethod: null,
    signature: null,
    phone: null,
    acceptedOfferType: [],
};
export var defaultUserNewQuoteData = {
    brandId: null,
    productData: null,
    colorId: null,
    productPrediction: null,
};
