export var DropoffKeys;
(function (DropoffKeys) {
    DropoffKeys["loadedPendingActiveItems"] = "loaded-pending-active-items";
    DropoffKeys["confirmAcceptedOffType"] = "confirm-accepted-offer-type";
    DropoffKeys["confirmNumberOfDropoffItems"] = "confirm-number-of-dropoff-items";
    DropoffKeys["confirmAddress"] = "confirm-address";
    DropoffKeys["paymentMethodInput"] = "payment-method-input";
    DropoffKeys["confirmPaymentMethodChange"] = "confirm-payment-method-change";
    DropoffKeys["confirmSignature"] = "confirm-signature";
    DropoffKeys["confirmPhone"] = "confirm-phone";
    DropoffKeys["hasGeneratedQRCode"] = "has-generated-qr-code";
    DropoffKeys["confirmOrder"] = "confirm-order";
    DropoffKeys["checkForPickUpItems"] = "check-for-pick-items";
})(DropoffKeys || (DropoffKeys = {}));
