export var WhoFilter;
(function (WhoFilter) {
    WhoFilter["ME"] = "ME";
    WhoFilter["EVERYONE"] = "EVERYONE";
})(WhoFilter || (WhoFilter = {}));
export var TimeFilter;
(function (TimeFilter) {
    TimeFilter["WEEK"] = "WEEK";
    TimeFilter["MONTH"] = "MONTH";
    TimeFilter["DAY"] = "DAY";
})(TimeFilter || (TimeFilter = {}));
