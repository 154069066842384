var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createLoadingMessage, createAssistantMessage, createQuizMessage, createChoiceMessage, createTitleMessage, createVideoMessage, createNoneMessage, createChartMessage, createLinkMessage, resetMessageIds, createImageMessage, } from '../..';
import { getTourVariableData } from 'main/api';
import { QuizTypes, storeModules } from 'main/store';
import round from 'lodash/round';
import { TourConfigKey, TourKeys } from './config';
import { createBirkinChartData } from './helpers';
//videos
import AuthVFakeVideo from 'main/assets/videos/authvfake.mp4';
import AboutUsVideo from 'main/assets/videos/aboutus.mp4';
import SustVideo from 'main/assets/videos/sust.mp4';
//images
import AboutUsThumbnail from 'main/assets/images/ABOUTUS_30_9x16_THUMBNAIL.jpg';
import SustainabilityThumbnail from 'main/assets/images/SUSTAINABILITY_30_9x16_THUMBNAIL.jpg';
import AuthVFakeThumbnail from 'main/assets/images/authvfake.jpg';
import StarrettlehighImage from 'main/assets/images/starrettlehigh.png';
import map1 from 'main/assets/images/nyc_tour_map_1.jpg';
import map2 from 'main/assets/images/nyc_tour_map_2.jpg';
import map3 from 'main/assets/images/nyc_tour_map_3.jpg';
import map4 from 'main/assets/images/nyc_tour_map_4.jpg';
import map5 from 'main/assets/images/nyc_tour_map_5.jpg';
import map6 from 'main/assets/images/nyc_tour_map_6.jpg';
import map7 from 'main/assets/images/nyc_tour_map_7.jpg';
import map8 from 'main/assets/images/nyc_tour_map_8.jpg';
import map9 from 'main/assets/images/nyc_tour_map_9.jpg';
export var getTourVariableDataMessages = function () {
    return [
        createLoadingMessage({
            apiRequest: {
                apiUrl: function () { return getTourVariableData(); },
                responseTransformer: function (tourData) { return storeModules.showroomAssistant.SET_TOUR_DATA(tourData); },
            },
            overrideNextId: TourConfigKey.startTourWithData,
        }),
    ];
};
export var createTourMessages = function (messages) {
    var tourVariableData = messages || storeModules.showroomAssistant.tourVariableData;
    return __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], starrettStop(TourKeys.stop0, TourKeys.stop1), true), hubStop(TourKeys.stop1, TourKeys.stop2), true), cageStop1(TourKeys.stop2, TourKeys.stop3, tourVariableData), true), sustainableStop(TourKeys.stop3, TourKeys.stop4), true), birkinStop(TourKeys.stop4, TourKeys.stop5, tourVariableData), true), largestUltraLuxuryStop(TourKeys.stop5, TourKeys.stop6, tourVariableData), true), fromUsStop(TourKeys.stop6, TourKeys.stop7), true), shopSellStop(TourKeys.stop7, TourKeys.stop8), true), pictureStop(TourKeys.stop8, TourKeys.stop9), true), authenticationStop(TourKeys.stop9, TourKeys.endTour, tourVariableData), true), endTour(TourKeys.endTour), true);
};
var starrettStop = function (stopId, nextStopId) {
    return createStop([
        createLoadingMessage({
            overrideCurrentId: stopId,
        }),
        createTitleMessage({
            primaryText: 'The Starrett-Lehigh Legacy',
        }),
        createImageMessage({
            imageSrc: StarrettlehighImage,
            height: 210,
            text: "The Starrett-Lehigh building\u2019s rich history in both manufacturing and fashion made it the perfect fit for FASHIONPHILE\u2019s NYC Flagship.",
        }),
        createAssistantMessage({
            text: "Constructed in 1931, the building was originally used for warehousing, distribution, and manufacturing.",
        }),
        createLoadingMessage(),
        createAssistantMessage({
            text: "FASHIONPHILE is continuing the Starrett-Lehigh legacy by bringing warehouse operations back to Manhattan in style. Here you can find everything from shipping to photography - and of course, the best-in-class authentication you\u2019ve come to know from FASHIONPHILE.",
        }),
        createChoiceMessage({
            choices: [{ text: 'Continue' }],
        }),
        createLoadingMessage({ overrideNextId: nextStopId }),
    ]);
};
var hubStop = function (stopId, nextStopId) {
    return createStop([
        createTitleMessage({
            primaryText: 'The Hub of All Things Ultra - Luxury',
            overrideCurrentId: stopId,
        }),
        createImageMessage({ imageSrc: map1, height: 160 }),
        createChoiceMessage({
            choices: [{ text: 'Continue' }],
        }),
        createAssistantMessage({
            text: 'Come tour the only place in the world where you can shop and sell the most exclusive brands.',
        }),
        createVideoMessage({
            videoSrc: AboutUsVideo,
            thumbnailSrc: AboutUsThumbnail,
            immediatelyGrabNextMessage: true,
        }),
        createChoiceMessage({
            choices: [{ text: 'Continue' }],
        }),
        createLoadingMessage({
            overrideNextId: nextStopId,
        }),
    ]);
};
var cageStop1 = function (stopId, nextStopId, tourVariableData) {
    return createStop([
        createTitleMessage({ primaryText: 'The Cage', overrideCurrentId: stopId }),
        createImageMessage({ imageSrc: map2, height: 160 }),
        createChoiceMessage({
            choices: [{ text: 'Continue' }],
        }),
        createAssistantMessage({
            text: "Our climate-controlled \u201Ccage\u201D is currently home to over ".concat(tourVariableData.inventoryCount, " luxury handbags and accessories."),
            overrideNextId: nextStopId,
        }),
    ]);
};
var largestUltraLuxuryStop = function (stopId, nextStopId, tourVariableData) {
    return createStop([
        createTitleMessage({ primaryText: 'The Largest Ultra-Luxury Selection In The World', overrideCurrentId: stopId }),
        createImageMessage({ imageSrc: map4, height: 160 }),
        createChoiceMessage({
            choices: [{ text: 'Continue' }],
        }),
        createAssistantMessage({
            text: "Fun fact: 12% of the products that come in sell in the first week! That means that last week we shipped ".concat(tourVariableData.itemsShipped, " items!"),
        }),
        createAssistantMessage({
            text: "On average, everything you see sells within 60 days.",
        }),
        createAssistantMessage({
            text: "That means in 60 days from now, you're looking at almost an entirely different assortment!",
            overrideNextId: nextStopId,
        }),
    ]);
};
var sustainableStop = function (stopId, nextStopId) {
    return createStop(__spreadArray(__spreadArray([
        createTitleMessage({ primaryText: 'The Sustainable Option', overrideCurrentId: stopId }),
        createImageMessage({ imageSrc: map3, height: 160 }),
        createChoiceMessage({
            choices: [{ text: 'Continue' }],
        }),
        createAssistantMessage({
            text: 'Luxury handbags are 200% less likely to end up in a landfill.',
        }),
        createAssistantMessage({
            text: 'The items you see here are sold daily. We believe ultra-luxury is a never-ending sustainable cycle. It starts with us and ends up with you.',
        })
    ], createQuizMessage({
        answerId: 0,
        question: 'Pop Quiz: Purchasing pre-owned saves…',
        answerTextArray: [
            "22 liters of water and 8kg of carbon dioxide",
            '4 gallons of water',
            '0 liters of water and 1kg of carbon dioxid',
            '10 liters of water and 2kg of carbon dioxid',
        ],
        successMessageText: ['You’re correct!'],
        failMessageText: [
            'Sorry, that’s not correct.',
            "Purchasing pre-owned saves over 22 liters of water and 8kg of Carbon",
        ],
        quizType: QuizTypes.TextOptions,
    }), true), [
        createAssistantMessage({
            text: "Fun fact: If all items were purchased secondhand for the next year, we would reduce carbon waste and industrial water consumption by about 73%",
        }),
        createVideoMessage({
            thumbnailSrc: SustainabilityThumbnail,
            videoSrc: SustVideo,
            immediatelyGrabNextMessage: true,
        }),
        createChoiceMessage({
            choices: [{ text: 'Continue' }],
        }),
        createLoadingMessage({
            overrideNextId: nextStopId,
        }),
    ], false));
};
var birkinStop = function (stopId, nextStopId, tourVariableData) {
    return createStop([
        createTitleMessage({ primaryText: 'The Famous Birkin Aisle', overrideCurrentId: stopId }),
        createImageMessage({ imageSrc: map5, height: 160 }),
        createChoiceMessage({ choices: [{ text: 'Continue' }] }),
        createAssistantMessage({
            text: 'FASHIONPHILE is home to the largest collection of Birkin bags in the world. 🌎',
        }),
        createAssistantMessage({
            text: 'Birkin bags make a great investment, often increasing in value faster than the stock market (or at least that’s what you can tell your partner 😉).',
        }),
        createChartMessage(__assign(__assign({}, createBirkinChartData(tourVariableData)), { text: 'The Growing Value of the Hermès Birkin YoY', height: 260 })),
        createChoiceMessage({ choices: [{ text: 'Continue' }] }),
        createLoadingMessage({ overrideNextId: nextStopId }),
    ]);
};
var fromUsStop = function (stopId, nextStopId) {
    return createStop([
        createTitleMessage({ primaryText: 'From Us, To You', overrideCurrentId: stopId }),
        createImageMessage({ imageSrc: map6, height: 160 }),
        createChoiceMessage({ choices: [{ text: 'Continue' }] }),
        createAssistantMessage({ text: "No, our handbags aren\u2019t sent down from heaven\u2026just around our carousel!" }),
        createAssistantMessage({
            text: "Once you select the pieces that you would like to see, our inventory team will locate them in the cage and deliver them to you on the carousel.",
        }),
        createLoadingMessage({ overrideNextId: nextStopId }),
    ]);
};
var authenticationStop = function (stopId, nextStopId, tourVariableData) {
    return createStop(__spreadArray(__spreadArray([
        createTitleMessage({ primaryText: 'Authentication is a Science', overrideCurrentId: stopId }),
        createImageMessage({ imageSrc: map9, height: 160 }),
        createChoiceMessage({
            choices: [{ text: 'Continue' }],
        }),
        createAssistantMessage({
            text: 'Every item we receive is authenticated by two highly-qualified specialists using our proprietary technology.',
        }),
        createAssistantMessage({
            text: 'Our authentication specialists go through a rigorous training that we call, FASHIONPHILE University. 🎓',
        }),
        createVideoMessage({
            text: "Watch a side-by-side comparison of Authentic vs. Fake",
            videoSrc: AuthVFakeVideo,
            thumbnailSrc: AuthVFakeThumbnail,
            immediatelyGrabNextMessage: true,
        }),
        createChoiceMessage({
            choices: [{ text: 'Continue' }],
        })
    ], createQuizMessage({
        quizType: QuizTypes.TextOptions,
        question: 'How many counterfeit items did we keep out of circulation in the past year?',
        answerId: 0,
        answerTextArray: [
            "".concat(tourVariableData.fakeCount, " items"),
            "".concat(round(tourVariableData.fakeCount * 0.2), " items"),
            "".concat(round(tourVariableData.fakeCount * 0.5), " items"),
            "".concat(round(tourVariableData.fakeCount * 0.85), " items"),
        ],
        successMessageText: ['You are correct!'],
        failMessageText: [
            'Sorry, you are incorrect',
            "We actually destroyed ".concat(tourVariableData.fakeCount, " fake items in the past year!"),
        ],
    }), true), [
        createLoadingMessage({ overrideNextId: nextStopId }),
    ], false));
};
var pictureStop = function (stopId, nextStopId) {
    return createStop([
        createTitleMessage({ primaryText: 'Picture Perfect', overrideCurrentId: stopId }),
        createImageMessage({ imageSrc: map8, height: 160 }),
        createChoiceMessage({
            choices: [{ text: 'Continue' }],
        }),
        createAssistantMessage({
            text: 'No filters here! Each item that we receive is meticulously inspected and photographed to accurately capture its condition from every angle.',
            overrideNextId: nextStopId,
        }),
    ]);
};
var shopSellStop = function (stopId, nextStopId) {
    return createStop([
        createTitleMessage({ primaryText: 'Shop. Sell. Repeat.', overrideCurrentId: stopId }),
        createImageMessage({ imageSrc: map7, height: 160 }),
        createChoiceMessage({
            choices: [{ text: 'Continue' }],
        }),
        createAssistantMessage({
            text: 'Whether you’re purchasing an investment bag or the ‘It’ bag of the moment, we treat every item as someone’s Holy Grail. All FASHIONPHILE items are thoughtfully packaged with a lot of love 💗 and shipped in unmarked boxes to ensure safe delivery.',
        }),
        createAssistantMessage({
            text: 'When that Chanel in your closet isn’t feeling so “you” anymore, sell it back to us and continue the sustainable cycle. 👛 ♻️👜',
            overrideNextId: nextStopId,
        }),
    ]);
};
var endTour = function (stopId) {
    var _a;
    return createStop([
        createAssistantMessage({
            overrideCurrentId: stopId,
            text: "Thank you for joining us at our NYC (Chelsea) Flagship!",
        }),
        createLinkMessage({
            url: ((_a = storeModules.main.warehouse) === null || _a === void 0 ? void 0 : _a.shopURL) || 'https://www.fashionphile.com',
            buttonText: 'Start Shopping',
            useLinkIcon: true,
        }),
        createNoneMessage(),
    ]);
};
var createStop = function (messages) {
    resetMessageIds();
    return messages;
};
