//TODO if this gets to large we need to move to subfolders
export var RouteNames;
(function (RouteNames) {
    RouteNames["Index"] = "index";
    RouteNames["ShowroomAssistant"] = "showroom-assistant";
    RouteNames["ShowroomAssistantTour"] = "tour";
    RouteNames["ShowroomAssistantDropoff"] = "dropoff";
    RouteNames["ShowroomAssistantPickup"] = "pickup";
    RouteNames["ShowroomAssistantNewQuoteWeb"] = "new-quote-web";
    RouteNames["ShowroomAssistantNewQuoteAssistant"] = "new-quote";
    RouteNames["ShowroomAssistantComplete"] = "showroom-assistant/complete";
    RouteNames["ShowroomAssistantDialog"] = "showroom-assistant/dialog";
    RouteNames["ShowroomAssistantReceive"] = "showroom-assistant/receive";
    RouteNames["MechanicalMatcher"] = "mechanical-matcher";
    RouteNames["Stiletto"] = "stiletto";
    RouteNames["StilettoEdit"] = "edit";
    RouteNames["StilettoFlaggedProducts"] = "flagged-products";
    RouteNames["StilettoPreview"] = "preview";
    RouteNames["Portal"] = "portal";
    RouteNames["Login"] = "login";
    RouteNames["Logout"] = "logout";
    RouteNames["ErrorPage"] = "error";
})(RouteNames || (RouteNames = {}));
export default RouteNames;
