var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import { refreshPage } from './environment';
export var transformErrors = function (errorObject, status) {
    if (errorObject === void 0) { errorObject = {}; }
    if (!errorObject)
        return { messages: [], status: status };
    try {
        if (errorObject.message) {
            //if contains a message, it's a single error
            errorObject = { message: errorObject.message };
        }
        var rootErrors = Object.entries(errorObject)
            .filter(function (_a) {
            var key = _a[0], value = _a[1];
            return key !== 'success';
        })
            .map(function (_a) {
            var key = _a[0], value = _a[1];
            return value;
        });
        var reducedErrors = rootErrors.reduce(function (acc, value) {
            var errors = isArray(value) ? __spreadArray([], value, true) : [String(value)];
            return __spreadArray(__spreadArray([], acc, true), errors, true);
        }, []);
        return { messages: reducedErrors, status: status };
    }
    catch (error) {
        return { messages: [], status: status };
    }
};
export var createToastError = function (errorObject) {
    var messages = transformErrors(errorObject).messages;
    var toastMessages = messages.map(function (message) { return ({
        text: message,
    }); });
    return toastMessages;
};
export var confirmErrorStrings = function (errors) {
    return errors && isArray(errors) ? errors : [];
};
export var NetworkErrorTypes;
(function (NetworkErrorTypes) {
    NetworkErrorTypes[NetworkErrorTypes["Unauthorized"] = 401] = "Unauthorized";
})(NetworkErrorTypes || (NetworkErrorTypes = {}));
export var errorRefreshFallback = function (messages, error) {
    messages.forEach(function (msg) {
        if (isString(error) && error.includes(msg))
            refreshPage();
    });
};
export var unloadHandlerMessage = function (event, text) {
    if (text === void 0) { text = 'Items are still being uploaded. Are you sure you want to exit?'; }
    event.preventDefault();
    return (event.returnValue = text);
};
