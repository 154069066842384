var _a;
import { WareHouseIds } from 'main/config';
var warehouseOpeningTextConfig = (_a = {},
    _a[WareHouseIds.ChelseaWarehouse] = 'Welcome to our NYC (Chelsea) Flagship.',
    _a[WareHouseIds.CarlsbadWarehouse] = 'Welcome to our Carlsbad Showroom.',
    _a);
export var createWelcomeMessage = function (warehouse) {
    return warehouseOpeningTextConfig[warehouse === null || warehouse === void 0 ? void 0 : warehouse.id] || "Welcome to our ".concat(warehouse === null || warehouse === void 0 ? void 0 : warehouse.city, " ").concat(warehouse === null || warehouse === void 0 ? void 0 : warehouse.type, ".");
};
export var createWelcomeMessageWithOptions = function (warehouse, options, userData) {
    var helloText = userData ? "Hello, ".concat(userData.firstName) : '';
    var optionsText = options.length > 1 ? 'What would you like to do today?' : '';
    var openingText = createWelcomeMessage(warehouse);
    var welcomeMessage = [openingText, optionsText].join(' ').trim();
    return [helloText, welcomeMessage].filter(function (text) { return text; });
};
