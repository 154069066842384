var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { changeImageOrder, deleteImages, getStilettoProductById, getStilettoProductToBeProcessed, splitProduct, createUploadProgressDetails, submitProduct, saveForLaterProduct, flagProduct, escalateIncident, markIncidentAsResolved, uploadNewImages, uploadSavedEditImage, uploadBulkSaveEditImageChunked, } from 'main/api';
import first from 'lodash/first';
import { removeItemFromArray, replaceItemInArrayWithId, timeout, toggleItemsInArray, unloadHandlerMessage, } from 'vue2/helpers';
import { createHighQualityDataURLForStore, createSmallDataURLForStore, createStoreStilettoImageComponents, createStoreStilettoImages, createUploadItem, createUploadItemEdits, getCacheImages, getEditSaving, isProductUniqueAndNotSubmitting, shouldGetMoreProducts, toggleItemForceHelper, } from './helpers';
import isBoolean from 'lodash/isBoolean';
import { StilettoEditTypes, StilettoLoadingActionEditImage, StilettoLoadingActionGlobal } from './enums';
import isEmpty from 'lodash/isEmpty';
import { v4 as uuidv4 } from 'uuid';
import { defaultStoreStilettoEdit } from './config';
import last from 'lodash/last';
import { initializeCanvasEditCreator, performImageEditReturnHistory, stilettoEditsOrder, } from './storePerformEditHelpers';
import { storeModules } from '..';
import { dataURLToBlob } from 'common/imageManipulation';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import mean from 'lodash/mean';
import { $_toast_error, $_toast_message, $_toast_success } from 'vue2/plugins/toasts';
import pluralize from 'pluralize';
import round from 'lodash/round';
export var stilettoStoreName = 'stiletto';
var NUMBER_OF_QUEUE_ITEMS = process.env.NODE_ENV === 'production' ? 2 : 2;
var StilettoStore = /** @class */ (function (_super) {
    __extends(StilettoStore, _super);
    function StilettoStore() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.gridIsShown = false;
        _this.productQueue = [];
        _this.edits = {};
        _this.selectedImageIds = [];
        _this.loadingActionsGlobal = [];
        _this.editToBePerformed = [];
        _this.bulkUploadProgress = {};
        _this.stagedImageOrder = [];
        _this.submittingIds = [];
        _this.altBackground = false;
        _this.canvasesCreated = [];
        return _this;
    }
    Object.defineProperty(StilettoStore.prototype, "currentProductImages", {
        get: function () {
            var _a;
            return createStoreStilettoImages(((_a = this.currentProduct) === null || _a === void 0 ? void 0 : _a.images) || [], this.featuredImageId);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StilettoStore.prototype, "stilettoImageComponents", {
        get: function () {
            return createStoreStilettoImageComponents(this.currentProductImages, this.edits, this.selectedImageIds);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StilettoStore.prototype, "editsAreSaving", {
        get: function () {
            return getEditSaving(this.edits);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StilettoStore.prototype, "cacheImages", {
        get: function () {
            return getCacheImages(this.productQueue);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StilettoStore.prototype, "hasProductInQueue", {
        get: function () {
            return !isEmpty(this.productQueue);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StilettoStore.prototype, "currentProduct", {
        get: function () {
            return first(this.productQueue) || null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StilettoStore.prototype, "currentProductId", {
        get: function () {
            var _a;
            return ((_a = this.currentProduct) === null || _a === void 0 ? void 0 : _a.id) || null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StilettoStore.prototype, "featuredImageId", {
        get: function () {
            var _a;
            return (_a = this.currentProduct) === null || _a === void 0 ? void 0 : _a.featuredImage.id;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StilettoStore.prototype, "currentProductDetails", {
        get: function () {
            var _a, _b, _c, _d, _e, _f;
            return {
                title: (_a = this.currentProduct) === null || _a === void 0 ? void 0 : _a.title,
                downloadURL: (_b = this.currentProduct) === null || _b === void 0 ? void 0 : _b.downloadURL,
                pendingItemId: (_c = this.currentProduct) === null || _c === void 0 ? void 0 : _c.pendingItemId,
                incident: (_d = this.currentProduct) === null || _d === void 0 ? void 0 : _d.incident,
                isChanelHandbag: (_e = this.currentProduct) === null || _e === void 0 ? void 0 : _e.isChanelHandbag,
                color: (_f = this.currentProduct) === null || _f === void 0 ? void 0 : _f.color,
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StilettoStore.prototype, "recentProducts", {
        get: function () {
            var _a;
            return ((_a = this.currentProduct) === null || _a === void 0 ? void 0 : _a.recentProducts) || [];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StilettoStore.prototype, "multipleImagesSelected", {
        get: function () {
            return this.selectedImageIds.length >= 1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StilettoStore.prototype, "imageOrderChanged", {
        get: function () {
            return (!isEmpty(this.stagedImageOrder) &&
                !isEqual(this.stilettoImageComponents.map(function (_a) {
                    var id = _a.id;
                    return id;
                }), this.stagedImageOrder.map(function (_a) {
                    var id = _a.id;
                    return id;
                })));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StilettoStore.prototype, "noProductsInQueue", {
        get: function () {
            return isEmpty(this.submittingIds) && !this.hasProductInQueue;
        },
        enumerable: false,
        configurable: true
    });
    //VISUAL PARMS
    StilettoStore.prototype.SET_ALT_BACKGROUND = function (value) {
        this.altBackground = value;
    };
    StilettoStore.prototype.toggleAltBackground = function (value) {
        this.SET_ALT_BACKGROUND(isBoolean(value) ? value : !this.altBackground);
    };
    //QUEUE PARAMS
    StilettoStore.prototype.SET_GRID_SHOWN = function (value) {
        this.gridIsShown = value;
    };
    StilettoStore.prototype.setGridShown = function (value) {
        this.SET_GRID_SHOWN(value);
    };
    StilettoStore.prototype.SET_LOADING_ACTIONS_GLOBAL = function (types) {
        this.loadingActionsGlobal = __spreadArray([], types, true);
    };
    StilettoStore.prototype.resetLoadingActionsGlobal = function () {
        this.SET_LOADING_ACTIONS_GLOBAL([]);
    };
    StilettoStore.prototype.toggleGlobalLoadingAction = function (_a) {
        var action = _a.action, add = _a.add;
        var result = toggleItemForceHelper({
            item: action,
            allowDuplicates: true,
            add: add,
            array: this.loadingActionsGlobal,
        });
        this.SET_LOADING_ACTIONS_GLOBAL(result);
    };
    StilettoStore.prototype.REPLACE_PRODUCT_QUEUE = function (products) {
        this.productQueue = products;
    };
    StilettoStore.prototype.ADD_PRODUCT_TO_QUEUE = function (product) {
        this.productQueue.push(product);
    };
    StilettoStore.prototype.REPLACE_PRODUCT_IN_QUEUE = function (params) {
        var updatedProduct = params.updatedProduct, currentProduct = params.currentProduct;
        this.productQueue = replaceItemInArrayWithId(this.productQueue, currentProduct, updatedProduct);
    };
    StilettoStore.prototype.replaceSpecificProductImage = function (_a) {
        var imageId = _a.imageId, updatedProduct = _a.updatedProduct;
        var currentProduct = this.productQueue.find(function (_a) {
            var id = _a.id;
            return id === updatedProduct.id;
        });
        var oldImage = currentProduct.images.find(function (_a) {
            var id = _a.id;
            return id === imageId;
        });
        var newImage = updatedProduct.images.find(function (_a) {
            var id = _a.id;
            return id === imageId;
        });
        var newImages = replaceItemInArrayWithId(currentProduct.images, oldImage, newImage);
        var clonedProduct = __assign({}, currentProduct);
        clonedProduct.images = newImages;
        this.REPLACE_PRODUCT_IN_QUEUE({ currentProduct: currentProduct, updatedProduct: clonedProduct });
    };
    StilettoStore.prototype.REMOVE_PRODUCT_FROM_QUEUE = function (product) {
        this.productQueue = removeItemFromArray(this.productQueue, product);
    };
    StilettoStore.prototype.removeProductFromQueue = function (productId) {
        var foundProduct = this.productQueue.find(function (_a) {
            var id = _a.id;
            return id === productId;
        });
        if (foundProduct)
            this.REMOVE_PRODUCT_FROM_QUEUE(foundProduct);
    };
    StilettoStore.prototype.getProductToBeProcessed = function (id) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var product, _b, addToQueue, error_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 5, , 6]);
                        if (this.productQueue.length >= NUMBER_OF_QUEUE_ITEMS)
                            return [2 /*return*/, this.currentProduct];
                        if (!id) return [3 /*break*/, 2];
                        return [4 /*yield*/, getStilettoProductById({ id: id })];
                    case 1:
                        _b = _c.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, getStilettoProductToBeProcessed({ currentProductId: (_a = this.currentProduct) === null || _a === void 0 ? void 0 : _a.id })];
                    case 3:
                        _b = _c.sent();
                        _c.label = 4;
                    case 4:
                        product = _b;
                        addToQueue = isProductUniqueAndNotSubmitting(product, this.productQueue, this.submittingIds);
                        if (addToQueue)
                            this.ADD_PRODUCT_TO_QUEUE(product);
                        if (shouldGetMoreProducts(product, this.productQueue, NUMBER_OF_QUEUE_ITEMS, this.submittingIds))
                            this.getProductToBeProcessed();
                        return [2 /*return*/, product];
                    case 5:
                        error_1 = _c.sent();
                        throw error_1;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    StilettoStore.prototype.refreshCurrentProduct = function (productId) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var id, updatedProduct, error_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        id = (productId || ((_a = this.currentProduct) === null || _a === void 0 ? void 0 : _a.id));
                        return [4 /*yield*/, getStilettoProductById({ id: id })];
                    case 1:
                        updatedProduct = _b.sent();
                        this.REPLACE_PRODUCT_IN_QUEUE({ updatedProduct: updatedProduct, currentProduct: this.currentProduct });
                        return [2 /*return*/, updatedProduct];
                    case 2:
                        error_2 = _b.sent();
                        throw error_2;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(StilettoStore.prototype, "isInitiailizingCanvases", {
        //EDITS TO BE PERFORMED
        get: function () {
            var _this = this;
            return !this.stilettoImageComponents.every(function (_a) {
                var id = _a.id;
                return _this.canvasesCreated.includes(id);
            });
        },
        enumerable: false,
        configurable: true
    });
    StilettoStore.prototype.SET_CANVASES_CREATED = function (data) {
        this.canvasesCreated = data;
    };
    StilettoStore.prototype.setCanvasesCreated = function (data) {
        this.SET_CANVASES_CREATED(data);
    };
    StilettoStore.prototype.SET_STAGED_IMAGE_ORDER = function (items) {
        this.stagedImageOrder = items;
    };
    StilettoStore.prototype.setStagedImageOrder = function (items) {
        this.SET_STAGED_IMAGE_ORDER(items);
    };
    StilettoStore.prototype.resetStagedImageOrder = function () {
        this.SET_STAGED_IMAGE_ORDER([]);
    };
    StilettoStore.prototype.SET_EDIT_TO_BE_PERFORMED = function (newArray) {
        this.editToBePerformed = newArray;
    };
    StilettoStore.prototype.addEditToBePerformed = function (data) {
        try {
            this.toggleImagePerformingEdit({ imageId: data.imageId, value: true }); //toggles off when removed
            var newItem = __assign(__assign({}, data), { uniqueId: uuidv4() });
            var currentEdits = this.editToBePerformed || [];
            this.SET_EDIT_TO_BE_PERFORMED(__spreadArray(__spreadArray([], currentEdits, true), [newItem], false));
        }
        catch (error) {
            throw error;
        }
    };
    StilettoStore.prototype.removeEditToBePerformed = function (data) {
        var newArray = removeItemFromArray(this.editToBePerformed, data);
        this.SET_EDIT_TO_BE_PERFORMED(newArray);
        this.toggleImagePerformingEdit({ imageId: data.imageId, value: false }); //toggles on when added
    };
    //SELECTED IMAGES
    StilettoStore.prototype.SET_SELECTED_IMAGES = function (ids) {
        this.selectedImageIds = __spreadArray([], ids, true);
    };
    StilettoStore.prototype.onSelectImage = function (ids) {
        this.SET_SELECTED_IMAGES(toggleItemsInArray(this.selectedImageIds, ids));
    };
    StilettoStore.prototype.clearSelectedImages = function () {
        this.SET_SELECTED_IMAGES([]);
    };
    //SET EDITS METHODS
    StilettoStore.prototype.findMatchingEdit = function (imageId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.edits[imageId] || defaultStoreStilettoEdit(imageId)];
            });
        });
    };
    StilettoStore.prototype.findMatchingComponent = function (imageId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.stilettoImageComponents.find(function (_a) {
                        var id = _a.id;
                        return id === imageId;
                    })];
            });
        });
    };
    StilettoStore.prototype.SET_EDITS = function (data) {
        var _a;
        this.edits = __assign(__assign({}, this.edits), (_a = {}, _a[data.id] = data, _a));
    };
    StilettoStore.prototype.SET_ALL_EDITS = function (edits) {
        this.edits = edits;
    };
    StilettoStore.prototype.clearAllEdits = function () {
        this.SET_ALL_EDITS({});
    };
    StilettoStore.prototype.resetImageOpenEditType = function (_a) {
        var imageId = _a.imageId;
        return __awaiter(this, void 0, void 0, function () {
            var foundEdit, newEditItem, error_3;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.findMatchingEdit(imageId)];
                    case 1:
                        foundEdit = _b.sent();
                        newEditItem = __assign(__assign({}, foundEdit), { openEditType: null });
                        this.SET_EDITS(__assign({}, newEditItem));
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _b.sent();
                        throw error_3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    StilettoStore.prototype.setOpenEditType = function (_a) {
        var imageId = _a.imageId, editType = _a.editType;
        return __awaiter(this, void 0, void 0, function () {
            var foundEdit, newEditItem;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.findMatchingEdit(imageId)];
                    case 1:
                        foundEdit = _b.sent();
                        newEditItem = __assign(__assign({}, foundEdit), { openEditType: editType });
                        this.SET_EDITS(__assign({}, newEditItem));
                        return [2 /*return*/];
                }
            });
        });
    };
    StilettoStore.prototype.toggleImageEditDisabled = function (_a) {
        var imageId = _a.imageId, value = _a.value;
        return __awaiter(this, void 0, void 0, function () {
            var foundEdit, newEditItem;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.findMatchingEdit(imageId)];
                    case 1:
                        foundEdit = _b.sent();
                        newEditItem = __assign(__assign({}, foundEdit), { editDisabled: isBoolean(value) ? !!value : !value });
                        this.SET_EDITS(__assign({}, newEditItem));
                        return [2 /*return*/];
                }
            });
        });
    };
    StilettoStore.prototype.toggleImagePerformingEdit = function (_a) {
        var imageId = _a.imageId, value = _a.value;
        return __awaiter(this, void 0, void 0, function () {
            var foundEdit, newEditItem;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.findMatchingEdit(imageId)];
                    case 1:
                        foundEdit = _b.sent();
                        newEditItem = __assign(__assign({}, foundEdit), { isPerformingEdit: isBoolean(value) ? !!value : !value });
                        this.SET_EDITS(__assign({}, newEditItem));
                        return [2 /*return*/];
                }
            });
        });
    };
    StilettoStore.prototype.toggleImageLoadingAction = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var imageId, action, add, foundEdit, selectedItemLoadingActions, loadingActions, newEditItem;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        imageId = data.imageId, action = data.action, add = data.add;
                        return [4 /*yield*/, this.findMatchingEdit(imageId)];
                    case 1:
                        foundEdit = _a.sent();
                        selectedItemLoadingActions = foundEdit.loadingActions;
                        loadingActions = toggleItemForceHelper({
                            item: action,
                            allowDuplicates: false,
                            add: add,
                            array: selectedItemLoadingActions,
                        });
                        newEditItem = __assign(__assign({}, foundEdit), { loadingActions: loadingActions });
                        this.SET_EDITS(__assign({}, newEditItem));
                        return [2 /*return*/];
                }
            });
        });
    };
    StilettoStore.prototype.initializeCanvas = function (_a) {
        var _b, e_1, _c, _d;
        var _e;
        var canvas = _a.canvas, imageId = _a.imageId;
        return __awaiter(this, void 0, void 0, function () {
            var imageComp, serverImageEdits, _f, stilettoEditsOrder_1, stilettoEditsOrder_1_1, editType, editParams, error_4, e_1_1;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        imageComp = (_e = this.stilettoImageComponents) === null || _e === void 0 ? void 0 : _e.find(function (_a) {
                            var id = _a.id;
                            return id === imageId;
                        });
                        if (!imageComp) return [3 /*break*/, 18];
                        serverImageEdits = imageComp.serverEdit;
                        _g.label = 1;
                    case 1:
                        _g.trys.push([1, 12, 13, 18]);
                        _f = true, stilettoEditsOrder_1 = __asyncValues(stilettoEditsOrder);
                        _g.label = 2;
                    case 2: return [4 /*yield*/, stilettoEditsOrder_1.next()];
                    case 3:
                        if (!(stilettoEditsOrder_1_1 = _g.sent(), _b = stilettoEditsOrder_1_1.done, !_b)) return [3 /*break*/, 11];
                        _d = stilettoEditsOrder_1_1.value;
                        _f = false;
                        _g.label = 4;
                    case 4:
                        _g.trys.push([4, , 9, 10]);
                        editType = _d;
                        editParams = initializeCanvasEditCreator(editType, serverImageEdits);
                        if (!editParams) return [3 /*break*/, 8];
                        _g.label = 5;
                    case 5:
                        _g.trys.push([5, 7, , 8]);
                        return [4 /*yield*/, this.performImageEdit({
                                canvas: canvas,
                                imageId: imageId,
                                addToEditHistory: false,
                                doNotRenderEditedImage: true,
                                editType: editType,
                                editParams: editParams,
                            })];
                    case 6:
                        _g.sent();
                        return [3 /*break*/, 8];
                    case 7:
                        error_4 = _g.sent();
                        throw error_4;
                    case 8: return [3 /*break*/, 10];
                    case 9:
                        _f = true;
                        return [7 /*endfinally*/];
                    case 10: return [3 /*break*/, 2];
                    case 11: return [3 /*break*/, 18];
                    case 12:
                        e_1_1 = _g.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 18];
                    case 13:
                        _g.trys.push([13, , 16, 17]);
                        if (!(!_f && !_b && (_c = stilettoEditsOrder_1.return))) return [3 /*break*/, 15];
                        return [4 /*yield*/, _c.call(stilettoEditsOrder_1)];
                    case 14:
                        _g.sent();
                        _g.label = 15;
                    case 15: return [3 /*break*/, 17];
                    case 16:
                        if (e_1) throw e_1.error;
                        return [7 /*endfinally*/];
                    case 17: return [7 /*endfinally*/];
                    case 18: return [2 /*return*/];
                }
            });
        });
    };
    StilettoStore.prototype.performImageEdit = function (data) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var foundEdit, previousEditsPerformed, editsPerformed, error_5;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.findMatchingEdit(data.imageId)];
                    case 1:
                        foundEdit = _b.sent();
                        previousEditsPerformed = ((_a = last(foundEdit.editHistory)) === null || _a === void 0 ? void 0 : _a.editsPerformed) || {};
                        return [4 /*yield*/, performImageEditReturnHistory(__assign(__assign({}, data), { previousEditsPerformed: previousEditsPerformed }))];
                    case 2:
                        editsPerformed = _b.sent();
                        return [4 /*yield*/, this.addImageEditToHistory(__assign(__assign({}, data), editsPerformed))];
                    case 3:
                        _b.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        error_5 = _b.sent();
                        throw error_5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    StilettoStore.prototype.addImageEditToHistory = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var doNotRenderEditedImage, imageId_1, canvas_1, editsPerformed, editType_1, foundEdit_1, renderImage, editedImage, _a, newEditItem, editHistoryItem, editHistory, error_6;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 5, , 6]);
                        doNotRenderEditedImage = data.doNotRenderEditedImage, imageId_1 = data.imageId, canvas_1 = data.canvas, editsPerformed = data.editsPerformed, editType_1 = data.editType;
                        return [4 /*yield*/, this.findMatchingEdit(imageId_1)];
                    case 1:
                        foundEdit_1 = _b.sent();
                        renderImage = function () { return __awaiter(_this, void 0, void 0, function () {
                            var isRenderFull, result, _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        isRenderFull = editType_1 === StilettoEditTypes.renderFullResoltion;
                                        if (!isRenderFull) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.renderImageFullResolution({ canvas: canvas_1, imageId: imageId_1 })];
                                    case 1:
                                        _a = _b.sent();
                                        return [3 /*break*/, 4];
                                    case 2: return [4 /*yield*/, this.renderImageSmallResolution({ canvas: canvas_1, imageId: imageId_1 })];
                                    case 3:
                                        _a = _b.sent();
                                        _b.label = 4;
                                    case 4:
                                        result = _a;
                                        return [4 /*yield*/, this.findMatchingEdit(imageId_1)];
                                    case 5:
                                        foundEdit_1 = _b.sent();
                                        return [2 /*return*/, result];
                                }
                            });
                        }); };
                        if (!!doNotRenderEditedImage) return [3 /*break*/, 3];
                        return [4 /*yield*/, renderImage()];
                    case 2:
                        _a = _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        _a = foundEdit_1.editedImage;
                        _b.label = 4;
                    case 4:
                        editedImage = _a;
                        newEditItem = __assign(__assign({}, foundEdit_1), { editedImage: editedImage, editedImageIsFullResolution: !doNotRenderEditedImage ? foundEdit_1.editedImageIsFullResolution : false });
                        if (data.addToEditHistory) {
                            editHistoryItem = {
                                editsPerformed: editsPerformed,
                                editType: editType_1,
                                //  canvasJSON: canvasToJSON(canvas),
                            };
                            editHistory = __spreadArray(__spreadArray([], foundEdit_1.editHistory, true), [editHistoryItem], false);
                            newEditItem = __assign(__assign({}, newEditItem), { editHistory: editHistory });
                        }
                        this.SET_EDITS(__assign({}, newEditItem));
                        return [3 /*break*/, 6];
                    case 5:
                        error_6 = _b.sent();
                        throw error_6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    StilettoStore.prototype.undoImageEdit = function (_a) {
        var imageId = _a.imageId;
        return __awaiter(this, void 0, void 0, function () {
            var foundEdit, newEditHistory, newEditItem, lastEdit, error_7;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.findMatchingEdit(imageId)];
                    case 1:
                        foundEdit = _b.sent();
                        newEditHistory = removeItemFromArray(foundEdit.editHistory, last(foundEdit.editHistory));
                        newEditItem = __assign(__assign({}, foundEdit), { editHistory: newEditHistory });
                        this.SET_EDITS(__assign({}, newEditItem));
                        lastEdit = last(newEditHistory);
                        if (!lastEdit)
                            return [2 /*return*/, this.resetEditsToDefault({ imageId: imageId })];
                        this.addEditToBePerformed({
                            imageId: imageId,
                            editType: StilettoEditTypes.undo,
                            editParams: newEditHistory,
                            addToEditHistory: false,
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        error_7 = _b.sent();
                        throw error_7;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    StilettoStore.prototype.resetCanvasBackToSelectedImageURL = function (_a) {
        var imageId = _a.imageId;
        return __awaiter(this, void 0, void 0, function () {
            var newEditItem;
            return __generator(this, function (_b) {
                newEditItem = __assign({}, defaultStoreStilettoEdit(imageId));
                this.SET_EDITS(__assign({}, newEditItem));
                this.addEditToBePerformed({
                    editType: StilettoEditTypes.reset,
                    imageId: imageId,
                    editParams: null,
                    addToEditHistory: true,
                });
                return [2 /*return*/];
            });
        });
    };
    StilettoStore.prototype.resetEditsToDefault = function (_a) {
        var imageId = _a.imageId;
        return __awaiter(this, void 0, void 0, function () {
            var newEditItem;
            return __generator(this, function (_b) {
                newEditItem = __assign({}, defaultStoreStilettoEdit(imageId));
                this.SET_EDITS(__assign({}, newEditItem));
                this.addEditToBePerformed({
                    editType: StilettoEditTypes.reset,
                    imageId: imageId,
                    editParams: null,
                    addToEditHistory: false,
                    doNotRenderEditedImage: true,
                });
                return [2 /*return*/];
            });
        });
    };
    StilettoStore.prototype.resetAllEditsToDefault = function () {
        var _this = this;
        this.stilettoImageComponents.forEach(function (_a) {
            var id = _a.id;
            _this.resetEditsToDefault({ imageId: id });
        });
    };
    StilettoStore.prototype.requestRenderImagesFullResolution = function () {
        var _this = this;
        this.imageComponentsWithEdits
            .filter(function (_a) {
            var editedImageIsFullResolution = _a.editedImageIsFullResolution;
            return !editedImageIsFullResolution;
        })
            .forEach(function (_a) {
            var id = _a.id;
            _this.addEditToBePerformed({
                imageId: id,
                editType: StilettoEditTypes.renderFullResoltion,
                editParams: null,
                addToEditHistory: false,
                doNotRenderEditedImage: false,
            });
        });
    };
    StilettoStore.prototype.renderImageFullResolution = function (_a) {
        var imageId = _a.imageId, canvas = _a.canvas;
        return __awaiter(this, void 0, void 0, function () {
            var foundEdit, img, editedImage, newEditItem, error_8;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.findMatchingEdit(imageId)];
                    case 1:
                        foundEdit = _b.sent();
                        img = canvas.backgroundImage;
                        return [4 /*yield*/, createHighQualityDataURLForStore(canvas, img)];
                    case 2:
                        editedImage = _b.sent();
                        newEditItem = __assign(__assign({}, foundEdit), { editedImage: editedImage, editedImageIsFullResolution: true });
                        this.SET_EDITS(__assign({}, newEditItem));
                        return [2 /*return*/, editedImage];
                    case 3:
                        error_8 = _b.sent();
                        throw error_8;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    StilettoStore.prototype.renderImageSmallResolution = function (_a) {
        var imageId = _a.imageId, canvas = _a.canvas;
        return __awaiter(this, void 0, void 0, function () {
            var foundEdit, editedImage, newEditItem, error_9;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.findMatchingEdit(imageId)];
                    case 1:
                        foundEdit = _b.sent();
                        return [4 /*yield*/, createSmallDataURLForStore(canvas)];
                    case 2:
                        editedImage = _b.sent();
                        newEditItem = __assign(__assign({}, foundEdit), { editedImage: editedImage, editedImageIsFullResolution: false });
                        this.SET_EDITS(__assign({}, newEditItem));
                        return [2 /*return*/, editedImage];
                    case 3:
                        error_9 = _b.sent();
                        throw error_9;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(StilettoStore.prototype, "imageComponentsWithEdits", {
        //UPLOAD HANDLERS
        get: function () {
            return this.stilettoImageComponents.filter(function (_a) {
                var editedImage = _a.editedImage;
                return editedImage;
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StilettoStore.prototype, "renderedPendingEdits", {
        get: function () {
            return !this.imageComponentsWithEdits.some(function (_a) {
                var isPerformingEdit = _a.isPerformingEdit;
                return isPerformingEdit;
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StilettoStore.prototype, "totalUploadProgress", {
        get: function () {
            return round(mean(__spreadArray([], Object.values(this.bulkUploadProgress), true)));
        },
        enumerable: false,
        configurable: true
    });
    StilettoStore.prototype.SET_BULK_UPLOAD_PROGRESS = function (_a) {
        var _b;
        var id = _a.id, progress = _a.progress;
        this.bulkUploadProgress = __assign(__assign({}, this.bulkUploadProgress), (_b = {}, _b[id] = progress, _b));
    };
    StilettoStore.prototype.SET_ALL_BULK_UPLOAD_PROGRESS = function (data) {
        this.bulkUploadProgress = __assign({}, data);
    };
    StilettoStore.prototype.resetUploadForProduct = function (productId) {
        this.SET_ALL_BULK_UPLOAD_PROGRESS(omit(__assign({}, this.bulkUploadProgress), [productId]));
    };
    StilettoStore.prototype.createUploadAllChangedImagesArray = function () {
        var _a, e_2, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var imageItems, _d, _e, _f, image, foundEdit, edits, blob, e_2_1, error_10;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        _g.trys.push([0, 18, , 19]);
                        if (isEmpty(this.imageComponentsWithEdits))
                            return [2 /*return*/, []];
                        return [4 /*yield*/, this.ensureEmptyAndRenderedInFullResolution()];
                    case 1:
                        _g.sent();
                        imageItems = [];
                        _g.label = 2;
                    case 2:
                        _g.trys.push([2, 11, 12, 17]);
                        _d = true, _e = __asyncValues(this.imageComponentsWithEdits);
                        _g.label = 3;
                    case 3: return [4 /*yield*/, _e.next()];
                    case 4:
                        if (!(_f = _g.sent(), _a = _f.done, !_a)) return [3 /*break*/, 10];
                        _c = _f.value;
                        _d = false;
                        _g.label = 5;
                    case 5:
                        _g.trys.push([5, , 8, 9]);
                        image = _c;
                        return [4 /*yield*/, this.findMatchingEdit(image.id)];
                    case 6:
                        foundEdit = _g.sent();
                        edits = createUploadItemEdits(foundEdit);
                        return [4 /*yield*/, dataURLToBlob(foundEdit.editedImage)];
                    case 7:
                        blob = _g.sent();
                        imageItems.push({ photo_id: image.id, image: blob, edits: edits });
                        return [3 /*break*/, 9];
                    case 8:
                        _d = true;
                        return [7 /*endfinally*/];
                    case 9: return [3 /*break*/, 3];
                    case 10: return [3 /*break*/, 17];
                    case 11:
                        e_2_1 = _g.sent();
                        e_2 = { error: e_2_1 };
                        return [3 /*break*/, 17];
                    case 12:
                        _g.trys.push([12, , 15, 16]);
                        if (!(!_d && !_a && (_b = _e.return))) return [3 /*break*/, 14];
                        return [4 /*yield*/, _b.call(_e)];
                    case 13:
                        _g.sent();
                        _g.label = 14;
                    case 14: return [3 /*break*/, 16];
                    case 15:
                        if (e_2) throw e_2.error;
                        return [7 /*endfinally*/];
                    case 16: return [7 /*endfinally*/];
                    case 17: return [2 /*return*/, imageItems || []];
                    case 18:
                        error_10 = _g.sent();
                        throw error_10;
                    case 19: return [2 /*return*/];
                }
            });
        });
    };
    StilettoStore.prototype.uploadAllChangedImages = function (_a) {
        var refreshProduct = _a.refreshProduct, imageItems = _a.imageItems, showLoadingIndicators = _a.showLoadingIndicators;
        return __awaiter(this, void 0, void 0, function () {
            var toggleUploadImagesMethod, _b, error_11;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (isEmpty(this.imageComponentsWithEdits))
                            return [2 /*return*/];
                        toggleUploadImagesMethod = function (add) {
                            _this.imageComponentsWithEdits
                                .map(function (_a) {
                                var id = _a.id;
                                return id;
                            })
                                .forEach(function (imageId) {
                                return _this.toggleImageLoadingAction({ imageId: imageId, action: StilettoLoadingActionEditImage.uploadingImage, add: add });
                            });
                        };
                        if (showLoadingIndicators)
                            toggleUploadImagesMethod(true);
                        this.toggleGlobalLoadingAction({ action: StilettoLoadingActionGlobal.uploadAllImages, add: true });
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 8, , 9]);
                        if (!isEmpty(imageItems)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.createUploadAllChangedImagesArray()];
                    case 2:
                        _b = _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        _b = imageItems;
                        _c.label = 4;
                    case 4:
                        imageItems = _b;
                        return [4 /*yield*/, uploadBulkSaveEditImageChunked(imageItems, this.currentProductId)];
                    case 5:
                        _c.sent();
                        if (!refreshProduct) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.refreshCurrentProduct()];
                    case 6:
                        _c.sent();
                        this.resetAllEditsToDefault();
                        _c.label = 7;
                    case 7:
                        if (showLoadingIndicators)
                            toggleUploadImagesMethod(false);
                        return [3 /*break*/, 9];
                    case 8:
                        error_11 = _c.sent();
                        $_toast_error({
                            text: 'Error uploading edited images. Please report this bug to the stiletto channel on Slack.',
                        });
                        throw error_11;
                    case 9:
                        this.toggleGlobalLoadingAction({ action: StilettoLoadingActionGlobal.uploadAllImages, add: false });
                        return [2 /*return*/];
                }
            });
        });
    };
    StilettoStore.prototype.uploadChangedImage = function (_a) {
        var imageId = _a.imageId, multiple = _a.multiple;
        return __awaiter(this, void 0, void 0, function () {
            var foundEdit, _b, image, edits, updatedProduct, error_12;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.toggleImageLoadingAction({ imageId: imageId, action: StilettoLoadingActionEditImage.uploadingImage, add: true });
                        this.toggleGlobalLoadingAction({ action: StilettoLoadingActionGlobal.uploadAllImages, add: true });
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 8, , 9]);
                        if (!!multiple) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.ensureEmptyAndRenderedInFullResolution()];
                    case 2:
                        _c.sent();
                        _c.label = 3;
                    case 3: return [4 /*yield*/, this.findMatchingEdit(imageId)];
                    case 4:
                        foundEdit = _c.sent();
                        return [4 /*yield*/, createUploadItem(foundEdit)];
                    case 5:
                        _b = _c.sent(), image = _b.image, edits = _b.edits;
                        return [4 /*yield*/, this.changeImageOrder({ items: this.stagedImageOrder, refreshProduct: false })];
                    case 6:
                        _c.sent();
                        return [4 /*yield*/, uploadSavedEditImage({ image: image, edits: edits, photo_id: imageId }, {
                                onUploadProgress: function (data) {
                                    return _this.SET_BULK_UPLOAD_PROGRESS({
                                        id: _this.currentProductId,
                                        progress: createUploadProgressDetails(data).progress,
                                    });
                                },
                            })];
                    case 7:
                        updatedProduct = _c.sent();
                        this.toggleGlobalLoadingAction({ action: StilettoLoadingActionGlobal.uploadAllImages, add: false });
                        if (!multiple) {
                            this.REPLACE_PRODUCT_IN_QUEUE({ updatedProduct: updatedProduct, currentProduct: this.currentProduct });
                            this.resetEditsToDefault({ imageId: imageId });
                        }
                        this.resetUploadForProduct(this.currentProductId);
                        return [3 /*break*/, 9];
                    case 8:
                        error_12 = _c.sent();
                        throw error_12;
                    case 9:
                        this.toggleImageLoadingAction({ imageId: imageId, action: StilettoLoadingActionEditImage.uploadingImage, add: false });
                        return [2 /*return*/];
                }
            });
        });
    };
    StilettoStore.prototype.ensureEmptyAndRenderedInFullResolution = function () {
        var _a, e_3, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var _d, _e, _f, image, e_3_1, error_13;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        _g.trys.push([0, 18, , 19]);
                        return [4 /*yield*/, this.ensureEditToBePerformedIsEmpty()];
                    case 1:
                        _g.sent();
                        return [4 /*yield*/, this.requestRenderImagesFullResolution()];
                    case 2:
                        _g.sent();
                        _g.label = 3;
                    case 3:
                        _g.trys.push([3, 11, 12, 17]);
                        _d = true, _e = __asyncValues(this.imageComponentsWithEdits);
                        _g.label = 4;
                    case 4: return [4 /*yield*/, _e.next()];
                    case 5:
                        if (!(_f = _g.sent(), _a = _f.done, !_a)) return [3 /*break*/, 10];
                        _c = _f.value;
                        _d = false;
                        _g.label = 6;
                    case 6:
                        _g.trys.push([6, , 8, 9]);
                        image = _c;
                        return [4 /*yield*/, this.ensureImageIsRenderedInFullResolution({ imageId: image.id })];
                    case 7:
                        _g.sent();
                        return [3 /*break*/, 9];
                    case 8:
                        _d = true;
                        return [7 /*endfinally*/];
                    case 9: return [3 /*break*/, 4];
                    case 10: return [3 /*break*/, 17];
                    case 11:
                        e_3_1 = _g.sent();
                        e_3 = { error: e_3_1 };
                        return [3 /*break*/, 17];
                    case 12:
                        _g.trys.push([12, , 15, 16]);
                        if (!(!_d && !_a && (_b = _e.return))) return [3 /*break*/, 14];
                        return [4 /*yield*/, _b.call(_e)];
                    case 13:
                        _g.sent();
                        _g.label = 14;
                    case 14: return [3 /*break*/, 16];
                    case 15:
                        if (e_3) throw e_3.error;
                        return [7 /*endfinally*/];
                    case 16: return [7 /*endfinally*/];
                    case 17: return [3 /*break*/, 19];
                    case 18:
                        error_13 = _g.sent();
                        throw error_13;
                    case 19: return [2 /*return*/];
                }
            });
        });
    };
    StilettoStore.prototype.ensureImageIsRenderedInFullResolution = function (_a) {
        var imageId = _a.imageId;
        return __awaiter(this, void 0, void 0, function () {
            var expection;
            var _this = this;
            return __generator(this, function (_b) {
                expection = function () { return _this.edits[imageId].editedImageIsFullResolution === true; };
                return [2 /*return*/, this.waitForValueToChange({ expection: expection })];
            });
        });
    };
    StilettoStore.prototype.ensureEditToBePerformedIsEmpty = function () {
        return __awaiter(this, void 0, void 0, function () {
            var expection;
            var _this = this;
            return __generator(this, function (_a) {
                expection = function () { return isEmpty(_this.editToBePerformed); };
                return [2 /*return*/, this.waitForValueToChange({ expection: expection })];
            });
        });
    };
    StilettoStore.prototype.waitForValueToChange = function (_a) {
        var expection = _a.expection, maxTimesToLoop = _a.maxTimesToLoop, loopNumber = _a.loopNumber;
        return __awaiter(this, void 0, void 0, function () {
            var _maxTimesToLoop, _loopNumber;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _maxTimesToLoop = maxTimesToLoop || 300;
                        _loopNumber = loopNumber || 0;
                        if (_maxTimesToLoop === _loopNumber)
                            throw 'Error Waiting For Value To Change';
                        return [4 /*yield*/, timeout(30)];
                    case 1:
                        _b.sent();
                        if (expection())
                            return [2 /*return*/, true];
                        return [2 /*return*/, this.waitForValueToChange({
                                expection: expection,
                                maxTimesToLoop: _maxTimesToLoop,
                                loopNumber: _loopNumber + 1,
                            })];
                }
            });
        });
    };
    StilettoStore.prototype.changeImageOrder = function (_a) {
        var items = _a.items, refreshProduct = _a.refreshProduct;
        return __awaiter(this, void 0, void 0, function () {
            var _b, error_14;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (isEmpty(items))
                            return [2 /*return*/];
                        this.toggleGlobalLoadingAction({ action: StilettoLoadingActionGlobal.reorderImages, add: true });
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 6, , 7]);
                        items.forEach(function (_a) {
                            var id = _a.id;
                            _this.toggleImageLoadingAction({ imageId: id, action: StilettoLoadingActionEditImage.reordering, add: true });
                        });
                        return [4 /*yield*/, changeImageOrder(items)];
                    case 2:
                        _c.sent();
                        if (!(this.loadingActionsGlobal.filter(function (g) { return g === StilettoLoadingActionGlobal.reorderImages; }).length <= 1)) return [3 /*break*/, 5];
                        _b = refreshProduct;
                        if (!_b) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.refreshCurrentProduct()];
                    case 3:
                        _b = (_c.sent());
                        _c.label = 4;
                    case 4:
                        _b;
                        items.forEach(function (_a) {
                            var id = _a.id;
                            _this.toggleImageLoadingAction({ imageId: id, action: StilettoLoadingActionEditImage.reordering, add: false });
                        });
                        _c.label = 5;
                    case 5:
                        this.resetStagedImageOrder();
                        return [3 /*break*/, 7];
                    case 6:
                        error_14 = _c.sent();
                        throw error_14;
                    case 7:
                        this.toggleGlobalLoadingAction({ action: StilettoLoadingActionGlobal.reorderImages, add: false });
                        return [2 /*return*/];
                }
            });
        });
    };
    StilettoStore.prototype.sortImagesInDOM = function (newImages) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var newImagesArray, currentProductImages, currentProduct, updatedProduct;
            return __generator(this, function (_b) {
                newImagesArray = [];
                currentProductImages = ((_a = this.currentProduct) === null || _a === void 0 ? void 0 : _a.images) || [];
                newImages.forEach(function (newImage) {
                    var currentImageFromAPI = currentProductImages.find(function (_a) {
                        var id = _a.id;
                        return id === newImage.id;
                    });
                    if (currentImageFromAPI)
                        newImagesArray.push(currentImageFromAPI);
                });
                currentProduct = this.currentProduct;
                updatedProduct = __assign(__assign({}, currentProduct), { images: newImagesArray });
                this.REPLACE_PRODUCT_IN_QUEUE({ updatedProduct: updatedProduct, currentProduct: this.currentProduct });
                return [2 /*return*/];
            });
        });
    };
    StilettoStore.prototype.makeFeaturedImage = function (imageId) {
        return __awaiter(this, void 0, void 0, function () {
            var filteredImages, newOrder, error_15;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.toggleGlobalLoadingAction({ action: StilettoLoadingActionGlobal.makeFeaturedImage, add: true });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        filteredImages = this.stilettoImageComponents.map(function (_a) {
                            var id = _a.id;
                            return id;
                        }).filter(function (id) { return id !== imageId; });
                        newOrder = __spreadArray([imageId], filteredImages, true).map(function (id, order) { return ({
                            id: id,
                            order: order,
                        }); });
                        return [4 /*yield*/, this.changeImageOrder({ items: newOrder, refreshProduct: true })];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_15 = _a.sent();
                        throw error_15;
                    case 4:
                        this.toggleGlobalLoadingAction({ action: StilettoLoadingActionGlobal.makeFeaturedImage, add: false });
                        return [2 /*return*/];
                }
            });
        });
    };
    StilettoStore.prototype.deleteImages = function (imageIds) {
        return __awaiter(this, void 0, void 0, function () {
            var text, updatedProduct, error_16;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        text = 'Are you sure you want to delete these images?';
                        if (!(confirm(text) == true)) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        this.toggleGlobalLoadingAction({ action: StilettoLoadingActionGlobal.deleteImages, add: true });
                        imageIds.forEach(function (imageId) {
                            _this.toggleImageLoadingAction({ imageId: imageId, action: StilettoLoadingActionEditImage.deleteImage, add: true });
                        });
                        return [4 /*yield*/, deleteImages({ imageIds: imageIds, productId: this.currentProductId })];
                    case 2:
                        updatedProduct = _a.sent();
                        this.REPLACE_PRODUCT_IN_QUEUE({ updatedProduct: updatedProduct, currentProduct: this.currentProduct });
                        imageIds.forEach(function (imageId) {
                            _this.toggleImageLoadingAction({ imageId: imageId, action: StilettoLoadingActionEditImage.deleteImage, add: false });
                        });
                        this.toggleGlobalLoadingAction({ action: StilettoLoadingActionGlobal.deleteImages, add: false });
                        $_toast_message({
                            text: "Successfully deleted ".concat(pluralize('image', imageIds.length, true)),
                        });
                        return [4 /*yield*/, this.refreshCurrentProduct()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        error_16 = _a.sent();
                        throw error_16;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    StilettoStore.prototype.splitProduct = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var result, error_17;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, splitProduct(__assign({}, params))];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.refreshCurrentProduct()];
                    case 2:
                        result = _a.sent();
                        this.toggleGlobalLoadingAction({ add: false, action: StilettoLoadingActionGlobal.splitImages });
                        return [2 /*return*/, result];
                    case 3:
                        error_17 = _a.sent();
                        throw error_17;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    StilettoStore.prototype.uploadNewImages = function (_a) {
        var _b;
        var images = _a.images;
        return __awaiter(this, void 0, void 0, function () {
            var oldImageLength, error_18;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.toggleGlobalLoadingAction({ action: StilettoLoadingActionGlobal.uploadNewImages, add: true });
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 4, , 5]);
                        oldImageLength = (_b = this.currentProduct) === null || _b === void 0 ? void 0 : _b.images.length;
                        return [4 /*yield*/, uploadNewImages({ images: images, product_id: this.currentProductId }, {
                                onUploadProgress: function (data) {
                                    return _this.SET_BULK_UPLOAD_PROGRESS({
                                        id: _this.currentProductId,
                                        progress: createUploadProgressDetails(data).progress,
                                    });
                                },
                            })];
                    case 2:
                        _c.sent();
                        return [4 /*yield*/, this.awaitChangeInProductImages(oldImageLength)];
                    case 3:
                        _c.sent();
                        $_toast_success({ text: "Successfully uploaded ".concat(pluralize('image', images.length, true)) });
                        return [3 /*break*/, 5];
                    case 4:
                        error_18 = _c.sent();
                        throw error_18;
                    case 5:
                        storeModules.stiletto.resetUploadForProduct(this.currentProductId);
                        this.toggleGlobalLoadingAction({ action: StilettoLoadingActionGlobal.uploadNewImages, add: false });
                        return [2 /*return*/];
                }
            });
        });
    };
    StilettoStore.prototype.awaitChangeInProductImages = function (changeInImageLength) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var oldUrls, awaitChangesInSize;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        oldUrls = (_a = this.currentProduct) === null || _a === void 0 ? void 0 : _a.images.map(function (_a) {
                            var url = _a.url;
                            return url;
                        });
                        awaitChangesInSize = function (currentLoop, loop) {
                            if (currentLoop === void 0) { currentLoop = 0; }
                            if (loop === void 0) { loop = 10; }
                            return __awaiter(_this, void 0, void 0, function () {
                                var newUrls;
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            if (loop === currentLoop)
                                                throw 'Please refresh the page and try again.';
                                            return [4 /*yield*/, timeout(loop * 50)];
                                        case 1:
                                            _b.sent(); //arbitrary waiting time
                                            return [4 /*yield*/, ((_a = this.refreshCurrentProduct()) === null || _a === void 0 ? void 0 : _a.then(function (item) { return item.images.map(function (_a) {
                                                    var url = _a.url;
                                                    return url;
                                                }); }))];
                                        case 2:
                                            newUrls = _b.sent();
                                            if (!isEqual(oldUrls, newUrls) || (changeInImageLength && !isEqual(changeInImageLength, newUrls.length)))
                                                return [2 /*return*/];
                                            else
                                                return [2 /*return*/, awaitChangesInSize(currentLoop++)];
                                            return [2 /*return*/];
                                    }
                                });
                            });
                        };
                        return [4 /*yield*/, awaitChangesInSize()];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    StilettoStore.prototype.SET_SUBMITTING_IDS = function (ids) {
        this.submittingIds = ids;
    };
    StilettoStore.prototype.onSubmitAsync = function () {
        return __awaiter(this, void 0, void 0, function () {
            var savedCurrentProductId, currentProductCanvases, imageItems, submitMethod;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        //Add alert to prevent closing early
                        if (isEmpty(this.submittingIds))
                            window.addEventListener('beforeunload', unloadHandlerMessage, { capture: true });
                        this.SET_SUBMITTING_IDS(__spreadArray(__spreadArray([], this.submittingIds, true), [this.currentProductId], false));
                        //submit logic
                        storeModules.stiletto.toggleGlobalLoadingAction({ add: true, action: StilettoLoadingActionGlobal.submitProduct });
                        savedCurrentProductId = Number(this.currentProductId);
                        currentProductCanvases = this.stilettoImageComponents.map(function (_a) {
                            var id = _a.id;
                            return id;
                        });
                        return [4 /*yield*/, this.ensureEmptyAndRenderedInFullResolution()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.createUploadAllChangedImagesArray()];
                    case 2:
                        imageItems = _a.sent();
                        submitMethod = function () { return __awaiter(_this, void 0, void 0, function () {
                            var error_19;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 4, , 5]);
                                        return [4 /*yield*/, Promise.all([
                                                this.changeImageOrder({ items: this.stagedImageOrder, refreshProduct: false }),
                                                storeModules.stiletto.uploadAllChangedImages({
                                                    refreshProduct: false,
                                                    showLoadingIndicators: false,
                                                    imageItems: imageItems,
                                                }),
                                            ])];
                                    case 1:
                                        _a.sent();
                                        return [4 /*yield*/, submitProduct({ id: savedCurrentProductId })];
                                    case 2:
                                        _a.sent();
                                        return [4 /*yield*/, this.getProductToBeProcessed()];
                                    case 3:
                                        _a.sent();
                                        this.SET_SUBMITTING_IDS(removeItemFromArray(this.submittingIds, savedCurrentProductId));
                                        if (isEmpty(this.submittingIds)) {
                                            window.removeEventListener('beforeunload', unloadHandlerMessage, { capture: true });
                                        }
                                        return [3 /*break*/, 5];
                                    case 4:
                                        error_19 = _a.sent();
                                        $_toast_error({
                                            text: 'Error uploading edited images. Please report this bug to the stiletto channel in Slack.',
                                        });
                                        throw error_19;
                                    case 5: return [2 /*return*/];
                                }
                            });
                        }); };
                        submitMethod();
                        this.setCanvasesCreated(this.canvasesCreated.filter(function (v, index) { return currentProductCanvases.indexOf(index) == -1; }));
                        storeModules.stiletto.resetLoadingActionsGlobal();
                        return [2 /*return*/];
                }
            });
        });
    };
    StilettoStore.prototype.saveForLater = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var error_20;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.toggleGlobalLoadingAction({ add: true, action: StilettoLoadingActionGlobal.saveForLater });
                        return [4 /*yield*/, saveForLaterProduct({ product_id: id })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_20 = _a.sent();
                        throw error_20;
                    case 3:
                        storeModules.stiletto.resetLoadingActionsGlobal();
                        return [2 /*return*/];
                }
            });
        });
    };
    StilettoStore.prototype.flagProduct = function (_a) {
        var id = _a.id, reason = _a.reason;
        return __awaiter(this, void 0, void 0, function () {
            var error_21;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        this.toggleGlobalLoadingAction({ add: true, action: StilettoLoadingActionGlobal.flagProduct });
                        return [4 /*yield*/, flagProduct({ product_id: id, reason: reason })];
                    case 1:
                        _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_21 = _b.sent();
                        throw error_21;
                    case 3:
                        storeModules.stiletto.resetLoadingActionsGlobal();
                        return [2 /*return*/];
                }
            });
        });
    };
    StilettoStore.prototype.escalateIncidentAndGetNextProduct = function (_a) {
        var productId = _a.productId, queueId = _a.queueId;
        return __awaiter(this, void 0, void 0, function () {
            var error_22;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 3, , 4]);
                        this.toggleGlobalLoadingAction({ add: true, action: StilettoLoadingActionGlobal.escalatedProduct });
                        return [4 /*yield*/, escalateIncident({ product_id: productId, queue_id: queueId })];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, this.getProductToBeProcessed()];
                    case 2:
                        _b.sent();
                        this.toggleGlobalLoadingAction({ add: false, action: StilettoLoadingActionGlobal.escalatedProduct });
                        return [3 /*break*/, 4];
                    case 3:
                        error_22 = _b.sent();
                        throw error_22;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    StilettoStore.prototype.markIncidentAsResolved = function (productId) {
        return __awaiter(this, void 0, void 0, function () {
            var error_23;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        this.toggleGlobalLoadingAction({ add: true, action: StilettoLoadingActionGlobal.resolveProduct });
                        return [4 /*yield*/, markIncidentAsResolved({ product_id: productId })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.refreshCurrentProduct()];
                    case 2:
                        _a.sent();
                        this.toggleGlobalLoadingAction({ add: false, action: StilettoLoadingActionGlobal.resolveProduct });
                        return [3 /*break*/, 4];
                    case 3:
                        error_23 = _a.sent();
                        throw error_23;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    StilettoStore.prototype.reset = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.REPLACE_PRODUCT_QUEUE([]);
                this.clearAllEdits();
                this.clearSelectedImages();
                this.SET_EDIT_TO_BE_PERFORMED([]);
                this.resetAllEditsToDefault();
                this.resetStagedImageOrder();
                return [2 /*return*/];
            });
        });
    };
    __decorate([
        Mutation
    ], StilettoStore.prototype, "SET_ALT_BACKGROUND", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "toggleAltBackground", null);
    __decorate([
        Mutation
    ], StilettoStore.prototype, "SET_GRID_SHOWN", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "setGridShown", null);
    __decorate([
        Mutation
    ], StilettoStore.prototype, "SET_LOADING_ACTIONS_GLOBAL", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "resetLoadingActionsGlobal", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "toggleGlobalLoadingAction", null);
    __decorate([
        Mutation
    ], StilettoStore.prototype, "REPLACE_PRODUCT_QUEUE", null);
    __decorate([
        Mutation
    ], StilettoStore.prototype, "ADD_PRODUCT_TO_QUEUE", null);
    __decorate([
        Mutation
    ], StilettoStore.prototype, "REPLACE_PRODUCT_IN_QUEUE", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "replaceSpecificProductImage", null);
    __decorate([
        Mutation
    ], StilettoStore.prototype, "REMOVE_PRODUCT_FROM_QUEUE", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "removeProductFromQueue", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "getProductToBeProcessed", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "refreshCurrentProduct", null);
    __decorate([
        Mutation
    ], StilettoStore.prototype, "SET_CANVASES_CREATED", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "setCanvasesCreated", null);
    __decorate([
        Mutation
    ], StilettoStore.prototype, "SET_STAGED_IMAGE_ORDER", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "setStagedImageOrder", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "resetStagedImageOrder", null);
    __decorate([
        Mutation
    ], StilettoStore.prototype, "SET_EDIT_TO_BE_PERFORMED", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "addEditToBePerformed", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "removeEditToBePerformed", null);
    __decorate([
        Mutation
    ], StilettoStore.prototype, "SET_SELECTED_IMAGES", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "onSelectImage", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "clearSelectedImages", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "findMatchingEdit", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "findMatchingComponent", null);
    __decorate([
        Mutation
    ], StilettoStore.prototype, "SET_EDITS", null);
    __decorate([
        Mutation
    ], StilettoStore.prototype, "SET_ALL_EDITS", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "clearAllEdits", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "resetImageOpenEditType", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "setOpenEditType", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "toggleImageEditDisabled", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "toggleImagePerformingEdit", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "toggleImageLoadingAction", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "initializeCanvas", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "performImageEdit", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "addImageEditToHistory", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "undoImageEdit", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "resetCanvasBackToSelectedImageURL", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "resetEditsToDefault", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "resetAllEditsToDefault", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "requestRenderImagesFullResolution", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "renderImageFullResolution", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "renderImageSmallResolution", null);
    __decorate([
        Mutation
    ], StilettoStore.prototype, "SET_BULK_UPLOAD_PROGRESS", null);
    __decorate([
        Mutation
    ], StilettoStore.prototype, "SET_ALL_BULK_UPLOAD_PROGRESS", null);
    __decorate([
        Action
    ], StilettoStore.prototype, "resetUploadForProduct", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "createUploadAllChangedImagesArray", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "uploadAllChangedImages", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "uploadChangedImage", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "ensureEmptyAndRenderedInFullResolution", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "ensureImageIsRenderedInFullResolution", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "ensureEditToBePerformedIsEmpty", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "waitForValueToChange", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "changeImageOrder", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "sortImagesInDOM", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "makeFeaturedImage", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "deleteImages", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "splitProduct", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "uploadNewImages", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "awaitChangeInProductImages", null);
    __decorate([
        Mutation
    ], StilettoStore.prototype, "SET_SUBMITTING_IDS", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "onSubmitAsync", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "saveForLater", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "flagProduct", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "escalateIncidentAndGetNextProduct", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "markIncidentAsResolved", null);
    __decorate([
        Action({ rawError: true })
    ], StilettoStore.prototype, "reset", null);
    StilettoStore = __decorate([
        Module({ namespaced: true, name: stilettoStoreName, preserveState: false })
    ], StilettoStore);
    return StilettoStore;
}(VuexModule));
export default StilettoStore;
