import axios from 'axios';
import { transformErrors } from 'vue2/helpers';
import { storeModules } from '../store';
var localBaseURL = '/'; //TODO talk to BE to make all api calls consistent, should be /api for all routes
var localInstance = axios.create({
    baseURL: localBaseURL,
});
// a response interceptor
localInstance.interceptors.response.use(function (resp) { return resp; }, function (error) {
    var _a, _b;
    return Promise.reject(transformErrors(((_a = error.response) === null || _a === void 0 ? void 0 : _a.data) || error, (_b = error.response) === null || _b === void 0 ? void 0 : _b.status));
});
// a request interceptor interceptor
localInstance.interceptors.request.use(function (config) {
    config.headers['Api-Key'] = process.env.MIX_API_KEY;
    config.headers['Accept'] = 'application/x.fashionphile.v1+json';
    config.headers['authToken'] = storeModules.user.userAuthToken;
    config.headers['Authorization'] = 'bearer ' + storeModules.user.webAppToken; //TODO revisit this to decide if we need to log into web app at all when using the api
    return config;
});
export default localInstance;
