import { InputTypes } from '../showroomAssistant';
import { PaymentMethods } from './interfaces';
import states from 'states-us';
//TODO this needs to be an endpoint because methods can be added, changed, etc.
export var defaultPaymentMethods = [
    {
        name: 'Check',
        value: PaymentMethods.CHECK,
        fields: [],
        showAsOption: true,
    },
    {
        name: 'Direct Deposit',
        value: PaymentMethods.ACH,
        fields: [
            {
                type: InputTypes.Text,
                name: 'Bank Name',
                value: 'achBankName',
            },
            {
                type: InputTypes.Text,
                name: 'Account Number',
                value: 'achAccountNumber',
            },
            {
                type: InputTypes.Text,
                name: 'Routing Number',
                value: 'achRoutingNumber',
            },
        ],
        showAsOption: true,
    },
    {
        name: 'FASHIONPHILE Store Credit (+10% Bonus)',
        value: PaymentMethods.STORE_CREDIT,
        fields: [],
        showAsOption: true,
    },
    {
        name: 'PayPal',
        value: PaymentMethods.PAYPAL,
        fields: [
            {
                type: InputTypes.Email,
                name: 'PayPal Email Address',
                value: 'paypalAddress',
            },
        ],
        showAsOption: true,
    },
    {
        name: 'Neiman Marcus Gift Card (+10% Bonus)',
        value: PaymentMethods.NEIMAN_MARCUS_GIFT_CARD,
        fields: [
            {
                type: InputTypes.Text,
                name: 'Drivers License Number',
                value: 'driversLicenseNumber',
            },
            {
                type: InputTypes.Select,
                name: 'State',
                value: 'driversLicenseState',
                options: states.map(function (state) { return ({ code: state.abbreviation, label: state.name }); }),
            },
            {
                type: InputTypes.Date,
                name: 'Birthdate',
                value: 'birthdate',
            },
        ],
        showAsOption: true,
    },
    {
        name: 'Wire Transfer',
        value: PaymentMethods.WIRE_TRANSFER,
        fields: [],
        showAsOption: false,
    },
];
