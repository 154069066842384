var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { canvasToDataURL, dataURLToBlob } from 'common/imageManipulation';
import isEmpty from 'lodash/isEmpty';
import { addItemToArray, isInArray, removeItemFromArray, toggleItemInArray } from 'vue2/helpers';
import isBoolean from 'lodash/isBoolean';
import { defaultStoreStilettoEdit } from './config';
import last from 'lodash/last';
import { StilettoEditTypes } from './enums';
export var getCacheImages = function (products) {
    return products
        .map(function (product) { return product.images; })
        .reduce(function (acc, curr) {
        return __spreadArray(__spreadArray(__spreadArray([], acc, true), extractAllImagesFullSize('url', curr).map(function (_a) {
            var src = _a.src;
            return src;
        }), true), extractAllImagesFullSize('originalImageUrl', curr).map(function (_a) {
            var src = _a.src;
            return src;
        }), true);
    }, []);
};
export var isProduct = function (data) {
    return !!data && !!(data === null || data === void 0 ? void 0 : data.id);
};
export var isProductUnique = function (data, queue) {
    return isProduct(data) && !queue.map(function (_a) {
        var id = _a.id;
        return id;
    }).includes(data.id);
};
export var isProductUniqueAndNotSubmitting = function (data, queue, submittingIds) {
    return isProduct(data) && isProductUnique(data, queue) && !submittingIds.includes(data.id);
};
export var shouldGetMoreProducts = function (data, queue, maxQueueLength, submittingIds) {
    return isProduct(data) && queue.length < maxQueueLength && !submittingIds.includes(data.id);
};
export var extractFullSizeImage = function (image) {
    return image === null || image === void 0 ? void 0 : image.fullSize;
};
export var extractThumbnailImage = function (image) {
    return image.thumbnail;
};
export var extractAllImagesFullSize = function (type, images) {
    if (!images)
        return [];
    return images.map(function (image) { return ({
        src: extractFullSizeImage(image[type]),
        id: image.id,
        order: image.order,
    }); });
};
export var extractAllImagesThumbnail = function (type, images) {
    if (!images)
        return [];
    return images.map(function (image) { return ({
        src: extractThumbnailImage(image[type]),
        id: image.id,
        order: image.order,
    }); });
};
export var createStoreStilettoImages = function (currentProductImages, featuredImageId) {
    return currentProductImages.map(function (_a) {
        var edit = _a.edit, order = _a.order, id = _a.id, originalImageUrl = _a.originalImageUrl, url = _a.url, preprocessedImageUrl = _a.preprocessedImageUrl;
        return ({
            isFeaturedImage: featuredImageId === id,
            serverEdit: edit,
            order: order,
            id: id,
            originalImageUrl: originalImageUrl,
            preprocessedImageUrl: preprocessedImageUrl,
            url: url,
        });
    });
};
var getImageToDisplay = function (foundEdit, image) {
    return foundEdit.editedImage || extractFullSizeImage(image.url);
};
var getImageToEditWith = function (foundEdit, image) {
    var editedImage = foundEdit.editedImageIsFullResolution && foundEdit.editedImage;
    var lastEditHistory = last(foundEdit.editHistory);
    var fallbackImage = extractFullSizeImage(image.originalImageUrl);
    return editedImage && foundEdit.editedImageIsFullResolution && !lastEditHistory
        ? fallbackImage
        : editedImage || fallbackImage;
};
export var createStoreStilettoImageComponents = function (images, edits, selectedImages) {
    return images.map(function (image) {
        var foundEdit = edits[image.id] || defaultStoreStilettoEdit(image.id);
        return __assign(__assign(__assign({}, image), foundEdit), { imageToDisplay: getImageToDisplay(foundEdit, image), imageToEditWith: getImageToEditWith(foundEdit, image), isSelected: isInArray(selectedImages, image.id) });
    });
};
export var createUploadItemEdits = function (editItem) {
    var edits = createUploadEditsObject(last(editItem.editHistory));
    return edits;
};
export var createUploadItem = function (editItem) { return __awaiter(void 0, void 0, void 0, function () {
    var blob, edits;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dataURLToBlob(editItem.editedImage)];
            case 1:
                blob = _a.sent();
                edits = createUploadItemEdits(editItem);
                return [2 /*return*/, { image: blob, edits: edits }];
        }
    });
}); };
export var createUploadEditsObject = function (lastEditHistory) {
    var _a;
    var defaultParams = {
        crop: undefined,
        whiteBalance: undefined,
        gradient: undefined,
        eraser: false,
    };
    var gradient = (_a = lastEditHistory.editsPerformed.gradient) === null || _a === void 0 ? void 0 : _a.reduce(
    //eslint-disable-next-line
    function (acc, curr) {
        var _a;
        var key = curr.position;
        return __assign(__assign({}, acc), (_a = {}, _a[key] = curr.percent, _a.featherPercent = curr.featherPercent, _a.featherOpacity = curr.featherOpacity, _a));
    }, {});
    return __assign(__assign(__assign({}, defaultParams), lastEditHistory.editsPerformed), { gradient: !isEmpty(gradient) ? gradient : undefined });
};
export var getEditSaving = function (edits) {
    var foundItem = Object.values(edits).find(function (_a) {
        var loadingActions = _a.loadingActions;
        return !isEmpty(loadingActions);
    });
    return foundItem === null || foundItem === void 0 ? void 0 : foundItem.loadingActions[0];
};
export var toggleItemForceHelper = function (_a) {
    var item = _a.item, add = _a.add, array = _a.array, allowDuplicates = _a.allowDuplicates;
    var newItems = array;
    if (isBoolean(add)) {
        if (add === true && (allowDuplicates || !array.includes(item)))
            newItems = addItemToArray(array, item);
        if (add === false)
            newItems = removeItemFromArray(array, item);
    }
    else
        newItems = toggleItemInArray(array, item);
    return newItems;
};
export var createSmallDataURLForStore = function (fabricCanvas) {
    return canvasToDataURL(fabricCanvas, {
        width: 500,
        height: 500,
        format: 'png',
        quality: 1,
    });
};
export var createHighQualityDataURLForStore = function (fabricCanvas, img) { return __awaiter(void 0, void 0, void 0, function () {
    var offset, dimensions;
    return __generator(this, function (_a) {
        offset = 0.01;
        dimensions = Math.min(img.width, img.height) - offset;
        return [2 /*return*/, canvasToDataURL(fabricCanvas, {
                width: dimensions,
                height: dimensions,
                format: 'png',
                quality: 1,
            })];
    });
}); };
export var createDataURLInImageDimensions = function (canvas) {
    var img = canvas.backgroundImage;
    return canvasToDataURL(canvas, { format: 'png', width: img.width, height: img.height });
};
export var stilettoEditTypeMapToEditHistory = (_a = {},
    _a[StilettoEditTypes.editBrightness] = 'brightness',
    _a[StilettoEditTypes.editCrop] = 'crop',
    _a[StilettoEditTypes.editGradient] = 'gradient',
    _a[StilettoEditTypes.editGamma] = 'gamma',
    _a[StilettoEditTypes.editWhiteBalance] = 'whiteBalance',
    _a[StilettoEditTypes.editEraser] = 'eraser',
    _a[StilettoEditTypes.reset] = '',
    _a[StilettoEditTypes.undo] = '',
    _a[StilettoEditTypes.renderFullResoltion] = '',
    _a);
