import { RouteNames } from './routeNames';
export var mechanicalMatcherRoutes = [
    {
        path: "/".concat(RouteNames.MechanicalMatcher),
        name: RouteNames.MechanicalMatcher,
        component: function () { return import('../views/MechanicalMatcher.vue'); },
        meta: {
            requiresAuth: true,
            title: 'Mechanical Matcher',
        },
    },
];
export default mechanicalMatcherRoutes;
