export var ChartTypes;
(function (ChartTypes) {
    ChartTypes["Bar"] = "Bar";
    ChartTypes["Line"] = "Line";
    ChartTypes["Doughnut"] = "Doughnut";
    ChartTypes["Pie"] = "Pie";
    //   Radar = 'Radar',
    //   PolarArea = 'PolarArea',
    //   Bubble = 'Bubble',
    //   Scatter = 'Scatter',
})(ChartTypes || (ChartTypes = {}));
