var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Vue from 'vue';
import isEmpty from 'lodash/isEmpty';
import { createToastError } from '../helpers';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
Vue.use(Toast, {});
export var ToastVariants;
(function (ToastVariants) {
    ToastVariants["default"] = "default";
    ToastVariants["info"] = "info";
    ToastVariants["warning"] = "warning";
    ToastVariants["success"] = "success";
    ToastVariants["error"] = "error";
})(ToastVariants || (ToastVariants = {}));
export var $_toast_message = function (_a) {
    var _b = _a.text, text = _b === void 0 ? '' : _b, _c = _a.timeout, timeout = _c === void 0 ? 5000 : _c, _d = _a.variant, variant = _d === void 0 ? ToastVariants.default : _d, params = __rest(_a, ["text", "timeout", "variant"]);
    var options = __assign({ timeout: timeout, pauseOnHover: true, closeOnClick: false, showCloseButtonOnHover: true }, params);
    if (variant === ToastVariants.default) {
        return Vue.$toast(text, options);
    }
    Vue.$toast[variant](text, options);
};
export var $_toast_success = function (params) {
    $_toast_message(__assign(__assign({}, params), { variant: ToastVariants.success }));
};
export var $_toast_error = function (params, errors) {
    var transformedErrors = createToastError(errors === null || errors === void 0 ? void 0 : errors.messages);
    if (!transformedErrors || isEmpty(transformedErrors)) {
        transformedErrors.push(__assign(__assign({ text: 'An error has occured' }, params), { variant: ToastVariants.error }));
    }
    transformedErrors.forEach(function (error) {
        $_toast_message(__assign(__assign({}, error), { variant: ToastVariants.error }));
    });
};
export default {
    install: function () {
        Vue.mixin({
            methods: {
                $_toast_message: function (_a) {
                    var params = __rest(_a, []);
                    $_toast_message(params);
                },
                $_toast_warning: function (params) {
                    this.$_toast_message(__assign(__assign({}, params), { variant: ToastVariants.warning }));
                },
                $_toast_error: function (params, errors) {
                    $_toast_error(params, errors);
                },
                $_toast_success: function (params) {
                    $_toast_success(params);
                },
            },
        });
    },
};
