import Vue from 'vue';
import App from './App.vue';
import router from './router';
//components
import PortalVue from 'portal-vue';
import vSelect from 'vue-select';
import Echo from 'laravel-echo';
//store and helpers
import store from './store';
import helpers from '../portal/helper.js';
//plugins
import { ToastsPlugin, FontawesomePlugin } from '../vue2-common/plugins';
import './plugins/bootstrap-vue';
import vueScrollto from 'vue-scrollto';
import VueCookies from 'vue-cookies';
//styles
import './styles/main.scss';
var plugin = {
    install: function () {
        //@ts-ignore
        Vue.helpers = helpers;
        Vue.prototype.$helpers = helpers;
    },
};
//directives
import ignoreOverflow from './directives/ignoreOverflow';
import clickOutside from './directives/clickOutside';
Vue.directive('click-outside', clickOutside);
Vue.directive('ignore-overflow', ignoreOverflow);
Vue.use(VueCookies);
Vue.use(vueScrollto);
Vue.use(plugin);
Vue.use(PortalVue);
Vue.use(ToastsPlugin);
Vue.use(FontawesomePlugin);
Vue.component('v-select', vSelect);
window.Pusher = require('pusher-js');
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    forceTLS: true,
});
export var bus = new Vue();
new Vue({
    render: function (h) { return h(App); },
    router: router,
    store: store,
}).$mount('#app');
