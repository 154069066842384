var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import localApi from '../localApi';
import * as routes from './routes';
export var loginWithFashionphile = function (loginData) {
    return localApi.post(routes.LOGIN, __assign({}, loginData)).then(function (_a) {
        var data = _a.data;
        return data;
    });
};
export var loginWithWebApp = function (loginData) {
    return localApi.post(routes.WEBAPP_LOGIN, __assign({}, loginData)).then(function (_a) {
        var data = _a.data;
        return data;
    });
};
