var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component } from 'vue-property-decorator';
import moment from 'moment';
var BuildMixin = /** @class */ (function (_super) {
    __extends(BuildMixin, _super);
    function BuildMixin() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BuildMixin.prototype.mounted = function () {
        var styles = [
            'color: green',
            'background: yellow',
            'font-size: 16px',
            'border: 1px solid red',
            'text-shadow: 1px 1px 1px black',
            'padding: 5px',
        ].join(';');
        var date = new Date("".concat(process.env.VUE_APP_BUILD_DATE));
        var momentDate = moment(date).format('lll');
        if (process.env.NODE_ENV !== 'development') {
            console.log("%cApp Build: ".concat(momentDate), styles);
        }
    };
    BuildMixin = __decorate([
        Component
    ], BuildMixin);
    return BuildMixin;
}(Vue));
export default BuildMixin;
