var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import localApi from '../localApi';
import * as routes from './routes';
export var getOriginalImageMatch = function (params) {
    return localApi.get(routes.MECHANICAL_MATCHES, { params: params }).then(function (_a) {
        var data = _a.data.data;
        return data;
    });
};
export var skipMatch = function (itemId) {
    return localApi.get(routes.SKIP_MATCH(itemId)).then(function (_a) {
        var data = _a.data;
        return data;
    });
};
export var updateMatch = function (_a) {
    var item_id = _a.item_id, params = __rest(_a, ["item_id"]);
    return localApi.post(routes.UPDATE_MATCH(item_id), __assign({}, params)).then(function (_a) {
        var data = _a.data;
        return data;
    });
};
export var getMatchImages = function (itemId) {
    return localApi.get(routes.MATCH_IMAGES(itemId)).then(function (_a) {
        var data = _a.data.data;
        return data;
    });
};
export var createNewPrediction = function (params) {
    return localApi.post(routes.NEW_PREDICTION, __assign({}, params)).then(function (_a) {
        var data = _a.data.data;
        return data;
    });
};
export var getProductByStyleId = function (_a) {
    var styleId = _a.styleId, color = _a.color;
    return localApi.get(routes.GET_PRODUCT_BY_STYLEID(styleId), { params: { color: color } }).then(function (_a) {
        var data = _a.data.data;
        return data;
    });
};
export var getPotentialMatches = function (params) {
    return localApi.post(routes.IDENTIFY_IMAGE, __assign({}, params)).then(function (_a) {
        var data = _a.data;
        return data || [];
    });
};
