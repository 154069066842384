import { ChartTypes } from 'vue2/components';
import { formatCurrency } from 'vue2/helpers';
export var createBirkinChartData = function (_a) {
    var birkinChart = _a.birkinChart;
    var labels = birkinChart.map(function (_a) {
        var year = _a.year;
        return year.toString();
    });
    var datasets = [
        {
            label: '% Growth',
            data: birkinChart.map(function (_a) {
                var averageSale = _a.averageSale;
                return averageSale;
            }),
            backgroundColor: '#f7e6ef',
            borderColor: '#de5ba3',
            pointBackgroundColor: '#de5ba3',
            fill: true,
            lineTension: 0.65,
            pointRadius: 3.5,
        },
    ];
    var chartOptions = {
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                ticks: {
                    callback: function (value) { return formatCurrency(Number(value)); },
                },
            },
        },
    };
    return {
        type: ChartTypes.Line,
        chartData: {
            labels: labels,
            datasets: datasets,
        },
        chartOptions: chartOptions,
    };
};
