export var NewQuoteKeys;
(function (NewQuoteKeys) {
    NewQuoteKeys["startNewQuote"] = "start-new-quote";
    NewQuoteKeys["hasChosenBrand"] = "has-chosen-category";
    NewQuoteKeys["selectProductName"] = "select-product-name";
    NewQuoteKeys["hasChosenName"] = "has-chosen-name";
    NewQuoteKeys["selectColor"] = "select-color";
    NewQuoteKeys["hasChosenColor"] = "has-chosen-color";
    NewQuoteKeys["selectImageToUpload"] = "select-image-to-upload";
    NewQuoteKeys["selectParentStyle"] = "select-image-match";
    NewQuoteKeys["hasChosenParentStyle"] = "has-chosen-parent-style";
    NewQuoteKeys["hasChosenSubStyle"] = "has-chosen-sub-style";
    NewQuoteKeys["getProductPricePrediction"] = "get-product-prediction";
    NewQuoteKeys["displayProductPricePrediction"] = "display-product-prediction";
    NewQuoteKeys["selectImageSubStyle"] = "select-image-sub-style";
})(NewQuoteKeys || (NewQuoteKeys = {}));
