var BASE_ROUTE = "/api/mechanical-matcher";
//mechancial matcher
export var MECHANICAL_MATCHES = "".concat(BASE_ROUTE);
export var SKIP_MATCH = function (pendingItemId) { return "".concat(BASE_ROUTE, "/skip/").concat(pendingItemId); };
export var UPDATE_MATCH = function (pendingItemId) { return "".concat(BASE_ROUTE, "/update/").concat(pendingItemId); };
export var MATCH_IMAGES = function (matchImageId) { return "".concat(BASE_ROUTE, "/images/").concat(matchImageId); };
export var IDENTIFY_IMAGE = "".concat(BASE_ROUTE, "/identify");
//other (TODO consider moving these to seperate folders if there is logical grouping)
export var GET_PRODUCT_BY_STYLEID = function (styleId) { return "/api/styles/products/".concat(styleId); };
export var NEW_PREDICTION = "/api/predict/identification";
