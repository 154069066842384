import hexColorOpacity from "hex-color-opacity";
export var applyOpacityToColor = function (hexCode, opacity) {
    return hexColorOpacity(hexCode, opacity);
};
export var extractOpacityFromColor = function (hexCode) {
    var normalize = function (val, max, min) {
        return (val - min) / (max - min);
    };
    var hexToAlpha = function (alphaHexString) {
        return Math.round(normalize(parseInt(alphaHexString, 16), 255, 0) * 100);
    };
    var value = hexCode.substring(7, 9);
    if (!value || value.length < 2)
        return 100;
    return hexToAlpha(value);
};
