var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import isEmpty from 'lodash/isEmpty';
import { storeModules } from 'main/store';
import { createAssistantMessage, createLoadingMessage, resetMessageIds, createInputSearchSelect, createChoiceMessage, createInputSelect, createInputUpload, } from '../../createMessageHelpers';
import { getCategories, getPredictionIdentification, getPricePrediction, getStyleById, searchStyle, } from 'main/api';
import { createColorSelect, formatParentMatches, formatSubStyleMatches, needsToUploadImage, saveCategoriesToStore, saveSelectProductToStore, searchCategoriesFromStore, searchProductNames, transformImagePredictUploadRequest, } from './transformers';
import { NewQuoteKeys } from './config';
import { initalNewQuoteMessages } from './initialMessages';
import { initialShowroomDropoffMessages } from '../dropoff';
export var newQuoteMessage_selectBrand = function () {
    var selectCategoriesMessages = [
        createAssistantMessage({
            text: 'What is the brand of your product?',
        }),
        createInputSearchSelect({
            apiRequest: {
                apiUrl: function (data) { return searchCategoriesFromStore(data); },
                requestTransformer: function (text) { return ({ text: text }); },
            },
            allowNonSelectOptions: true,
            placeholder: 'Louis Vuitton',
            overrideNextId: NewQuoteKeys.hasChosenBrand,
        }),
    ];
    var selectUploadImage = newQuoteMessage_selectImageToUpload();
    var messages = [
        createAssistantMessage({
            text: "I can help you leave new items here for us to process later.  You most likely will be eligible for payment within 24 hours.",
        }),
        createAssistantMessage({
            text: "I need to ask a few questions to get started.",
        }),
    ];
    messages = __spreadArray(__spreadArray(__spreadArray(__spreadArray([], messages, true), [
        createLoadingMessage({
            apiRequest: {
                apiUrl: function () { return getCategories(); },
                responseTransformer: function (data) { return saveCategoriesToStore(data); },
            },
        }),
        createAssistantMessage({ text: 'Do you know the brand of your product?' }),
        createChoiceMessage({
            choices: [
                { text: "No", nextMessageId: selectUploadImage[0].id },
                { text: "Yes, I know the brand", nextMessageId: selectCategoriesMessages[0].id },
            ],
        })
    ], false), selectCategoriesMessages, true), selectUploadImage, true);
    resetMessageIds();
    return messages;
};
export var newQuoteMessage_chosenBrand = function (data) {
    var chosenbrandId = data && data.selectedValue.code;
    var messages = [];
    if (chosenbrandId) {
        storeModules.showroomAssistant.UPDATE_USER_NEW_QUOTE_DATA({ brandId: String(chosenbrandId) });
        messages = __spreadArray([], messages, true);
    }
    else {
        messages = __spreadArray(__spreadArray([], messages, true), [
            createAssistantMessage({ text: 'No known brand selected' }),
            createAssistantMessage({ text: 'No problem!' }),
        ], false);
    }
    messages = __spreadArray(__spreadArray([], messages, true), [createLoadingMessage({ overrideNextId: NewQuoteKeys.selectProductName })], false);
    resetMessageIds();
    return __spreadArray([], messages, true);
};
export var newQuoteMessage_selectName = function () {
    var selectedbrandId = storeModules.showroomAssistant.userNewQuoteData.brandId;
    var selectProductNameMessages = [
        createAssistantMessage({ text: 'What is the name of your product?' }),
        createInputSearchSelect({
            apiRequest: {
                apiUrl: function (data) { return searchStyle(data); },
                requestTransformer: function (term) { return ({ term: term, brandId: Number(selectedbrandId) }); },
                responseTransformer: function (data) { return searchProductNames(data); },
            },
            allowNonSelectOptions: true,
            overrideNextId: NewQuoteKeys.hasChosenName,
            minWidthImage: 175,
            fallbackButtonText: 'Product Name Not Found',
            forceShowFallback: true,
        }),
    ];
    var selectUploadImage = newQuoteMessage_selectImageToUpload();
    var messages = [];
    if (selectedbrandId) {
        messages = __spreadArray(__spreadArray(__spreadArray(__spreadArray([], messages, true), [
            createAssistantMessage({ text: 'Do you know the name of your product?' }),
            createChoiceMessage({
                choices: [
                    { text: "No", nextMessageId: selectUploadImage[0].id },
                    { text: "Yes, I know the name", nextMessageId: selectProductNameMessages[0].id },
                ],
            })
        ], false), selectProductNameMessages, true), selectUploadImage, true);
    }
    else {
        messages = __spreadArray(__spreadArray([], messages, true), [createLoadingMessage({ overrideNextId: NewQuoteKeys.hasChosenName })], false);
    }
    resetMessageIds();
    return __spreadArray([], messages, true);
};
export var getSelectedProduct = function (overrideNextId) {
    var _a;
    if (overrideNextId === void 0) { overrideNextId = NewQuoteKeys.selectColor; }
    var selectedProductId = (_a = storeModules.showroomAssistant.userNewQuoteData.productData) === null || _a === void 0 ? void 0 : _a.styleId;
    var messages = [
        createLoadingMessage({
            apiRequest: {
                apiUrl: function () { return getStyleById(selectedProductId); },
                responseTransformer: function (_a) {
                    var data = _a.data;
                    return saveSelectProductToStore(data);
                },
            },
            overrideNextId: overrideNextId,
        }),
    ];
    resetMessageIds();
    return messages;
};
export var newQuoteMessage_chosenName = function (data) {
    var messages = [];
    var chosenName = data === null || data === void 0 ? void 0 : data.selectedValue;
    if (data) {
        storeModules.showroomAssistant.UPDATE_USER_NEW_QUOTE_DATA({
            productData: {
                name: chosenName === null || chosenName === void 0 ? void 0 : chosenName.label,
                styleId: chosenName === null || chosenName === void 0 ? void 0 : chosenName.code,
            },
        });
        messages = __spreadArray(__spreadArray([], messages, true), getSelectedProduct(), true);
    }
    else {
        messages = __spreadArray(__spreadArray([], messages, true), [createLoadingMessage({ overrideNextId: NewQuoteKeys.selectImageToUpload })], false);
    }
    messages = __spreadArray([], messages, true);
    resetMessageIds();
    return messages;
};
export var newQuoteMessage_selectColor = function () {
    var _a, _b;
    var selectedbrandId = storeModules.showroomAssistant.userNewQuoteData.brandId;
    var selectedProductId = (_a = storeModules.showroomAssistant.userNewQuoteData.productData) === null || _a === void 0 ? void 0 : _a.styleId;
    var messages = [];
    if (selectedbrandId && selectedProductId && !isEmpty((_b = storeModules.showroomAssistant.selectedStyle) === null || _b === void 0 ? void 0 : _b.colors)) {
        messages = __spreadArray(__spreadArray([], messages, true), [
            createAssistantMessage({ text: 'What is the color of your bag?' }),
            createInputSelect({
                selectData: createColorSelect(storeModules.showroomAssistant.selectedStyle),
                overrideNextId: NewQuoteKeys.hasChosenColor,
                noSelectText: 'Click to select color',
                minWidthImage: 175,
            }),
        ], false);
    }
    else {
        messages = __spreadArray(__spreadArray([], messages, true), [createLoadingMessage({ overrideNextId: NewQuoteKeys.hasChosenColor })], false);
    }
    resetMessageIds();
    return __spreadArray([], messages, true);
};
export var newQuoteMessage_chosenColor = function (data) {
    var messages = [];
    if (data) {
        storeModules.showroomAssistant.UPDATE_USER_NEW_QUOTE_DATA({
            colorId: Number(data.selectedValue.code),
        });
        messages = __spreadArray([], messages, true);
    }
    messages = __spreadArray(__spreadArray([], messages, true), [createLoadingMessage({ overrideNextId: NewQuoteKeys.selectImageToUpload })], false);
    resetMessageIds();
    return messages;
};
export var newQuoteMessage_selectImageToUpload = function () {
    var _a;
    var messages = [];
    if (needsToUploadImage(storeModules.showroomAssistant.userNewQuoteData)) {
        var brandId_1 = storeModules.showroomAssistant.userNewQuoteData.brandId;
        var styleId_1 = (_a = storeModules.showroomAssistant.userNewQuoteData.productData) === null || _a === void 0 ? void 0 : _a.styleId;
        messages = __spreadArray(__spreadArray([], messages, true), [
            createAssistantMessage({
                text: 'Alright, now I need picture from you.  Could you please upload a single straight on image of your bag?',
            }),
            createInputUpload({
                type: 'image',
                apiRequest: {
                    apiUrl: function (data) { return getPredictionIdentification(data); },
                    requestTransformer: function (data) { return transformImagePredictUploadRequest(data, { brandId: brandId_1, styleId: styleId_1 }); },
                },
                overrideNextId: NewQuoteKeys.selectParentStyle,
            }),
        ], false);
    }
    else {
        messages = __spreadArray(__spreadArray([], messages, true), [
            createAssistantMessage({
                text: "Awesome! Let's get you your quote.",
                overrideNextId: NewQuoteKeys.getProductPricePrediction,
            }),
        ], false);
    }
    resetMessageIds();
    return messages;
};
export var newQuoteMessage_selectParentStyle = function (_a) {
    var uploadResponse = _a.uploadResponse;
    var messages = [];
    if (uploadResponse.matches && !isEmpty(uploadResponse.matches)) {
        messages = __spreadArray(__spreadArray([], messages, true), [
            createAssistantMessage({ text: 'Please select which product matches your own' }),
            createInputSelect({
                selectData: formatParentMatches(uploadResponse.matches),
                overrideNextId: NewQuoteKeys.hasChosenParentStyle,
                noSelectText: 'Click to product',
                minWidthImage: 175,
                fallbackButtonText: 'Product Not Found',
            }),
        ], false);
    }
    else {
        messages = __spreadArray(__spreadArray([], messages, true), [
            createAssistantMessage({ text: 'Looks like we did not find any matches on our end' }),
            createAssistantMessage({ text: 'TODO. Add some content here' }),
        ], false);
    }
    resetMessageIds();
    return __spreadArray([], messages, true);
};
export var newQuoteMessage_chosenParentStyle = function (_a) {
    var _b;
    var selectedValue = _a.selectedValue;
    var messages = [];
    if (selectedValue) {
        var brandId = (_b = selectedValue.products) === null || _b === void 0 ? void 0 : _b.data[0].brandId.toString();
        var productData = { name: selectedValue.parentName, styleId: Number(selectedValue.styleId) };
        var colorId = undefined;
        storeModules.showroomAssistant.UPDATE_USER_NEW_QUOTE_DATA({ brandId: brandId, productData: productData, colorId: colorId });
    }
    if (selectedValue === null || selectedValue === void 0 ? void 0 : selectedValue.subStyles) {
        messages = __spreadArray(__spreadArray([], messages, true), newQuoteMessage_selectImageSubStyle(selectedValue), true);
    }
    else if (!selectedValue) {
        messages = __spreadArray(__spreadArray([], messages, true), [
            createAssistantMessage({ text: 'No style selected.' }),
            createAssistantMessage({ text: 'TODO add content here' }),
        ], false);
    }
    else {
        messages = __spreadArray(__spreadArray([], messages, true), getSelectedProduct(), true);
    }
    resetMessageIds();
    return messages;
};
export var newQuoteMessage_selectImageSubStyle = function (selectedValue) {
    var messages = [
        createAssistantMessage({
            text: "It looks like that product has a few \"sub-styles\". Can you select which sub-style you think your bag is?",
        }),
        createInputSelect({
            selectData: formatSubStyleMatches(selectedValue),
            overrideNextId: NewQuoteKeys.hasChosenSubStyle,
            noSelectText: 'Click to sub style',
            minWidthImage: 175,
            fallbackButtonText: 'Sub Style Not Found',
        }),
    ];
    resetMessageIds();
    return messages;
};
export var newQuoteMessage_chosenSubStyle = function (_a) {
    var _b;
    var selectedValue = _a.selectedValue;
    var messages = [];
    if (selectedValue) {
        var brandId = (_b = selectedValue.products) === null || _b === void 0 ? void 0 : _b.data[0].brandId.toString();
        var productData = { name: selectedValue.parentName, styleId: Number(selectedValue.styleId) };
        var colorId = undefined;
        storeModules.showroomAssistant.UPDATE_USER_NEW_QUOTE_DATA({ brandId: brandId, productData: productData, colorId: colorId });
        messages = __spreadArray(__spreadArray(__spreadArray([], messages, true), [createAssistantMessage({ text: 'Thanks!' })], false), getSelectedProduct(), true);
    }
    else {
        messages = __spreadArray(__spreadArray([], messages, true), [
            createAssistantMessage({ text: 'No sub style selected' }),
            createAssistantMessage({ text: 'TODO add content here' }),
        ], false);
    }
    resetMessageIds();
    return messages;
};
export var newQuoteMessage_getPredicition = function () {
    var _a;
    var styleId = (_a = storeModules.showroomAssistant.userNewQuoteData.productData) === null || _a === void 0 ? void 0 : _a.styleId;
    var colorId = storeModules.showroomAssistant.userNewQuoteData.colorId || undefined;
    var messages = [
        createAssistantMessage({ text: 'Please wait a second while we evaluate your product' }),
        createLoadingMessage({
            apiRequest: {
                apiUrl: function (data) { return getPricePrediction(data); },
                requestTransformer: function () { return ({ styleId: styleId, colorId: colorId }); },
                responseTransformer: function (data) { return data; },
            },
            overrideNextId: NewQuoteKeys.displayProductPricePrediction,
        }),
    ];
    resetMessageIds();
    return messages;
};
export var newQuoteMessage_displayPrediction = function () {
    var messages = [
        createAssistantMessage({ text: 'Here is your estimated payout' }),
        createAssistantMessage({ text: 'TODO - display predicition' }),
    ];
    resetMessageIds();
    return messages;
};
export var submitNewQuote = function () {
    var initialNewQuoteMessages = initalNewQuoteMessages();
    var noNewQuoteMessage = __spreadArray([
        createAssistantMessage({ text: "Great! Let's start dropping off your items!" })
    ], initialShowroomDropoffMessages(), true);
    var messages = __spreadArray(__spreadArray([
        createAssistantMessage({ text: 'Thanks for submitting your order!' }),
        createAssistantMessage({ text: 'Do you have any more items to quote today?' }),
        createChoiceMessage({
            choices: [
                { text: "No", nextMessageId: noNewQuoteMessage[0].id },
                { text: "Yes, I have more items", nextMessageId: initialNewQuoteMessages[0].id },
            ],
        })
    ], initialNewQuoteMessages, true), noNewQuoteMessage, true);
    resetMessageIds();
    return __spreadArray([], messages, true);
};
