var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { searchAddress, AcceptOfferType } from 'main/api';
import { formatCurrency, replaceItemInArray } from 'vue2/helpers';
import { storeModules } from 'main/store';
import { DropoffKeys } from './config';
import { createSelectDataDropoffItems, getAllSelectedInvalidOffers, searchAddresRequestTransformer, } from '../apiHelpers';
import { createInputPhoneMessage, createChoiceMessage, createAssistantMessage, createInputSelect, createInputSearchSelect, createInputSignature, resetMessageIds, } from '../../createMessageHelpers';
import { createAddressString, extractPhoneFromSellerAddress, formatAddressStringForStore, hasAddressOnFile, hasPaymentOnFile, hasPhoneOnFile, } from '../../../address';
import { message_confirmOrder } from './dropoffMessages';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
export var message_chooseAcceptedOfferTypes = function (overrideNextId) {
    var messages = [];
    var invalidItems = getAllSelectedInvalidOffers(storeModules.showroomAssistant.userDropoffData.dropoffItems);
    invalidItems.forEach(function (quote) {
        var isLast = isEqual(quote, last(invalidItems));
        var idToUse = isLast ? overrideNextId : undefined;
        var selectOption = function (acceptedOfferType) {
            var newItem = __assign(__assign({}, quote), { acceptedOfferType: acceptedOfferType });
            var newArray = replaceItemInArray(storeModules.main.existingQuotes, quote, newItem);
            storeModules.main.REPLACE_QUOTES(newArray);
            storeModules.showroomAssistant.UPDATE_USER_DROPOFF_DATA({
                acceptedOfferType: __spreadArray(__spreadArray([], (storeModules.showroomAssistant.userDropoffData.acceptedOfferType || []), true), [
                    { id: quote.id, acceptedOfferType: acceptedOfferType },
                ], false),
            });
        };
        var infoText = createAssistantMessage({
            text: "It looks like you have multiple offers on your item \"".concat(quote.brand, " - ").concat(quote.name, "\". Please select which offer you would prefer."),
        });
        var newChoiceMessage = createChoiceMessage({
            choices: [
                {
                    text: "Buyout Offer: ".concat(formatCurrency(Number(quote.buyoutQuote))),
                    middlewareFunction: function () { return selectOption(AcceptOfferType.buyout); },
                    nextMessageId: idToUse,
                },
                {
                    text: "Consignment Estimate: ".concat(formatCurrency(Number(quote.consignmentEstimate))),
                    middlewareFunction: function () { return selectOption(AcceptOfferType.consignment); },
                    nextMessageId: idToUse,
                },
            ],
        });
        messages = __spreadArray(__spreadArray([], messages, true), [infoText, newChoiceMessage], false);
    });
    resetMessageIds();
    return messages;
};
export var message_chooseItems = function () {
    var messages = [
        createAssistantMessage({
            overrideCurrentId: DropoffKeys.confirmAcceptedOffType,
            text: "Please select which items you are dropping off with us today. Once completed, we\u2019ll give you a QR code to show to the front desk to confirm the dropoff.",
        }),
        createInputSelect({
            selectData: createSelectDataDropoffItems(storeModules.main.existingQuotes),
            multiple: true,
            overrideNextId: DropoffKeys.confirmNumberOfDropoffItems,
            noSelectText: 'Click to select items',
            removeButton: true,
        }),
    ];
    resetMessageIds();
    return messages;
};
export var message_chooseAddress = function () {
    var _a, _b, _c;
    var messages = [];
    var doesHaveAddress = hasAddressOnFile((_a = storeModules.user.userData) === null || _a === void 0 ? void 0 : _a.sellerAddress);
    var getAddressMessages = [
        createAssistantMessage({ text: "What is your current address?" }),
        createInputSearchSelect({
            apiRequest: {
                apiUrl: function (data) { return searchAddress(data); },
                requestTransformer: function (text) { return ({ search: text }); },
                responseTransformer: function (data) { return searchAddresRequestTransformer(data); },
            },
            allowNonSelectOptions: false,
            overrideNextId: DropoffKeys.confirmAddress,
            fallbackOptions: [
                { key: 'street_line', placeholder: 'Address Line 1' },
                { key: 'secondary', placeholder: 'Address Line 2' },
                { key: 'city', placeholder: 'City' },
                { key: 'state', placeholder: 'State' },
                { key: 'zipcode', placeholder: 'Zip Code' },
            ],
            fallbackButtonText: 'Manually Enter Address',
        }),
    ];
    if (doesHaveAddress) {
        var addressData = formatAddressStringForStore((_b = storeModules.user.userData) === null || _b === void 0 ? void 0 : _b.sellerAddress);
        var addressString = createAddressString((_c = storeModules.user.userData) === null || _c === void 0 ? void 0 : _c.sellerAddress);
        storeModules.showroomAssistant.UPDATE_USER_DROPOFF_DATA({ addressData: addressData });
        var paymentMessages = message_choosePayment();
        messages = __spreadArray([
            createAssistantMessage({
                text: "The address we have on file is ".concat(addressString, ". Is this address correct?"),
            }),
            createChoiceMessage({
                choices: [
                    { text: "No, let's change it", nextMessageId: getAddressMessages[0].id },
                    { text: "Yes", nextMessageId: paymentMessages[0].id },
                ],
            })
        ], paymentMessages, true);
    }
    messages = __spreadArray(__spreadArray([], messages, true), getAddressMessages, true);
    resetMessageIds();
    return messages;
};
export var message_choosePayment = function () {
    var _a, _b;
    var messages = [];
    var doesHavePaymentMethod = hasPaymentOnFile(storeModules.user.userData);
    var selectData = storeModules.main.paymentMethods
        .filter(function (method) { return method.showAsOption; })
        .map(function (method) { return ({
        label: method.name,
        code: method.value,
    }); });
    var paymentOnFile = selectData.find(function (_a) {
        var _b, _c;
        var code = _a.code;
        return code === ((_c = (_b = storeModules.user.userResponse) === null || _b === void 0 ? void 0 : _b.data.sellerPayment) === null || _c === void 0 ? void 0 : _c.methodCode);
    });
    var selectPaymentMessages = [
        createAssistantMessage({ text: 'What payment method would you like to use?' }),
        createInputSelect({
            selectData: selectData,
            overrideNextId: DropoffKeys.paymentMethodInput,
            preSelectedData: paymentOnFile || selectData[0],
        }),
    ];
    if (doesHavePaymentMethod) {
        storeModules.showroomAssistant.UPDATE_USER_DROPOFF_DATA({
            paymentMethod: { paymentMethod: (_b = (_a = storeModules.user.userData) === null || _a === void 0 ? void 0 : _a.sellerPayment) === null || _b === void 0 ? void 0 : _b.methodCode },
        });
        var confirmPhoneMessages = message_choosePhone();
        messages = __spreadArray([
            createAssistantMessage({
                text: "Looks like your payment method is ".concat(paymentOnFile.label, ". Would you like to use this as your payment method?"),
            }),
            createChoiceMessage({
                choices: [
                    { text: "No, let's change it", nextMessageId: selectPaymentMessages[0].id },
                    { text: "Yes", nextMessageId: confirmPhoneMessages[0].id },
                ],
            })
        ], confirmPhoneMessages, true);
    }
    messages = __spreadArray(__spreadArray([], messages, true), selectPaymentMessages, true);
    resetMessageIds();
    return messages;
};
export var message_choosePhone = function () {
    var _a, _b, _c;
    var messages = [];
    var doesHavePhone = hasPhoneOnFile((_a = storeModules.user.userData) === null || _a === void 0 ? void 0 : _a.sellerAddress);
    var getPhoneMessages = [
        createAssistantMessage({ text: "What is your current phone number?" }),
        createInputPhoneMessage({ overrideNextId: DropoffKeys.confirmPhone }),
    ];
    if (doesHavePhone) {
        storeModules.showroomAssistant.UPDATE_USER_DROPOFF_DATA({
            phone: {
                phoneNumber: extractPhoneFromSellerAddress((_b = storeModules.user.userData) === null || _b === void 0 ? void 0 : _b.sellerAddress),
            },
        });
        var confirmOrderMessages = message_confirmOrder();
        messages = __spreadArray([
            createAssistantMessage({
                text: "The current phone you have on file is ".concat(extractPhoneFromSellerAddress((_c = storeModules.user.userData) === null || _c === void 0 ? void 0 : _c.sellerAddress), ". Do you want to use that number?"),
            }),
            createChoiceMessage({
                choices: [
                    { text: "No, let's change it", nextMessageId: getPhoneMessages[0].id },
                    { text: "Yes", nextMessageId: confirmOrderMessages[0].id },
                ],
            })
        ], confirmOrderMessages, true);
    }
    messages = __spreadArray(__spreadArray([], messages, true), getPhoneMessages, true);
    resetMessageIds();
    return messages;
};
export var message_signToDropoff = function () {
    var messages = [
        createAssistantMessage({
            text: "We're almost finished. Please sign below after reading our $[0]",
            inTextLinks: [['Terms & Conditions', 'https://help.fashionphile.com/s/seller-terms-and-conditions']],
        }),
        createInputSignature({ label: 'Please sign below', overrideNextId: DropoffKeys.confirmSignature }),
    ];
    resetMessageIds();
    return messages;
};
