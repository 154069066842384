var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import localApi from '../localApi';
import webAppInstance from '../webAppApi';
import * as routes from './routes';
export var getTourVariableData = function () {
    return localApi.get(routes.GET_TOUR_DATA).then(function (_a) {
        var data = _a.data;
        return data;
    });
};
export var getPendingActiveItems = function (params) {
    return localApi.get(routes.GET_CUSTOMER_ITEMS, { params: params }).then(function (_a) {
        var data = _a.data;
        return data;
    });
};
export var requestPickupItems = function (params) {
    return localApi.post(routes.REQUEST_PICKUP_ITEMS, __assign({}, params)).then(function (_a) {
        var data = _a.data;
        return data;
    });
};
export var getWarehouse = function (warehouseId) {
    return localApi.get(routes.GET_WAREHOUSES(warehouseId)).then(function (_a) {
        var data = _a.data.data;
        return data;
    });
};
export var generateQrCode = function (params) {
    return localApi.post(routes.SHOWROOM_ASSISTANT_GENERATE_QR, __assign({}, params)).then(function (_a) {
        var data = _a.data;
        return data;
    });
};
export var searchAddress = function (_a) {
    var search = _a.search;
    return localApi.get(routes.ADDRESS_SEARCH, { params: { search: search } }).then(function (_a) {
        var suggestions = _a.data.suggestions;
        return suggestions;
    });
};
export var searchStyle = function (params) {
    return localApi.get(routes.STYLES_SEARCH, { params: params }).then(function (_a) {
        var data = _a.data.data;
        return data;
    });
};
export var getCategories = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.limit, limit = _c === void 0 ? 100 : _c, _d = _b.enabledForQuotes, enabledForQuotes = _d === void 0 ? 'enabledForQuotes' : _d;
    return webAppInstance.get(routes.GET_CATEGORIES, { params: { limit: limit, enabledForQuotes: enabledForQuotes } }).then(function (_a) {
        var data = _a.data;
        return data;
    });
};
export var getStyleById = function (styleId) {
    return localApi.get(routes.STYLES_GET_STYLE(styleId)).then(function (_a) {
        var data = _a.data;
        return data;
    });
};
export var getPredictionIdentification = function (params) {
    var formData = new FormData();
    formData.append('image', params.image);
    params.brandId && formData.append('brandId', String(params.brandId));
    params.styleId && formData.append('styleId', String(params.styleId));
    params.colorId && formData.append('colorId', String(params.colorId));
    return localApi.post(routes.PREDICT_IDENTIFICATION, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
export var getPricePrediction = function (_a) {
    var styleId = _a.styleId, colorId = _a.colorId;
    return localApi.get(routes.PREDICT_PRICE, { params: { styleId: styleId, colorId: colorId } });
};
//*********************** NOT DONE
export var uploadFileToVisionPrediction = function (_a) {
    var image = _a.image, brandId = _a.brandId;
    var formData = new FormData();
    formData.append('image', String(image));
    formData.append('brandId', String(brandId));
    return localApi.post(routes.UPLOAD_VISION_PREDICT, formData).then(function (_a) {
        var data = _a.data;
        return data;
    });
};
export var receiveQuotes = function (pendingItemIds) {
    return localApi.get(routes.RECEIVE_QUOTES(pendingItemIds)).then(function (_a) {
        var data = _a.data;
        return data;
    });
};
export var targetPricing = function (params) {
    return localApi.get(routes.PRICING_TARGET_PRICING, { params: params }).then(function (_a) {
        var data = _a.data;
        return data;
    });
};
export var changePendingItems = function (params) {
    return localApi.put(routes.CHANGE_PENDING_ITMES(params.id), { params: params }).then(function (_a) {
        var data = _a.data;
        return data;
    });
};
