var _a;
import { messages_loadedPendingActiveItems, message_confirmOrder, message_confirmSignature, message_dropoffItems, message_hasGeneratedQRCode, message_paymethodMethodInput, message_chooseItems, message_confirm_Address, message_confirm_PaymentMethod, message_confirm_Phone, message_checkPickUpItems, } from './index';
import { DropoffKeys } from './config';
//eslint-disable-next-line
export var dropoffMessagesByKey = (_a = {},
    _a[DropoffKeys.loadedPendingActiveItems] = messages_loadedPendingActiveItems,
    _a[DropoffKeys.confirmAcceptedOffType] = message_chooseItems,
    _a[DropoffKeys.paymentMethodInput] = message_paymethodMethodInput,
    _a[DropoffKeys.hasGeneratedQRCode] = message_hasGeneratedQRCode,
    _a[DropoffKeys.confirmNumberOfDropoffItems] = message_dropoffItems,
    _a[DropoffKeys.confirmAddress] = message_confirm_Address,
    _a[DropoffKeys.confirmPaymentMethodChange] = message_confirm_PaymentMethod,
    _a[DropoffKeys.confirmSignature] = message_confirmSignature,
    _a[DropoffKeys.confirmPhone] = message_confirm_Phone,
    _a[DropoffKeys.confirmOrder] = message_confirmOrder,
    _a[DropoffKeys.checkForPickUpItems] = message_checkPickUpItems,
    _a);
export default dropoffMessagesByKey;
