var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { MessageAnimationTypes, storeModules } from 'main/store';
import { createAssistantMessage, createLoadingMessage, createImageMessage, createChoiceMessage, resetMessageIds, } from '../..';
import isEmpty from 'lodash/isEmpty';
import { requestPickupItems } from 'main/api';
import { NewPickupKeys } from './config';
import { initialShowroomDropoffMessages } from '../dropoff';
export var messages_showAvailablePickup = function () {
    var messages = [];
    if (isEmpty(storeModules.main.pickUpItems)) {
        messages = __spreadArray(__spreadArray([], messages, true), [
            createAssistantMessage({ text: 'You do not have any items available for pickup at this location' }),
        ], false);
    }
    else {
        messages = __spreadArray(__spreadArray(__spreadArray(__spreadArray([], messages, true), [
            createAssistantMessage({ text: 'These are the items that are available for pickup at this location' }),
            createLoadingMessage({ timer: { time: 1500, showLoader: true } })
        ], false), storeModules.main.pickUpItems.map(function (item) {
            return createImageMessage({ text: item.title, imageSrc: item.thumbnailUrl });
        }), true), [
            createLoadingMessage({
                apiRequest: {
                    apiUrl: function () { var _a; return requestPickupItems({ warehouseId: (_a = storeModules.main.warehouse) === null || _a === void 0 ? void 0 : _a.id }); },
                    responseTransformer: function (svgImage) { return storeModules.showroomAssistant.SET_PICKUP_CODE(svgImage); },
                },
            }),
            createAssistantMessage({
                text: 'Your pickup request has been recieved.',
                animation: {
                    type: MessageAnimationTypes.confetti,
                },
                overrideNextId: NewPickupKeys.pickUpRequestReceived,
            }),
        ], false);
    }
    resetMessageIds();
    return messages;
};
export var message_pickUpRequestReceived = function () {
    var dropoffMessages = initialShowroomDropoffMessages();
    var messages = [createLoadingMessage()];
    messages = __spreadArray(__spreadArray([], messages, true), [
        createImageMessage({
            imageSrc: String(storeModules.showroomAssistant.pickupCode),
            useBgImage: false,
            isSVG: true,
            text: "Please show this QR code to the front desk to proceed with the pickup process.",
        }),
    ], false);
    if (!isEmpty(storeModules.main.existingQuotes)) {
        messages = __spreadArray(__spreadArray(__spreadArray([], messages, true), [
            createChoiceMessage({
                choices: [{ text: 'Continue' }],
            }),
            createAssistantMessage({
                text: 'Looks like you have some items to drop off with us today. Would you like to do that now?',
            }),
            createChoiceMessage({
                choices: [{ text: "No" }, { text: "Yes, drop off items", nextMessageId: dropoffMessages[0].id }],
            })
        ], false), dropoffMessages, true);
    }
    resetMessageIds();
    return messages;
};
