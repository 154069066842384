export var setHeaderData = function (to, from) {
    var nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find(function (r) { return r.meta && r.meta.title; });
    var nearestWithMeta = to.matched
        .slice()
        .reverse()
        .find(function (r) { return r.meta && r.meta.metaTags; });
    var previousNearestWithMeta = from.matched
        .slice()
        .reverse()
        .find(function (r) { return r.meta && r.meta.metaTags; });
    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    }
    else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }
    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(function (el) { var _a; return (_a = el.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(el); });
    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta)
        return;
    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags
        .map(function (tagDef) {
        var tag = document.createElement('meta');
        Object.keys(tagDef).forEach(function (key) {
            tag.setAttribute(key, tagDef[key]);
        });
        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');
        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(function (tag) { return document.head.appendChild(tag); });
};
