var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as routes from '../routes';
import adminApi from '../../adminApi';
import isNumber from 'lodash/isNumber';
import { storeModules } from 'main/store';
import { chunkArray } from 'vue2/helpers';
import { createUploadProgressDetails } from 'main/api/helpers';
export var getStilettoProductToBeProcessed = function (params) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, adminApi.get(routes.GET_IMAGE_TO_BE_PROCESSED, { params: params }).then(function (_a) {
            var data = _a.data;
            return data;
        })];
}); }); };
export var submitProduct = function (params) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, adminApi.post(routes.SUBMIT_PRODUCT, __assign({}, params)).then(function (_a) {
            var data = _a.data;
            return data;
        })];
}); }); };
export var getStilettoProductById = function (params) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, adminApi.get(routes.GET_IMAGE_TO_BE_PROCESSED_BY_ID(params.id), {}).then(function (_a) {
            var data = _a.data;
            return data;
        })];
}); }); };
export var splitProduct = function (params) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, adminApi.post(routes.SPLIT_PRODUCT, __assign({}, params)).then(function (_a) {
            var data = _a.data;
            return data;
        })];
}); }); };
export var deleteImages = function (params) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, adminApi.post(routes.DELETE_IMAGES, __assign({}, params)).then(function (_a) {
            var data = _a.data;
            return data;
        })];
}); }); };
export var saveForLaterProduct = function (params) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, adminApi.post(routes.SAVE_FOR_LATER, params).then(function (_a) {
            var data = _a.data;
            return data;
        })];
}); }); };
export var flagProduct = function (params) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, adminApi.post(routes.FLAG_PRODUCT, params).then(function (_a) {
            var data = _a.data;
            return data;
        })];
}); }); };
export var changeImageOrder = function (params) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, adminApi.post(routes.UPDATE_ORDER, params).then(function (_a) {
            var data = _a.data;
            return data;
        })];
}); }); };
//opted to use store/uploadManager instead, might be a bad idea. Might switch to using this
export var uploadSavedEditImage = function (_a, events) {
    var image = _a.image, photo_id = _a.photo_id, edits = _a.edits;
    if (events === void 0) { events = {}; }
    return __awaiter(void 0, void 0, void 0, function () {
        var formData;
        return __generator(this, function (_b) {
            formData = new FormData();
            formData.append('image', image);
            formData.append('photo_id', String(photo_id));
            formData.append('edits', JSON.stringify(edits));
            return [2 /*return*/, adminApi
                    .post(routes.SAVE_EDITED_IMAGE, formData, __assign({ headers: {
                        'Content-Type': 'multipart/form-data',
                    }, params: {} }, events))
                    .then(function (_a) {
                    var data = _a.data;
                    return data;
                })];
        });
    });
};
export var uploadBulkSaveEditImageChunked = function (images, id, chunkLimit) {
    if (chunkLimit === void 0) { chunkLimit = 10; }
    return __awaiter(void 0, void 0, void 0, function () {
        var chunkedImageRequests, chunkedRequestIds, chunkedPromises, removeUploadProgress;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    chunkedImageRequests = chunkArray(images, chunkLimit);
                    chunkedRequestIds = [];
                    chunkedPromises = chunkedImageRequests.reduce(function (acc, curr, index) {
                        var productIdToUse = "".concat(id, "_").concat(index);
                        var newMethod = uploadBulkSaveEditImages(curr, {
                            onUploadProgress: function (data) {
                                storeModules.stiletto.SET_BULK_UPLOAD_PROGRESS({
                                    id: productIdToUse,
                                    progress: createUploadProgressDetails(data).progress,
                                });
                            },
                        }).catch(function (err) {
                            throw err;
                        });
                        acc.push(newMethod);
                        chunkedRequestIds.push(productIdToUse);
                        return acc;
                    }, []);
                    removeUploadProgress = function () {
                        chunkedRequestIds.forEach(function (productIdToUse) {
                            storeModules.stiletto.resetUploadForProduct(productIdToUse);
                        });
                    };
                    return [4 /*yield*/, Promise.all(chunkedPromises)
                            .then(function () { return removeUploadProgress(); })
                            .catch(function (err) {
                            removeUploadProgress();
                            throw err;
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
};
export var uploadBulkSaveEditImages = function (images, events) {
    if (events === void 0) { events = {}; }
    return __awaiter(void 0, void 0, void 0, function () {
        var formData, res;
        return __generator(this, function (_a) {
            formData = new FormData();
            images.forEach(function (_a) {
                var image = _a.image, photo_id = _a.photo_id, edits = _a.edits;
                formData.append("photos[".concat(photo_id, "][image]"), image);
                formData.append("photos[".concat(photo_id, "][edits]"), JSON.stringify(__assign({}, edits)));
            });
            res = 0;
            formData.forEach(function (x) {
                //@ts-ignore
                if (isNumber(x.size))
                    res = res + x.size;
            });
            console.log("Number of images ".concat(images.length, ","), res / 1000000, 'MB');
            return [2 /*return*/, adminApi
                    .post(routes.SAVE_EDITED_BULK_IMAGES, formData, __assign({ headers: {
                        'Content-Type': 'multipart/form-data',
                    } }, events))
                    .then(function (_a) {
                    var data = _a.data;
                    return data;
                })];
        });
    });
};
export var uploadNewImages = function (_a, events) {
    var images = _a.images, product_id = _a.product_id;
    if (events === void 0) { events = {}; }
    return __awaiter(void 0, void 0, void 0, function () {
        var formData;
        return __generator(this, function (_b) {
            formData = new FormData();
            images.forEach(function (image, n) {
                formData.append("images[".concat(n, "]"), image);
            });
            formData.append('product_id', String(product_id));
            return [2 /*return*/, adminApi
                    .post(routes.UPLOAD_NEW_IMAGES, formData, __assign({ headers: {
                        'Content-Type': 'multipart/form-data',
                    } }, events))
                    .then(function (_a) {
                    var data = _a.data;
                    return data;
                })];
        });
    });
};
