// core functionality was copied from vuetify -> node_modules/vuetify/src/directives/click-outside
export var IGNORE_CLICK_OUTSIDE_CLASS = 'ignore-click-outside';
function defaultConditional() {
    return true;
}
function directive(e, el, binding) {
    var _a;
    var handler = typeof binding.value === 'function' ? binding.value : (_a = binding.value) === null || _a === void 0 ? void 0 : _a.handler;
    var isActive = (typeof binding.value === 'object' && binding.value.closeConditional) || defaultConditional;
    // The include element callbacks below can be expensive
    // so we should avoid calling them when we're not active.
    // Explicitly check for false to allow fallback compatibility
    // with non-toggleable components
    if (!e || isActive(e) === false)
        return;
    // Check if additional elements were passed to be included in check
    // (click must be outside all included elements, if any)
    var elements = ((typeof binding.value === 'object' && binding.value.include) || (function () { return []; }))();
    // Add the root element for the component this directive was defined on
    elements.push(el);
    // CUSTOM ADDITION NOT IN VUEITFY DIRECTIVE
    // add element if ancestor contains ignore class
    var ignoredElements = typeof binding.value === 'object' &&
        binding.value.ignoreСlosestClass &&
        e.target.closest(".".concat(binding.value.ignoreСlosestClass));
    ignoredElements && elements.push(ignoredElements);
    // Check if it's a click outside our elements, and then if our callback returns true.
    // Non-toggleable components should take action in their callback and return falsy.
    // Toggleable can return true if it wants to deactivate.
    // Note that, because we're in the capture phase, this callback will occur before
    // the bubbling click event on any outside elements.
    !elements.some(function (el) { return el.contains(e.target); }) &&
        setTimeout(function () {
            isActive(e) && handler && handler(e);
        }, 0);
}
var inserted = function (el, binding) {
    var onClick = function (e) { return directive(e, el, binding); };
    // iOS does not recognize click events on document
    // or body, this is the entire purpose of the v-app
    // component and [data-app], stop removing this
    var app = document.querySelector('[data-app]') || document.body; // This is only for unit tests
    app.addEventListener('click', onClick, true);
    // @ts-ignore
    el._clickOutside = onClick;
};
var unbind = function (el) {
    // @ts-ignore
    if (!el._clickOutside)
        return;
    var app = document.querySelector('[data-app]') || document.body; // This is only for unit tests
    // @ts-ignore
    app && app.removeEventListener('click', el._clickOutside, true);
    // @ts-ignore
    delete el._clickOutside;
};
var clickOutside = {
    inserted: inserted,
    unbind: unbind,
};
export default clickOutside;
