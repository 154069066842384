import { RouteNames } from './routeNames';
import Page404 from '../views/404.vue';
var ErrorRoutes = [
    {
        path: "/".concat(RouteNames.ErrorPage),
        name: RouteNames.ErrorPage,
        component: Page404,
    },
    { path: '*', redirect: { name: RouteNames.ErrorPage } },
];
export default ErrorRoutes;
