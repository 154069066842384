export var GET_IMAGE_TO_BE_PROCESSED = 'api/stiletto/product';
export var GET_IMAGE_TO_BE_PROCESSED_BY_ID = function (id) { return "api/stiletto/product/".concat(id); };
export var GET_EDIT_IMAGE = function (productImageId) { return "api/stiletto/product-image/".concat(productImageId); };
export var DELETE_IMAGES = 'api/stiletto/delete-images';
export var SAVE_FOR_LATER = "api/stiletto/skip";
export var FLAG_PRODUCT = "/api/stiletto/flag-product";
export var SUBMIT_PRODUCT = 'api/stiletto/submit';
export var SPLIT_PRODUCT = 'api/stiletto/split';
export var UPDATE_ORDER = '/api/stiletto/update-order';
export var SAVE_EDITED_IMAGE = 'api/stiletto/save-edit';
export var SAVE_EDITED_BULK_IMAGES = 'api/stiletto/save-edits';
export var UPLOAD_NEW_IMAGES = 'api/stiletto/upload-images';
export var PREVIEW_PRODUCTS = '/api/stiletto/preview-products';
export var ESCALATE_INCIDENT = '/api/stiletto/escalate-incident';
//DASHBOARD
export var GET_DASHBOARD_DATA = '/api/stiletto-dashboard';
export var GET_DASHBOARD_PREVIOUS_ITEMS = "/api/stiletto-dashboard/recent-edits";
export var GET_FLAGGED_PRODUCTS = "/api/stiletto-dashboard/flagged-products";
export var MARK_AS_RESOLVED = '/api/stiletto-dashboard/mark-resolved/';
