import { render, staticRenderFns } from "./AppOverlayLoader.vue?vue&type=template&id=596a12a1&scoped=true"
import script from "./AppOverlayLoader.vue?vue&type=script&lang=ts"
export * from "./AppOverlayLoader.vue?vue&type=script&lang=ts"
import style0 from "./AppOverlayLoader.vue?vue&type=style&index=0&id=596a12a1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "596a12a1",
  null
  
)

export default component.exports