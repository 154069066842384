import axios from 'axios';

const mainStore = {
  state: {
    authToken: '',
    isLoggedIn: false,
    brands: [],
    tokenExpires: '',
    confirmedAddress: {},
    webAppToken: '',
    conditions: [
      {
        optionName: 'Flawed',
        optionValue: 6,
      },
      {
        optionName: 'Fair',
        optionValue: 1,
      },
      {
        optionName: 'Good',
        optionValue: 2,
      },
      {
        optionName: 'Very Good',
        optionValue: 3,
      },
      {
        optionName: 'Excellent',
        optionValue: 4,
      },
      {
        optionName: 'New',
        optionValue: 5,
      },
    ],
    signature: '',
    confirmedQuotes: [],
    paymentMethods: [
      {
        optionName: 'Check',
        optionValue: 'check',
      },
      {
        optionName: 'ACH',
        optionValue: 'ach',
        fields: [
          {
            type: 'text',
            name: 'Bank Name',
            value: 'achBankName',
          },
          {
            type: 'text',
            name: 'Account Number',
            value: 'achAccountNumber',
          },
          {
            type: 'text',
            name: 'Routing Number',
            value: 'achRoutingNumber',
          },
        ],
      },
      {
        optionName: 'Store Credit',
        optionValue: 'account_balance',
      },
      {
        optionName: 'PayPal',
        optionValue: 'paypal',
        fields: [
          {
            type: 'email',
            name: 'PayPal Email Address',
            value: 'paypalEmail',
          },
        ],
      },
      {
        optionName: 'Neiman Marcus Gift Card',
        optionValue: 'nm_gift_card',
        fields: [
          {
            type: 'text',
            name: 'Drivers License Number',
            value: 'driversLicenseNumber',
          },
          {
            type: 'select',
            name: 'State',
            value: 'driversLicenseState',
          },
          {
            type: 'date',
            name: 'Birthdate',
            value: 'birthdate',
          },
        ],
      },
    ],
  },
  mutations: {
    setBrands(state, brands) {
      state.brands = brands;
    },
    setWebAppToken(state, token) {
      state.webAppToken = token;
      window.location.href = '/showroom-assistant/dialog';
    },
    addQuote(state, quote) {
      state.confirmedQuotes.push(quote);
    },
    removeQuote(state, quoteId) {
      state.confirmedQuotes = state.confirmedQuotes.filter(function (confirmedQuote) {
        return confirmedQuote.id !== quoteId;
      });
    },
    confirmAddress(state, address) {
      state.confirmedAddress = address;
    },
    storeSignature(state, signature) {
      state.signature = signature;
    },
    removeSignature(state) {
      state.signature = '';
    },
  },
  actions: {
    getBrands({ state }) {
      return axios
        .get(process.env.MIX_FASHIONPHILE_API_URL + '/api/categories?limit=100&filter=enabledForQuotes', {
          headers: {
            Accept: 'application/x.fashionphile.v1+json',
          },
        })
        .then(response => {
          let brandsArray = response.data.data,
            brands = [];
          brandsArray.forEach(function (inputObject) {
            brands.push({
              optionName: inputObject.attributes.name,
              optionValue: inputObject.id,
            });
          });
          this.commit('setBrands', brands);
        });
    },
    loginUser({ state, dispatch }, data) {
      this.commit('loginUser', data.data);
      dispatch('getBrands');
    },
    setWebAppToken({ state }, token) {
      this.commit('setWebAppToken', token);
    },
    addConfirmedQuote({ state }, quote) {
      this.commit('addQuote', quote);
    },
    removeConfirmedQuote({ state }, quoteId) {
      this.commit('removeQuote', quoteId);
    },
    confirmAddress({ state }, address) {
      this.commit('confirmAddress', address);
    },
    storeSignature({ state }, signature) {
      this.commit('storeSignature', signature);
    },
    removeSignature({ state }) {
      this.commit('removeSignature');
    },
  },
};

export default mainStore;
