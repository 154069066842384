export var TourKeys;
(function (TourKeys) {
    TourKeys["stop0"] = "stop-0";
    TourKeys["stop1"] = "stop-1";
    TourKeys["stop2"] = "stop-2";
    TourKeys["stop3"] = "stop-3";
    TourKeys["stop4"] = "stop-4";
    TourKeys["stop5"] = "stop-5";
    TourKeys["stop6"] = "stop-6";
    TourKeys["stop7"] = "stop-7";
    TourKeys["stop8"] = "stop-8";
    TourKeys["stop9"] = "stop-9";
    TourKeys["endTour"] = "end-tour";
})(TourKeys || (TourKeys = {}));
export var TourConfigKey;
(function (TourConfigKey) {
    TourConfigKey["getTourVariableData"] = "get-tour-variable-data";
    TourConfigKey["startTourWithData"] = "startTourWithData";
})(TourConfigKey || (TourConfigKey = {}));
