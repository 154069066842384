var _a, _b;
export var ButtonTypes;
(function (ButtonTypes) {
    ButtonTypes["primary"] = "primary";
    ButtonTypes["secondary"] = "secondary";
    ButtonTypes["glass"] = "glass";
    ButtonTypes["ghostWhite"] = "ghost-white";
    ButtonTypes["white"] = "white";
    ButtonTypes["whiteBordered"] = "white-bordered";
    ButtonTypes["blue"] = "blue";
    ButtonTypes["gray"] = "gray";
    ButtonTypes["danger"] = "danger";
})(ButtonTypes || (ButtonTypes = {}));
export var ButtonSizes;
(function (ButtonSizes) {
    ButtonSizes["sm"] = "sm";
    ButtonSizes["md"] = "md";
    ButtonSizes["lg"] = "lg";
    ButtonSizes["xl"] = "xl";
})(ButtonSizes || (ButtonSizes = {}));
export var buttonTypeConfig = (_a = {},
    _a[ButtonTypes.primary] = 'btn-primary',
    _a[ButtonTypes.secondary] = 'btn-secondary',
    _a[ButtonTypes.glass] = 'btn-glass',
    _a[ButtonTypes.ghostWhite] = 'btn-ghost-white',
    _a[ButtonTypes.white] = 'btn-white',
    _a[ButtonTypes.whiteBordered] = 'btn-white-bordered',
    _a[ButtonTypes.blue] = 'btn-blue',
    _a[ButtonTypes.gray] = 'btn-gray',
    _a[ButtonTypes.danger] = 'btn-danger',
    _a);
//TODO consider moving main.scss button styles to component
export var buttonSizeConfig = (_b = {},
    _b[ButtonSizes.sm] = 'btn-sm',
    _b[ButtonSizes.md] = 'btn-md',
    _b[ButtonSizes.lg] = 'btn-lg',
    _b[ButtonSizes.xl] = 'btn-xl',
    _b);
