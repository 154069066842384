var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { fabric } from "fabric";
import { getCanvasDimensions } from "./canvas";
import { addShapes, createRect } from "./shapes";
import { applyOpacityToColor, extractOpacityFromColor } from "./colors";
import isEmpty from "lodash/isEmpty";
export var VignettePosition;
(function (VignettePosition) {
    VignettePosition["bottom"] = "bottom";
    VignettePosition["top"] = "top";
    VignettePosition["left"] = "left";
    VignettePosition["right"] = "right";
})(VignettePosition || (VignettePosition = {}));
export var applyLinearVignetteAllEdges = function (canvas, params, isExport) { var _a, params_1, params_1_1; return __awaiter(void 0, void 0, void 0, function () {
    var vignetteObjects, _loop_1, _b, vignetteObjects_1, vignetteObjects_1_1, e_1_1, p, featherPercent, position, percent, featherOpacity, e_2_1;
    var _c, e_1, _d, _e, _f, e_2, _g, _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0: return [4 /*yield*/, hasLinearVignettes(canvas)];
            case 1:
                vignetteObjects = _j.sent();
                if (!!isEmpty(vignetteObjects)) return [3 /*break*/, 15];
                _j.label = 2;
            case 2:
                _j.trys.push([2, 8, 9, 14]);
                _loop_1 = function () {
                    var p, assosciatedParam;
                    return __generator(this, function (_k) {
                        switch (_k.label) {
                            case 0:
                                _e = vignetteObjects_1_1.value;
                                _b = false;
                                _k.label = 1;
                            case 1:
                                _k.trys.push([1, , 5, 6]);
                                p = _e;
                                return [4 /*yield*/, params.find(function (_a) {
                                        var position = _a.position;
                                        return p.name === position;
                                    })];
                            case 2:
                                assosciatedParam = (_k.sent());
                                return [4 /*yield*/, updateSingleVignetteFeathering(p, assosciatedParam)];
                            case 3:
                                _k.sent();
                                return [4 /*yield*/, updateSingleVignetteDimensions(canvas, p, assosciatedParam)];
                            case 4:
                                _k.sent();
                                return [3 /*break*/, 6];
                            case 5:
                                _b = true;
                                return [7 /*endfinally*/];
                            case 6: return [2 /*return*/];
                        }
                    });
                };
                _b = true, vignetteObjects_1 = __asyncValues(vignetteObjects);
                _j.label = 3;
            case 3: return [4 /*yield*/, vignetteObjects_1.next()];
            case 4:
                if (!(vignetteObjects_1_1 = _j.sent(), _c = vignetteObjects_1_1.done, !_c)) return [3 /*break*/, 7];
                return [5 /*yield**/, _loop_1()];
            case 5:
                _j.sent();
                _j.label = 6;
            case 6: return [3 /*break*/, 3];
            case 7: return [3 /*break*/, 14];
            case 8:
                e_1_1 = _j.sent();
                e_1 = { error: e_1_1 };
                return [3 /*break*/, 14];
            case 9:
                _j.trys.push([9, , 12, 13]);
                if (!(!_b && !_c && (_d = vignetteObjects_1.return))) return [3 /*break*/, 11];
                return [4 /*yield*/, _d.call(vignetteObjects_1)];
            case 10:
                _j.sent();
                _j.label = 11;
            case 11: return [3 /*break*/, 13];
            case 12:
                if (e_1) throw e_1.error;
                return [7 /*endfinally*/];
            case 13: return [7 /*endfinally*/];
            case 14:
                canvas.renderAll();
                return [2 /*return*/, canvas];
            case 15:
                _j.trys.push([15, 23, 24, 29]);
                _a = true, params_1 = __asyncValues(params);
                _j.label = 16;
            case 16: return [4 /*yield*/, params_1.next()];
            case 17:
                if (!(params_1_1 = _j.sent(), _f = params_1_1.done, !_f)) return [3 /*break*/, 22];
                _h = params_1_1.value;
                _a = false;
                _j.label = 18;
            case 18:
                _j.trys.push([18, , 20, 21]);
                p = _h;
                featherPercent = p.featherPercent, position = p.position, percent = p.percent, featherOpacity = p.featherOpacity;
                return [4 /*yield*/, applyLinearVignette(canvas, {
                        position: position,
                        percent: percent,
                        featherPercent: featherPercent,
                        featherOpacity: featherOpacity,
                        isExport: isExport,
                    })];
            case 19:
                canvas = _j.sent();
                return [3 /*break*/, 21];
            case 20:
                _a = true;
                return [7 /*endfinally*/];
            case 21: return [3 /*break*/, 16];
            case 22: return [3 /*break*/, 29];
            case 23:
                e_2_1 = _j.sent();
                e_2 = { error: e_2_1 };
                return [3 /*break*/, 29];
            case 24:
                _j.trys.push([24, , 27, 28]);
                if (!(!_a && !_f && (_g = params_1.return))) return [3 /*break*/, 26];
                return [4 /*yield*/, _g.call(params_1)];
            case 25:
                _j.sent();
                _j.label = 26;
            case 26: return [3 /*break*/, 28];
            case 27:
                if (e_2) throw e_2.error;
                return [7 /*endfinally*/];
            case 28: return [7 /*endfinally*/];
            case 29: return [2 /*return*/, canvas];
        }
    });
}); };
var applyLinearVignette = function (canvas, _a) {
    var percent = _a.percent, position = _a.position, featherOpacity = _a.featherOpacity, featherPercent = _a.featherPercent;
    return __awaiter(void 0, void 0, void 0, function () {
        var adjustedAmountPercent, adjustAmountFeather, adjustFeatherOpacity, rect, resultingCanvas;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    adjustedAmountPercent = percent / 100;
                    adjustAmountFeather = featherPercent / 100;
                    adjustFeatherOpacity = featherOpacity / 100;
                    rect = createRect(__assign(__assign(__assign(__assign({}, setLinearGradientPosition(canvas, position, adjustedAmountPercent)), setLinearGradientDimensions(canvas, position, adjustedAmountPercent)), setLinearGradientLocks(position)), { selectable: false, hasControls: false, lockMovementX: true, lockMovementY: true, lockRotation: true, moveCursor: "default", hoverCursor: "default", cornerColor: "#4da6ff", 
                        //  borderColor: "#4da6ff",
                        transparentCorners: false, hasBorders: false, 
                        //strokeWidth: !isExport ? 2 : 0,
                        name: position, 
                        //  stroke: applyOpacityToColor("#4da6ff", 0.65),
                        //  strokeDashArray: [15, 15],
                        dirty: true, objectCaching: false, noScaleCache: false }));
                    rect.setControlsVisibility(__assign({}, setLinearGradientControls(position)));
                    rect.set("dirty", true);
                    rect = setLinearGradient(rect, position, adjustAmountFeather, adjustFeatherOpacity);
                    return [4 /*yield*/, addShapes(canvas, [rect])];
                case 1:
                    resultingCanvas = _b.sent();
                    return [2 /*return*/, resultingCanvas];
            }
        });
    });
};
var hasLinearVignettes = function (canvas) {
    return canvas
        .getObjects()
        .filter(function (_a) {
        var name = _a.name;
        return name &&
            __spreadArray([], Object.keys(VignettePosition), true).includes(name);
    });
};
var createLinearJSGradient = function (position, featherPercent, featherOpacity) {
    return new fabric.Gradient({
        type: "linear",
        gradientUnits: "percentage",
        coords: setLinearGradientCoords(position),
        colorStops: [
            { offset: 0, color: applyOpacityToColor("#fff", 1) },
            {
                offset: featherPercent,
                color: applyOpacityToColor("#fff", featherOpacity),
            },
            { offset: 1, color: applyOpacityToColor("#fff", 0) },
        ],
    });
};
var setLinearGradient = function (shape, position, featherPercent, featherOpacity) {
    return shape.set("fill", createLinearJSGradient(position, featherPercent, featherOpacity));
};
var setLinearGradientCoords = function (position) {
    if (position === VignettePosition.bottom)
        return { x1: 0, y1: 1, x2: 0, y2: 0 };
    else if (position === VignettePosition.top)
        return { x1: 0, y1: 0.25, x2: 0, y2: 1 };
    else if (position === VignettePosition.left)
        return { x1: 0, y1: 0, x2: 1, y2: 0 };
    else
        return { x1: 1, y1: 0, x2: 0, y2: 0 };
};
var DYNAMIC_OFFSET = 1;
var setLinearGradientPosition = function (canvas, position, percent) {
    var _a = getCanvasDimensions(canvas), canvasWidth = _a.canvasWidth, canvasHeight = _a.canvasHeight;
    var heightOffset = canvasHeight * percent;
    var widthOffset = canvasWidth * percent;
    if (position === VignettePosition.bottom)
        return { top: canvasHeight - heightOffset, left: -DYNAMIC_OFFSET };
    else if (position === VignettePosition.top)
        return { top: 0 - DYNAMIC_OFFSET, left: -DYNAMIC_OFFSET };
    else if (position === VignettePosition.left)
        return { top: 0, left: 0 - DYNAMIC_OFFSET };
    else
        position === VignettePosition.right;
    return { top: 0, left: canvasWidth - widthOffset };
};
var setLinearGradientDimensions = function (canvas, position, percent) {
    var _a = getCanvasDimensions(canvas), canvasWidth = _a.canvasWidth, canvasHeight = _a.canvasHeight;
    if ([VignettePosition.bottom, VignettePosition.top].includes(position))
        return {
            width: canvasWidth + DYNAMIC_OFFSET,
            height: canvasHeight * percent,
        };
    else
        return {
            width: canvasWidth * percent,
            height: canvasHeight + DYNAMIC_OFFSET,
        };
};
var setLinearGradientLocks = function (position) {
    if ([VignettePosition.bottom, VignettePosition.top].includes(position))
        return { lockScalingX: true };
    else
        return { lockScalingY: true };
};
var setLinearGradientControls = function (position) {
    var baseContorls = {
        mtr: false,
        tr: false,
        br: false,
        bl: false,
        tl: false,
        mt: false,
        ml: false,
        mr: false,
        mb: false,
    };
    if (position === VignettePosition.bottom)
        return __assign(__assign({}, baseContorls), { mt: true });
    else if (position === VignettePosition.top)
        return __assign(__assign({}, baseContorls), { mb: true });
    else if (position === VignettePosition.left)
        return __assign(__assign({}, baseContorls), { mr: true });
    return __assign(__assign({}, baseContorls), { ml: true });
};
var updateSingleVignetteFeathering = function (r, data) {
    var featherPercent = data.featherPercent, featherOpacity = data.featherOpacity;
    var adjustedFeatherAmount = featherPercent / 100;
    var adjustedOpacityAmount = featherOpacity / 100;
    r.dirty = true;
    var newGradient = createLinearJSGradient(r.name, adjustedFeatherAmount, adjustedOpacityAmount);
    r.set("fill", newGradient);
};
var updateSingleVignetteDimensions = function (canvas, r, data, renderAfter) {
    if (renderAfter === void 0) { renderAfter = false; }
    var adjustedAmountPercent = data.percent / 100;
    var _a = setLinearGradientDimensions(canvas, r.name, adjustedAmountPercent), width = _a.width, height = _a.height;
    var _b = setLinearGradientPosition(canvas, r.name, adjustedAmountPercent), top = _b.top, left = _b.left;
    r.dirty = true;
    r.set({
        height: height,
        width: width,
        top: top,
        left: left,
        dirty: true,
    });
    canvas.requestRenderAll();
    canvas.bringToFront(r);
};
export var updateVignetteFeathering = function (canvas, data) {
    var objs = canvas.getObjects();
    objs.forEach(function (r) {
        updateSingleVignetteFeathering(r, data);
    });
    canvas.renderAll();
};
export var getVignettesForExport = function (canvas) {
    var _a = getCanvasDimensions(canvas), canvasHeight = _a.canvasHeight, canvasWidth = _a.canvasWidth;
    var objs = canvas.getObjects();
    var results = [];
    var determinePercentage = function (name, _a) {
        var width = _a.width, height = _a.height;
        var result = 0;
        if ([VignettePosition.bottom, VignettePosition.top].includes(name))
            result = height / canvasHeight;
        else
            result = width / canvasWidth;
        return result * 100;
    };
    var determineWidthAndHeight = function (name, r) {
        if ([VignettePosition.bottom, VignettePosition.top].includes(name)) {
            return {
                width: r.width,
                height: (r.height - DYNAMIC_OFFSET) * (r.scaleY || 1),
            };
        }
        return {
            width: (r.width - DYNAMIC_OFFSET) * (r.scaleX || 1),
            height: r.height,
        };
    };
    objs.forEach(function (r) {
        var _a;
        var fill = (_a = r.fill.colorStops) === null || _a === void 0 ? void 0 : _a[1];
        var featherPercent = (fill === null || fill === void 0 ? void 0 : fill.offset) * 100;
        var featherOpacity = extractOpacityFromColor(fill === null || fill === void 0 ? void 0 : fill.color);
        var _b = determineWidthAndHeight(r.name, r), width = _b.width, height = _b.height;
        var percent = determinePercentage(r.name, {
            width: width,
            height: height,
        });
        //@ts-ignore
        results.push({
            position: r.name,
            featherPercent: featherPercent,
            featherOpacity: featherOpacity,
            percent: percent,
        });
    });
    return results;
};
