var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue from 'vue';
import Vuex from 'vuex';
import { getModule } from 'vuex-module-decorators';
import rootStore from './rootStore';
import showroomAssistant from './showroomAssistant';
import user from './user';
import main from './main';
import mechanicalMatcher from './mechanicalMatcher';
import stiletto from './stiletto';
import uploadManager from './uploadManager';
Vue.use(Vuex);
var modules = {
    showroomAssistant: showroomAssistant,
    user: user,
    main: main,
    mechanicalMatcher: mechanicalMatcher,
    stiletto: stiletto,
    uploadManager: uploadManager,
};
var store = new Vuex.Store(__assign(__assign({}, rootStore), { modules: modules }));
export default store;
export var storeModules = {
    showroomAssistant: getModule(showroomAssistant, store),
    user: getModule(user, store),
    main: getModule(main, store),
    mechanicalMatcher: getModule(mechanicalMatcher, store),
    stiletto: getModule(stiletto, store),
    uploadManager: getModule(uploadManager, store),
};
export * from './showroomAssistant';
export * from './user';
export * from './main';
export * from './mechanicalMatcher';
export * from './stiletto';
export * from './uploadManager';
