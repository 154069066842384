export var PaymentMethods;
(function (PaymentMethods) {
    PaymentMethods["CHECK"] = "check";
    PaymentMethods["ACH"] = "ach";
    PaymentMethods["STORE_CREDIT"] = "account_balance";
    PaymentMethods["PAYPAL"] = "paypal";
    PaymentMethods["NEIMAN_MARCUS_GIFT_CARD"] = "nm_gift_card";
    PaymentMethods["WIRE_TRANSFER"] = "wire_transfer";
    PaymentMethods["FARFETCH"] = "farfetch";
    PaymentMethods["ACCOUNT_BALANCE"] = "account_balance";
})(PaymentMethods || (PaymentMethods = {}));
