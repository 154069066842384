import { fabric } from "fabric";
var filters = fabric.Image.filters;
filters.Brightness;
var createClass = fabric.util.createClass;
//@ts-ignore
filters.WhiteBalance = createClass(filters.BaseFilter, {
    type: "WhiteBalance",
    initialize: function (options) {
        this.whiteBalance = [225, 228, 243];
        filters.BaseFilter.prototype.initialize.call(this, options);
    },
    whiteBalance: [225, 228, 243],
    getUniformLocations: function (gl, program) {
        return {
            uWhiteBalance: gl.getUniformLocation(program, "uWhiteBalance"),
        };
    },
    sendUniformData: function (gl, uniformLocations) {
        gl.uniform3fv(uniformLocations.uWhiteBalance, this.whiteBalance);
    },
    fragmentSource: "precision highp float;\n" +
        "uniform sampler2D uTexture;\n" +
        "varying vec2 vTexCoord;\n" +
        "uniform vec3 uWhiteBalance;\n" +
        "void main() {\n" +
        "vec4 color = texture2D(uTexture, vTexCoord);\n" +
        "color.r = color.r * (255.0 / float(uWhiteBalance[0]));\n" +
        "color.g = color.g * (255.0 / float(uWhiteBalance[1]));\n" +
        "color.b = color.b * (255.0 / float(uWhiteBalance[2]));\n" +
        "gl_FragColor = color;\n" +
        "}",
    isNeutralState: function () {
        return false;
    },
    toObject: function () {
        return fabric.util.object.extend(this.callSuper("toObject"), {
            colorSource: this.colorSource,
        });
    },
});
//@ts-ignore
fabric.Image.filters.WhiteBalance.fromObject =
    //@ts-ignore
    fabric.Image.filters.BaseFilter.fromObject;
