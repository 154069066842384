var _a;
import { NewQuoteKeys } from './config';
import { newQuoteMessage_selectBrand, newQuoteMessage_chosenBrand, newQuoteMessage_selectName, newQuoteMessage_chosenName, newQuoteMessage_selectColor, newQuoteMessage_chosenColor, newQuoteMessage_selectImageToUpload, newQuoteMessage_selectParentStyle, newQuoteMessage_getPredicition, newQuoteMessage_displayPrediction, newQuoteMessage_chosenParentStyle, newQuoteMessage_chosenSubStyle, newQuoteMessage_selectImageSubStyle, } from './newQuoteMessages';
//eslint-disable-next-line
export var newQuoteMessageByKey = (_a = {},
    _a[NewQuoteKeys.startNewQuote] = newQuoteMessage_selectBrand,
    _a[NewQuoteKeys.hasChosenBrand] = newQuoteMessage_chosenBrand,
    _a[NewQuoteKeys.selectProductName] = newQuoteMessage_selectName,
    _a[NewQuoteKeys.hasChosenName] = newQuoteMessage_chosenName,
    _a[NewQuoteKeys.selectColor] = newQuoteMessage_selectColor,
    _a[NewQuoteKeys.hasChosenColor] = newQuoteMessage_chosenColor,
    _a[NewQuoteKeys.selectImageToUpload] = newQuoteMessage_selectImageToUpload,
    _a[NewQuoteKeys.selectParentStyle] = newQuoteMessage_selectParentStyle,
    _a[NewQuoteKeys.getProductPricePrediction] = newQuoteMessage_getPredicition,
    _a[NewQuoteKeys.displayProductPricePrediction] = newQuoteMessage_displayPrediction,
    _a[NewQuoteKeys.hasChosenParentStyle] = newQuoteMessage_chosenParentStyle,
    _a[NewQuoteKeys.hasChosenSubStyle] = newQuoteMessage_chosenSubStyle,
    _a[NewQuoteKeys.selectImageSubStyle] = newQuoteMessage_selectImageSubStyle,
    _a);
export default newQuoteMessageByKey;
