export default {
  currency(amount) {
    return "$" + Number(amount).toFixed(2);
  },
  dynamicContent(content, data) {
    if (content && data) {
      return content.replace(
        /{\s*(\w+?)\s*}/g,
        (_, token) => data[token] || ""
      );
    }
  },
  singularOrPlural(count, singular, plural) {
    if (count > 1) {
      return plural;
    }
    return singular;
  },
  titleFromSnake(string, delimiter = "_") {
    return string
      .split(delimiter)
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(" ");
  },
  isEmptyObject(object) {
    return JSON.stringify(object) === "{}";
  },
};
