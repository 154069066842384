var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { v4 as uuidv4 } from 'uuid';
import shuffle from 'lodash/shuffle';
import { MessageSender, MessageType, MessageAnimationTypes, QuizTypes, } from 'main/store';
import last from 'lodash/last';
import first from 'lodash/first';
import isString from 'lodash/isString';
//Used for mocking messages, api should use unique ids as well
var CURRENT_ID_TRACKER = uuidv4();
var NEXT_ID_TRACKER = CURRENT_ID_TRACKER;
//overrideNextId is used for messages that are inserted non sequentially (quiz repsonses)
var getIds = function (_a) {
    var _b = _a === void 0 ? {} : _a, overrideNextId = _b.overrideNextId, overrideCurrentId = _b.overrideCurrentId;
    if (overrideNextId && overrideCurrentId) {
        CURRENT_ID_TRACKER = overrideCurrentId;
        NEXT_ID_TRACKER = overrideNextId;
    }
    else if (overrideNextId) {
        CURRENT_ID_TRACKER = NEXT_ID_TRACKER;
        NEXT_ID_TRACKER = overrideNextId;
    }
    else if (overrideCurrentId) {
        CURRENT_ID_TRACKER = overrideCurrentId;
    }
    else {
        CURRENT_ID_TRACKER = NEXT_ID_TRACKER;
        NEXT_ID_TRACKER = uuidv4();
    }
    return { CURRENT_ID_TRACKER: CURRENT_ID_TRACKER, NEXT_ID_TRACKER: NEXT_ID_TRACKER };
};
export var resetMessageIds = function () {
    CURRENT_ID_TRACKER = uuidv4();
    NEXT_ID_TRACKER = CURRENT_ID_TRACKER;
};
export var createNoneMessage = function (props) {
    var overrideCurrentId = (props || {}).overrideCurrentId;
    var CURRENT_ID_TRACKER = getIds({ overrideCurrentId: overrideCurrentId }).CURRENT_ID_TRACKER;
    return {
        sender: MessageSender.Assistant,
        props: {},
        type: MessageType.None,
        id: CURRENT_ID_TRACKER,
        nextMessageId: undefined,
    };
};
export var createLoadingMessage = function (props) {
    if (props === void 0) { props = {}; }
    return createDefaultMessage(props, MessageType.Loading);
};
export var createUserMessage = function (props) {
    return createTextMessage(__assign(__assign({}, props), { sender: MessageSender.Self }));
};
export var createAssistantMessage = function (props) {
    return createTextMessage(__assign(__assign({}, props), { sender: MessageSender.Assistant }));
};
export var createTextMessage = function (_a) {
    var sender = _a.sender, _b = _a.text, text = _b === void 0 ? '' : _b, overrideNextId = _a.overrideNextId, overrideCurrentId = _a.overrideCurrentId, timer = _a.timer, animation = _a.animation, ignoreWhenSeeding = _a.ignoreWhenSeeding, inTextLinks = _a.inTextLinks;
    var _c = getIds({ overrideNextId: overrideNextId, overrideCurrentId: overrideCurrentId }), CURRENT_ID_TRACKER = _c.CURRENT_ID_TRACKER, NEXT_ID_TRACKER = _c.NEXT_ID_TRACKER;
    var props = {
        text: text,
        animation: animation,
        inTextLinks: inTextLinks,
    };
    return {
        timer: timer,
        sender: sender,
        props: props,
        type: MessageType.Text,
        id: CURRENT_ID_TRACKER,
        nextMessageId: NEXT_ID_TRACKER,
        ignoreWhenSeeding: ignoreWhenSeeding,
    };
};
export var createImageMessage = function (_a) {
    var _b = _a.sender, sender = _b === void 0 ? MessageSender.Assistant : _b, _c = _a.text, text = _c === void 0 ? '' : _c, timer = _a.timer, overrideNextId = _a.overrideNextId, overrideCurrentId = _a.overrideCurrentId, etcProps = __rest(_a, ["sender", "text", "timer", "overrideNextId", "overrideCurrentId"]);
    var _d = getIds({ overrideNextId: overrideNextId, overrideCurrentId: overrideCurrentId }), CURRENT_ID_TRACKER = _d.CURRENT_ID_TRACKER, NEXT_ID_TRACKER = _d.NEXT_ID_TRACKER;
    var props = __assign({ text: text }, etcProps);
    return {
        id: CURRENT_ID_TRACKER,
        sender: sender || MessageSender.Assistant,
        type: MessageType.Image,
        props: props,
        timer: timer,
        nextMessageId: NEXT_ID_TRACKER,
    };
};
export var createChoiceMessage = function (_a) {
    var choices = _a.choices, overrideNextId = _a.overrideNextId, overrideCurrentId = _a.overrideCurrentId;
    var _b = getIds({ overrideNextId: overrideNextId, overrideCurrentId: overrideCurrentId }), CURRENT_ID_TRACKER = _b.CURRENT_ID_TRACKER, NEXT_ID_TRACKER = _b.NEXT_ID_TRACKER;
    var choicesWithIds = choices.map(function (_a) {
        var text = _a.text, nextMessageId = _a.nextMessageId, props = __rest(_a, ["text", "nextMessageId"]);
        var message = isString(text)
            ? { text: text }
            : __assign({}, text);
        return __assign({ message: message, nextMessageId: nextMessageId || NEXT_ID_TRACKER }, props);
    });
    var props = {
        choices: choicesWithIds,
    };
    return {
        id: CURRENT_ID_TRACKER,
        sender: MessageSender.Assistant,
        type: MessageType.TextChoice,
        nextMessageId: NEXT_ID_TRACKER,
        props: props,
    };
};
export var createVideoMessage = function (_a) {
    var props = __rest(_a, []);
    return createDefaultMessage(props, MessageType.Video);
};
export var createTitleMessage = function (_a) {
    var props = __rest(_a, []);
    return createDefaultMessage(props, MessageType.Title);
};
export var createQuizMessage = function (_a) {
    var answerId = _a.answerId, question = _a.question, _b = _a.answerTextArray, answerTextArray = _b === void 0 ? [] : _b, _c = _a.quizType, quizType = _c === void 0 ? QuizTypes.TextOptions : _c, timer = _a.timer, successMessageText = _a.successMessageText, failMessageText = _a.failMessageText, overrideNextId = _a.overrideNextId, overrideCurrentId = _a.overrideCurrentId;
    var _d = getIds({ overrideNextId: overrideNextId, overrideCurrentId: overrideCurrentId }), CURRENT_ID_TRACKER = _d.CURRENT_ID_TRACKER, NEXT_ID_TRACKER = _d.NEXT_ID_TRACKER;
    var _e = createQuizAnswers({
        nextMessageId: NEXT_ID_TRACKER,
        answerId: answerId,
        successMessageText: successMessageText,
        failMessageText: failMessageText,
        answerTextArray: answerTextArray,
    }), answers = _e.answers, successMessage = _e.successMessage, failMessage = _e.failMessage;
    var props = {
        answerId: answerId,
        question: question,
        answers: answers,
        quizType: quizType,
    };
    var quizMessage = {
        id: CURRENT_ID_TRACKER,
        sender: MessageSender.Assistant,
        type: MessageType.Quiz,
        props: props,
        timer: timer,
    };
    return __spreadArray(__spreadArray([quizMessage], successMessage, true), failMessage, true);
};
export var createQuizAnswers = function (_a) {
    var nextMessageId = _a.nextMessageId, answerId = _a.answerId, successMessageText = _a.successMessageText, failMessageText = _a.failMessageText, answerTextArray = _a.answerTextArray;
    var successId = function (index) { return "".concat(nextMessageId, "_correct_").concat(index); };
    var failId = function (index) { return "".concat(nextMessageId, "_incorrect_").concat(index); };
    var isLastMessage = function (text, array) { return last(array) === text; };
    var isFirstMessage = function (text, array) { return first(array) === text; };
    var successMessage = successMessageText.map(function (text, index) {
        return createTextMessage({
            sender: MessageSender.Assistant,
            text: text,
            overrideNextId: !isLastMessage(text, successMessageText) ? successId(index + 1) : nextMessageId,
            overrideCurrentId: successId(index),
            animation: isFirstMessage(text, successMessageText)
                ? {
                    type: MessageAnimationTypes.confetti,
                }
                : undefined,
            ignoreWhenSeeding: true,
        });
    });
    var failMessage = failMessageText.map(function (text, index) {
        return createTextMessage({
            sender: MessageSender.Assistant,
            text: text,
            overrideNextId: !isLastMessage(text, failMessageText) ? failId(index + 1) : nextMessageId,
            overrideCurrentId: failId(index),
            ignoreWhenSeeding: true,
        });
    });
    var answers = answerTextArray.map(function (text, id) {
        var nextMessageId = id === answerId ? successMessage[0].id : failMessage[0].id;
        return {
            id: id,
            nextMessageId: nextMessageId,
            text: text,
        };
    });
    return { successMessage: successMessage, failMessage: failMessage, answers: shuffle(answers) };
};
export var createInputTextMessage = function (props) {
    return createDefaultMessage(props, MessageType.InputText);
};
export var createInputTextAreaMessage = function (props) {
    return createDefaultMessage(props, MessageType.InputTextArea);
};
export var createInputTextMultiple = function (props) {
    return createDefaultMessage(props, MessageType.InputTextMultiple);
};
export var createInputSelect = function (props) {
    return createDefaultMessage(props, MessageType.InputSelect);
};
export var createInputSearchSelect = function (props) {
    return createDefaultMessage(props, MessageType.InputSearchSelect);
};
export var createInputSignature = function (props) {
    return createDefaultMessage(props, MessageType.InputSignature);
};
export var createInputUpload = function (props) {
    return createDefaultMessage(props, MessageType.InputUpload);
};
export var createInputPhoneMessage = function (props) {
    if (props === void 0) { props = {}; }
    return createDefaultMessage(props, MessageType.InputPhone);
};
export var createConfirmationMessage = function (props) {
    return createDefaultMessage(props, MessageType.Confirmation);
};
export var createChartMessage = function (props) {
    return createDefaultMessage(props, MessageType.Chart);
};
export var createLinkMessage = function (props) {
    return createDefaultMessage(props, MessageType.Link);
};
export var createDefaultMessage = function (props, type, sender) {
    if (sender === void 0) { sender = MessageSender.Assistant; }
    var overrideNextId = props.overrideNextId, overrideCurrentId = props.overrideCurrentId;
    var _a = getIds({ overrideNextId: overrideNextId, overrideCurrentId: overrideCurrentId }), CURRENT_ID_TRACKER = _a.CURRENT_ID_TRACKER, NEXT_ID_TRACKER = _a.NEXT_ID_TRACKER;
    return {
        sender: sender,
        props: props,
        type: type,
        id: CURRENT_ID_TRACKER,
        nextMessageId: NEXT_ID_TRACKER,
    };
};
