var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { storeModules } from 'main/store';
import { createChoiceMessage } from '../createMessageHelpers';
import { RouteNames } from 'main/router/routeNames';
import { isShowroomAssistantDropoffEnabled, isShowroomAssistantPickupEnabled } from '../../identifiers';
import { WareHouseIds } from 'main/config';
import isEmpty from 'lodash/isEmpty';
import { openNewTab } from 'vue2/helpers';
import { initialTourMessages } from './tour';
import { initialShowroomDropoffMessages } from './dropoff';
import { initalNewQuoteMessages } from './newQuote';
import { initialShowroomPickupMessages } from './pickup';
export var initialMessagesRecord = (_a = {},
    _a[RouteNames.ShowroomAssistantDropoff] = function () { return initialShowroomDropoffMessages; },
    _a[RouteNames.ShowroomAssistantTour] = function () { return initialTourMessages; },
    _a[RouteNames.ShowroomAssistantPickup] = function () { return initialShowroomPickupMessages; },
    _a[RouteNames.ShowroomAssistantNewQuoteAssistant] = function () { return initalNewQuoteMessages; },
    _a);
export var initialShowroomMessages = function (queryParams, pickUpItems, dropoffMethods) {
    var warehouseId = Number(queryParams['warehouse-id']);
    return [
        {
            text: 'Pick up item',
            name: RouteNames.ShowroomAssistantPickup,
            show: isShowroomAssistantPickupEnabled(queryParams) && (!isEmpty(pickUpItems) || !storeModules.user.isLoggedIn),
        },
        {
            text: 'Drop off item',
            name: RouteNames.ShowroomAssistantDropoff,
            show: isShowroomAssistantDropoffEnabled(warehouseId),
        },
        {
            text: 'Start a new web quote (temp)',
            name: RouteNames.ShowroomAssistantNewQuoteWeb,
            show: false && isShowroomAssistantDropoffEnabled(warehouseId),
        },
        {
            text: 'Start a new quote',
            name: RouteNames.ShowroomAssistantNewQuoteAssistant,
            show: isShowroomAssistantDropoffEnabled(warehouseId),
        },
        {
            text: 'Start Tour',
            name: RouteNames.ShowroomAssistantTour,
            show: warehouseId === WareHouseIds.ChelseaWarehouse,
        },
    ]
        .map(function (item) { return (__assign(__assign({}, item), { onClick: function () { return dropoffMethods && dropoffMethods[item.name](); } })); })
        .filter(function (_a) {
        var show = _a.show;
        return show;
    });
};
export var initialShowroomMessagesStoreMessages = function () {
    var _a, _b;
    var _c, _d, _e, _f, _g;
    var initialMessages = initialShowroomMessages({ 'warehoud-id': (_c = storeModules.main.warehouse) === null || _c === void 0 ? void 0 : _c.id }, storeModules.main.pickUpItems);
    var hasShownMessage = function (routeName) { return !!initialMessages.find(function (_a) {
        var name = _a.name;
        return name === routeName;
    }); };
    var dropoffMessages = hasShownMessage(RouteNames.ShowroomAssistantDropoff) ? initialShowroomDropoffMessages() : [];
    var pickupMessages = hasShownMessage(RouteNames.ShowroomAssistantPickup) ? initialShowroomPickupMessages() : [];
    var tourMessages = hasShownMessage(RouteNames.ShowroomAssistantTour) ? initialTourMessages() : [];
    var newQuoteMessages = hasShownMessage(RouteNames.ShowroomAssistantNewQuoteAssistant)
        ? initalNewQuoteMessages()
        : [];
    var initalRecordNextId = (_a = {},
        _a[RouteNames.ShowroomAssistantDropoff] = (_d = dropoffMessages[0]) === null || _d === void 0 ? void 0 : _d.id,
        _a[RouteNames.ShowroomAssistantPickup] = (_e = pickupMessages[0]) === null || _e === void 0 ? void 0 : _e.id,
        _a[RouteNames.ShowroomAssistantTour] = (_f = tourMessages[0]) === null || _f === void 0 ? void 0 : _f.id,
        _a[RouteNames.ShowroomAssistantNewQuoteAssistant] = (_g = newQuoteMessages[0]) === null || _g === void 0 ? void 0 : _g.id,
        _a);
    var initialRecordMiddleware = (_b = {},
        _b[RouteNames.ShowroomAssistantNewQuoteWeb] = function () { return openNewTab('https://sell.fashionphile.com/sell-your-bag'); },
        _b);
    var initailStoreMessages = createChoiceMessage({
        choices: initialMessages.map(function (message) { return ({
            text: message.text,
            nextMessageId: initalRecordNextId[message.name] || message.name,
            middlewareFunction: initialRecordMiddleware[message.name],
        }); }),
    });
    return __spreadArray(__spreadArray(__spreadArray([initailStoreMessages], pickupMessages, true), dropoffMessages, true), tourMessages, true);
};
