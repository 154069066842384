import moment from 'moment';
import floor from 'lodash/floor';
import pluralize from 'pluralize';
export var MILLISECONDS_IN_SECOND = 1000;
export var MILLISECONDS_IN_MINUTE = MILLISECONDS_IN_SECOND * 60;
export var MILLISECONDS_IN_HOUR = MILLISECONDS_IN_MINUTE * 60;
export var SECONDS_IN_MINUTE = 60;
export var MINUTES_IN_HOUR = 60;
export var HOURS_IN_DAY = 24;
export var minutesToMilliseconds = function (minutes) {
    return minutes * MILLISECONDS_IN_MINUTE;
};
export var secondsToMilliseconds = function (seconds) {
    return seconds * MILLISECONDS_IN_SECOND;
};
export var differenceBetweenDates = function (earlyDate, lateDate, type) {
    if (type === void 0) { type = 'hours'; }
    return moment(lateDate).diff(moment(earlyDate), type);
};
export var formatSecondsAndMinutes = function (seconds) {
    var minutes = floor(seconds / SECONDS_IN_MINUTE);
    if (minutes >= 1)
        seconds -= minutes * SECONDS_IN_MINUTE;
    return {
        seconds: seconds,
        minutes: minutes,
    };
};
export var formatSecondsMinutesHours = function (secs) {
    var _a = formatSecondsAndMinutes(secs), seconds = _a.seconds, minutes = _a.minutes;
    var hours = floor(minutes / SECONDS_IN_MINUTE);
    if (hours >= 1)
        minutes -= hours * MINUTES_IN_HOUR;
    return { minutes: minutes, seconds: seconds, hours: hours };
};
export var formatSecondsMinutesHoursDays = function (secs) {
    var _a = formatSecondsMinutesHours(secs), seconds = _a.seconds, minutes = _a.minutes, hours = _a.hours;
    var days = floor(hours / HOURS_IN_DAY);
    if (days >= 1)
        hours -= days * HOURS_IN_DAY;
    return { minutes: minutes, seconds: seconds, hours: hours, days: days };
};
export var hoursToSeconds = function (hours) {
    return MINUTES_IN_HOUR * SECONDS_IN_MINUTE * hours;
};
export var formatTimeValue = function (secs, joiner) {
    if (joiner === void 0) { joiner = "\n"; }
    var _a = formatSecondsMinutesHoursDays(secs), seconds = _a.seconds, minutes = _a.minutes, hours = _a.hours, days = _a.days;
    var formatDays = "".concat(pluralize('Day', days, true));
    var formatHours = "".concat(pluralize('Hour', hours, true));
    var formatMinutes = "".concat(minutes, " Min");
    var formatSeconds = "".concat(seconds, " Sec");
    if (days && hours)
        return [formatDays, formatHours].join(joiner);
    if (days && !hours)
        return formatDays;
    if (hours && minutes)
        return [formatHours, formatMinutes].join(joiner);
    if (hours && !minutes)
        return formatHours;
    if (minutes && seconds)
        return [formatMinutes, formatSeconds].join(joiner);
    if (minutes && !seconds)
        return formatMinutes;
    return formatSeconds;
};
export var formatHoursToTimeValue = function (hours) {
    var secs = hoursToSeconds(hours);
    return formatTimeValue(secs);
};
