var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { fabric } from "fabric";
import { getCanvasDimensions } from "./canvas";
export var canvasToDataURL = function (canvas, _a) {
    var format = _a.format, width = _a.width, height = _a.height, rest = __rest(_a, ["format", "width", "height"]);
    canvas.renderAll();
    var result = canvas === null || canvas === void 0 ? void 0 : canvas.toDataURL(__assign({ multiplier: calculateMultiplier(canvas, width, height), format: format }, rest));
    return result;
};
export var downloadImage = function (canvas, _a) {
    var name = _a.name, format = _a.format, width = _a.width, height = _a.height;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_b) {
            //push download to end of callstack
            setTimeout(function () {
                var dataURL = canvasToDataURL(canvas, { format: format, width: width, height: height });
                downloadURI(dataURL, name || "download");
            }, 0);
            return [2 /*return*/];
        });
    });
};
export var canvasToJSON = function (canvas) {
    return canvas.toJSON();
};
export var canvasFromJSON = function (canvas, state, renderCanvas) {
    if (renderCanvas === void 0) { renderCanvas = true; }
    return new Promise(function (resolve) {
        canvas.loadFromJSON(state, function () {
            if (renderCanvas)
                canvas.renderAll();
            resolve(canvas);
        });
    });
};
export var canvasSetScaleToImg = function (canvas, img, render) {
    if (render === void 0) { render = true; }
    if (!(img === null || img === void 0 ? void 0 : img.width))
        return;
    var scaleX = canvas.getWidth() / img.width;
    var scaleY = canvas.getHeight() / img.height;
    var scaleRatio = Math.min(scaleX, scaleY);
    canvasSetScaleZoom(canvas, scaleRatio, render);
};
export var canvasLockAllObjects = function (canvas) {
    canvas.getObjects().forEach(function (obj) {
        obj.selectable = false;
        obj.moveCursor = "default";
        obj.hoverCursor = "default";
    });
};
export var canvasSetScaleZoom = function (canvas, scaleRatio, render) {
    if (render === void 0) { render = true; }
    canvas.setZoom(scaleRatio);
    render && canvas.renderAll();
};
export var dataURLToImage = function (dataUri) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve) {
                fabric.Image.fromURL(dataUri, function (img) {
                    resolve(img);
                });
            })];
    });
}); };
export var dataURLToBlob = function (dataURI) { return __awaiter(void 0, void 0, void 0, function () {
    var byteString, mimeString, ab, ia, i, blob;
    return __generator(this, function (_a) {
        byteString = atob(dataURI.split(",")[1]);
        mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
        ab = new ArrayBuffer(byteString.length);
        ia = new Uint8Array(ab);
        // set the bytes of the buffer to the correct values
        for (i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        blob = new Blob([ab], { type: mimeString });
        return [2 /*return*/, blob];
    });
}); };
export var blobToDataURL = function (blob) {
    return new Promise(function (resolve) {
        var a = new FileReader();
        a.onload = function (e) {
            resolve(e.target.result);
        };
        a.readAsDataURL(blob);
    });
};
export var calculateMultiplier = function (canvas, width, height) {
    if (width && height) {
        var _a = getCanvasDimensions(canvas), canvasWidth = _a.canvasWidth, canvasHeight = _a.canvasHeight;
        var multW = width / canvasWidth;
        var multH = height / canvasHeight;
        return Math.min(multH, multW);
    }
    return 1;
};
export var downloadURI = function (uri, name) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
