var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { AcceptOfferType, } from 'main/api';
import { PaymentMethods, storeModules } from 'main/store';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import { formatCurrency, inputHasText, isArrayOfNumbers } from 'vue2/helpers';
import { getExistingQuoteByQuoteId } from 'main/helpers/storeHelpers';
export var searchStyleRequestTransformer = function (text) {
    return { term: text };
};
export var searchStyleResponseTransformer = function (_a) {
    var hits = _a.hits;
    return hits.map(function (hit) { return ({
        label: hit.name,
        code: hit.id,
        image: hit.product.imageUrl,
    }); });
};
export var pendingActiveItemsResponseTransformer = function (_a) {
    var pendingItems = _a.pendingItems, pickUpItems = _a.pickUpItems;
    storeModules.main.REPLACE_QUOTES(pendingItems.data);
    storeModules.main.REPLACE_PICKUP_ITEMS(pickUpItems.data);
};
export var searchAddresRequestTransformer = function (data) {
    return data.map(function (address, index) { return (__assign({ label: addressToSingleLine(address), code: index }, address)); });
};
export var addressToSingleLine = function (address) {
    var streetAddress = [address.street_line, address.secondary].filter(inputHasText).join(' ');
    var streetAndCity = [streetAddress, address.city].filter(inputHasText).join(', ');
    var stateAndPostalCode = [address.state, address.zipcode].filter(inputHasText).join(' ');
    var addressString = [streetAndCity, stateAndPostalCode].filter(inputHasText).join(', ');
    return addressString.trim();
};
export var addressToStoreFormat = function (selectedValue) {
    var addressFormattedForStore = {
        address_1: selectedValue.street_line,
        address_2: selectedValue.secondary,
        city: selectedValue.city,
        state: selectedValue.state,
        postal_code: selectedValue.zipcode,
    };
    return addressFormattedForStore;
};
export var transformDropoffToQRCode = function (userDropoffData, warehouseId) {
    var _a, _b;
    if (!isArrayOfNumbers(userDropoffData.dropoffItems))
        console.warn('Dropoff items incorrect');
    if (!isString(userDropoffData.signature))
        console.warn('Signature incorrect');
    if (userDropoffData.addressData && !isObject(userDropoffData.addressData))
        console.warn('Address data incorrect');
    if (userDropoffData.paymentMethod && !isObject(userDropoffData.paymentMethod))
        console.warn('Payment method incorrect');
    if (userDropoffData.phone && !isString(userDropoffData.phone.phoneNumber))
        console.warn('Phone number incorrect');
    if (!warehouseId)
        console.warn('Warehouse id incorrect');
    if (userDropoffData.acceptedOfferType && !isArray(userDropoffData.acceptedOfferType))
        console.warn('Accepted offer incorrect');
    return {
        pendingItems: mapOfferTypesToDropoffItems(userDropoffData.dropoffItems, userDropoffData.acceptedOfferType || []),
        signature: userDropoffData.signature,
        addressData: userDropoffData.addressData || undefined,
        paymentMethod: __assign({ paymentMethod: (_a = userDropoffData.paymentMethod) === null || _a === void 0 ? void 0 : _a.paymentMethod }, flattenDataToCodeIfSelectData((_b = userDropoffData.paymentMethod) === null || _b === void 0 ? void 0 : _b.fields)),
        warehouseId: warehouseId,
        phone: userDropoffData.phone || undefined,
    };
};
//TODO - consider creating a user data refresh endpoint to avoid front end caching and keep server source of truth
export var overrideUserDataWithUpdatedData = function (newUserData) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    var currentUserData = __assign({}, storeModules.user.userResponse).data;
    //set payment methods
    currentUserData.sellerPayment = __assign(__assign({}, currentUserData.sellerPayment), { methodCode: ((_a = newUserData.paymentMethod) === null || _a === void 0 ? void 0 : _a.paymentMethod) || ((_b = currentUserData.sellerPayment) === null || _b === void 0 ? void 0 : _b.methodCode), method: (_c = newUserData.paymentMethod) === null || _c === void 0 ? void 0 : _c.paymentMethod, paypalAddress: ((_e = (_d = newUserData.paymentMethod) === null || _d === void 0 ? void 0 : _d.fields) === null || _e === void 0 ? void 0 : _e.paypalAddress) || ((_f = currentUserData.sellerPayment) === null || _f === void 0 ? void 0 : _f.paypalAddress) });
    if (((_g = newUserData.paymentMethod) === null || _g === void 0 ? void 0 : _g.paymentMethod) === PaymentMethods.PAYPAL) {
        currentUserData.paypalAddress =
            ((_j = (_h = newUserData.paymentMethod) === null || _h === void 0 ? void 0 : _h.fields) === null || _j === void 0 ? void 0 : _j.paypalAddress) || currentUserData.paypalAddress;
    }
    //set phone
    currentUserData.sellerAddress = __assign(__assign({}, currentUserData.sellerAddress), { phone: ((_k = newUserData.phone) === null || _k === void 0 ? void 0 : _k.phoneNumber) || ((_l = currentUserData.sellerAddress) === null || _l === void 0 ? void 0 : _l.phone), raw_phone: ((_m = newUserData.phone) === null || _m === void 0 ? void 0 : _m.phoneNumber) || ((_o = currentUserData.sellerAddress) === null || _o === void 0 ? void 0 : _o.raw_phone) });
    //set address - NOT CURRENTLY POSSIBLE BECAUSE ADDRESS IS SINGLE LINE
    storeModules.user.SET_USER(__assign(__assign({}, storeModules.user.userResponse), { data: currentUserData }));
};
var flattenDataToCodeIfSelectData = function (dataToFlatten) {
    if (dataToFlatten === void 0) { dataToFlatten = {}; }
    return Object.entries(dataToFlatten || {}).reduce(function (acc, _a) {
        var _b;
        var key = _a[0], value = _a[1];
        var isSelectData = isObject(value) && value.code && value.label;
        if (isSelectData)
            value = value.code;
        if (isObject(value))
            console.warn('Object is not SelectData', value, dataToFlatten);
        return __assign(__assign({}, acc), (_b = {}, _b[key] = value, _b));
    }, {});
};
var mapOfferTypesToDropoffItems = function (pendingItemIds, acceptedOfferTypes) {
    if (acceptedOfferTypes === void 0) { acceptedOfferTypes = []; }
    return pendingItemIds.reduce(function (acc, currId) {
        var _a;
        var matchItem = function (_a) {
            var id = _a.id;
            return id === currId;
        };
        var foundAcceptedType = acceptedOfferTypes.find(matchItem);
        if (foundAcceptedType)
            return __spreadArray(__spreadArray([], acc, true), [foundAcceptedType], false);
        var acceptedOfferTypeInStore = (_a = storeModules.main.existingQuotes.find(matchItem)) === null || _a === void 0 ? void 0 : _a.acceptedOfferType;
        return __spreadArray(__spreadArray([], acc, true), [{ id: currId, acceptedOfferType: acceptedOfferTypeInStore }], false);
    }, []);
};
export var isInvalidOffer = function (item) {
    return !!item.buyoutQuote && !!item.consignmentEstimate && !item.acceptedOfferType;
};
export var getAllSelectedInvalidOffers = function (items) {
    return items
        .map(function (item) { return getExistingQuoteByQuoteId(item); })
        .filter(function (item) { return item; })
        .filter(function (quote) { return isInvalidOffer(quote); });
};
export var determinePriceMetaText = function (item) {
    var consignmentEstimate = item.consignmentEstimate, buyoutQuote = item.buyoutQuote, acceptedOfferType = item.acceptedOfferType;
    var metaText = [];
    var buyoutText = { label: 'Buyout Offer:', value: formatCurrency(Number(buyoutQuote)) };
    var consignmentText = { label: 'Consignment Estimate:', value: formatCurrency(Number(consignmentEstimate)) };
    switch (acceptedOfferType) {
        case AcceptOfferType.buyout:
            metaText.push(buyoutText);
            break;
        case AcceptOfferType.consignment:
            metaText.push(consignmentText);
            break;
    }
    if (isEmpty(metaText)) {
        if (buyoutQuote)
            metaText.push(buyoutText);
        if (consignmentEstimate)
            metaText.push(consignmentText);
    }
    return { metaText: metaText, disabled: false };
};
export var createSelectDataDropoffItems = function (items) {
    return items.map(function (quote) {
        var _a = determinePriceMetaText(quote), metaText = _a.metaText, disabled = _a.disabled;
        return {
            label: "".concat(quote.brand, " - ").concat(quote.name),
            code: quote.id,
            image: quote.thumbnailUrl,
            metaText: metaText,
            disabled: disabled,
        };
    });
};
