var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { storeModules } from 'main/store';
import { timeout } from 'vue2/helpers';
import UnknownImage from 'main/assets/images/unknown.png';
import { extractThumbnails } from 'main/controllers/MechanicalMatcher/helpers';
export var saveCategoriesToStore = function (_a) {
    var data = _a.data;
    storeModules.main.SET_CATEGORIES(data);
    return;
};
export var saveSelectProductToStore = function (data) {
    storeModules.showroomAssistant.SET_SELECTED_STYLE(data);
    return;
};
export var searchCategoriesFromStore = function (_a) {
    var text = _a.text;
    return __awaiter(void 0, void 0, void 0, function () {
        var items;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, timeout(50)];
                case 1:
                    _b.sent();
                    items = storeModules.main.categories
                        .filter(function (_a) {
                        var _b = _a.attributes, isEnabledForQuotes = _b.isEnabledForQuotes, name = _b.name;
                        return isEnabledForQuotes && name;
                    })
                        .filter(function (_a) {
                        var name = _a.attributes.name;
                        return name === null || name === void 0 ? void 0 : name.toLowerCase().includes((text === null || text === void 0 ? void 0 : text.toLowerCase()) || '');
                    });
                    return [2 /*return*/, items.map(function (item) { return (__assign(__assign({}, item), { label: item.attributes.name, code: item.id })); })];
            }
        });
    });
};
export var searchProductNames = function (data) {
    return data.hits.map(function (hit) { return (__assign(__assign({}, hit), { image: hit.product.imageUrl, label: hit.name, code: hit.id })); });
};
export var createColorSelect = function (data) {
    //TODO add image when BE is ready
    var noColorOption = {
        code: 0,
        label: 'Color not Found',
        image: UnknownImage,
    };
    var allColors = data.colors.map(function (color, index) { return (__assign(__assign({}, color), { label: color.name, code: color.id, image: "https://picsum.photos/250/250?random=".concat(index + 1) })); });
    return __spreadArray(__spreadArray([], allColors, true), [noColorOption], false);
};
export var needsToUploadImage = function (userNewQuoteData) {
    var _a;
    return !(userNewQuoteData && userNewQuoteData.brandId && ((_a = userNewQuoteData.productData) === null || _a === void 0 ? void 0 : _a.styleId));
};
export var transformImagePredictUploadRequest = function (image, _a) {
    var brandId = _a.brandId, styleId = _a.styleId;
    return { image: image, styleId: styleId, brandId: Number(brandId) || undefined };
};
export var transformImagePredictUploadResponse = function (uploadResponse) {
    return uploadResponse;
};
export var formatParentMatches = function (matches) {
    return matches.map(function (match) {
        var _a;
        return (__assign(__assign({}, match), { code: match.styleId, label: match.parentName, image: match.products && extractThumbnails((_a = match.products) === null || _a === void 0 ? void 0 : _a.data[0].images)[0] }));
    });
};
export var formatSubStyleMatches = function (match) {
    var _a;
    return (_a = match.subStyles) === null || _a === void 0 ? void 0 : _a.map(function (style) {
        var _a;
        return (__assign(__assign({}, style), { code: style.styleId, label: style.styleName, image: style.products && extractThumbnails((_a = style.products) === null || _a === void 0 ? void 0 : _a.data[0].images)[0] }));
    });
};
