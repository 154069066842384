export var AlertBoxTypes;
(function (AlertBoxTypes) {
    AlertBoxTypes["error"] = "error";
    AlertBoxTypes["secondaryError"] = "secondaryError";
    AlertBoxTypes["info"] = "info";
    AlertBoxTypes["infoBlack"] = "infoBlack";
    AlertBoxTypes["infoBlue"] = "infoBlue";
    AlertBoxTypes["warning"] = "warning";
    AlertBoxTypes["infoWhite"] = "infoWhite";
})(AlertBoxTypes || (AlertBoxTypes = {}));
