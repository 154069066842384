var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { storeModules, MessageExecuteActionType, MessageType, } from 'main/store';
import { getMessageById } from './findMessages';
import { toggleLoadingProp } from './changeMessages';
import { timeout } from 'vue2/helpers';
import { getNextShowroomAssistantMessage } from '../createMessages';
//TODO adjust in prod
var TEXT_TIMER_PER_CHAR = 25;
export var MINIMUM_TIME_BETWEEN_MESSAGE = 400;
export var MAXIMUM_TIME_BETWEEN_MESSAGE = 2000;
export var SCROLL_DURATION = 400;
export var TIME_BEFORE_SHOWING_LOADER = MINIMUM_TIME_BETWEEN_MESSAGE;
export var createMessage = function (storeMessageData) { return __awaiter(void 0, void 0, void 0, function () {
    var buildActionConfig, buildAction, builtMessage;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                buildActionConfig = (_a = {},
                    _a[MessageType.Loading] = function () { return createLoadingMessage(storeMessageData); },
                    _a[MessageType.Text] = function () { return createTextMessage(storeMessageData); },
                    _a[MessageType.Title] = function () { return createTitleMessage(storeMessageData); },
                    _a[MessageType.Image] = function () { return createTextMessage(storeMessageData); },
                    _a[MessageType.Chart] = function () { return createTextMessage(storeMessageData); },
                    _a[MessageType.Link] = function () { return createTextMessage(storeMessageData); },
                    _a.default = function () { return createDefaultMessage(storeMessageData); },
                    _a);
                buildAction = buildActionConfig[storeMessageData.type] || buildActionConfig.default;
                return [4 /*yield*/, buildAction(storeMessageData)];
            case 1:
                builtMessage = _b.sent();
                return [2 /*return*/, builtMessage];
        }
    });
}); };
var createDefaultMessage = function (storeMessageData) { return __awaiter(void 0, void 0, void 0, function () {
    var executeActionType;
    return __generator(this, function (_a) {
        executeActionType = storeMessageData.timer ? MessageExecuteActionType.Timer : MessageExecuteActionType.Input;
        return [2 /*return*/, __assign(__assign({}, storeMessageData), { executeActionType: executeActionType })];
    });
}); };
var createLoadingMessage = function (storeMessageData) { return __awaiter(void 0, void 0, void 0, function () {
    var loaderDefault, executeActionType;
    var _a;
    return __generator(this, function (_b) {
        loaderDefault = {
            showLoader: true,
            time: MAXIMUM_TIME_BETWEEN_MESSAGE,
            delayLoader: 500,
        };
        executeActionType = ((_a = storeMessageData.props) === null || _a === void 0 ? void 0 : _a.apiRequest)
            ? MessageExecuteActionType.Input
            : MessageExecuteActionType.Timer;
        return [2 /*return*/, __assign(__assign({}, storeMessageData), { executeActionType: executeActionType, timer: storeMessageData.timer || loaderDefault })];
    });
}); };
var createTextMessage = function (storeMessageData) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, __assign(__assign({}, storeMessageData), { executeActionType: MessageExecuteActionType.Timer, timer: determineTextTimer(storeMessageData) })];
    });
}); };
var createTitleMessage = function (storeMessageData) { return __awaiter(void 0, void 0, void 0, function () {
    var titleLoaderDefault;
    return __generator(this, function (_a) {
        titleLoaderDefault = {
            delayLoader: 500,
            time: 1500,
            showLoader: true,
        };
        return [2 /*return*/, __assign(__assign({}, storeMessageData), { executeActionType: MessageExecuteActionType.Timer, timer: storeMessageData.timer || titleLoaderDefault })];
    });
}); };
var determineTextTimer = function (storeMessageData) {
    var text = storeMessageData.props.text || '';
    var timerByText = text.length * TEXT_TIMER_PER_CHAR;
    if (timerByText < MINIMUM_TIME_BETWEEN_MESSAGE) {
        return { time: MINIMUM_TIME_BETWEEN_MESSAGE };
    }
    if (timerByText > MAXIMUM_TIME_BETWEEN_MESSAGE) {
        return { time: MAXIMUM_TIME_BETWEEN_MESSAGE };
    }
    return { time: timerByText };
};
export var timeBeforeShowingLoader = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, timeout(TIME_BEFORE_SHOWING_LOADER)];
            case 1:
                _a.sent();
                return [2 /*return*/, true];
        }
    });
}); };
export var getStartingMessage = function (messages) { return __awaiter(void 0, void 0, void 0, function () {
    var storeMessages;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                storeMessages = messages || storeModules.showroomAssistant.messages;
                return [4 /*yield*/, createMessage(storeMessages[0])];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getNextMessage = function (_a) {
    var nextMessageId = _a.nextMessageId, currentMessageId = _a.currentMessageId, data = _a.data;
    return __awaiter(void 0, void 0, void 0, function () {
        var nextMessage, newMessages, builtMessage;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    nextMessage = nextMessageId && getMessageById(nextMessageId);
                    if (!nextMessage) return [3 /*break*/, 2];
                    return [4 /*yield*/, createMessage(nextMessage)];
                case 1: return [2 /*return*/, _b.sent()];
                case 2:
                    toggleLoadingProp(currentMessageId);
                    return [4 /*yield*/, getNextShowroomAssistantMessage({ currentMessageId: currentMessageId, data: data })];
                case 3:
                    newMessages = _b.sent();
                    storeModules.showroomAssistant.ADD_MESSAGES(newMessages);
                    return [4 /*yield*/, getStartingMessage(newMessages)];
                case 4:
                    builtMessage = _b.sent();
                    toggleLoadingProp(currentMessageId);
                    return [2 /*return*/, builtMessage];
            }
        });
    });
};
