var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { storeModules } from 'main/store';
import { createAssistantMessage, createLoadingMessage, createUserMessage, resetMessageIds, addressToStoreFormat, addressToSingleLine, } from '../..';
import { message_confirmOrder } from './dropoffMessages';
import pluralize from 'pluralize';
import { message_choosePayment, message_choosePhone } from './dropoffSelectMessages';
export var message_confirm_NumberOfDropoffItems = function (_a, overrideNextId) {
    var selectedValue = _a.selectedValue;
    storeModules.showroomAssistant.UPDATE_USER_DROPOFF_DATA({
        dropoffItems: selectedValue.map(function (_a) {
            var code = _a.code;
            return code;
        }),
    });
    var numberOfSelected = selectedValue.length;
    var messages = [
        createAssistantMessage({
            text: "You selected ".concat(pluralize('item', numberOfSelected, true), " you are dropping off today."),
            overrideNextId: overrideNextId,
        }),
    ];
    resetMessageIds();
    return messages;
};
export var message_confirm_Address = function (_a) {
    var selectedValue = _a.selectedValue, textMultiple = _a.textMultiple;
    var addressFormattedForStore = addressToStoreFormat(selectedValue || textMultiple);
    storeModules.showroomAssistant.UPDATE_USER_DROPOFF_DATA({ addressData: addressFormattedForStore });
    var paymentMessages = message_choosePayment();
    var messages = __spreadArray([
        createUserMessage({
            text: "My address is ".concat(addressToSingleLine(selectedValue || textMultiple)),
            overrideNextId: paymentMessages[0].id,
        })
    ], paymentMessages, true);
    resetMessageIds();
    return messages;
};
export var message_confirm_PaymentMethod = function (props) {
    var _a;
    var textMultiple = (props === null || props === void 0 ? void 0 : props.textMultiple) || null;
    storeModules.showroomAssistant.UPDATE_USER_DROPOFF_DATA({
        paymentMethod: {
            paymentMethod: (_a = storeModules.showroomAssistant.userDropoffData.paymentMethod) === null || _a === void 0 ? void 0 : _a.paymentMethod,
            fields: textMultiple,
        },
    });
    var choosePhoneMessages = message_choosePhone();
    var messages = __spreadArray([createLoadingMessage({ overrideNextId: choosePhoneMessages[0].id })], choosePhoneMessages, true);
    resetMessageIds();
    return messages;
};
export var message_confirm_Phone = function (_a) {
    var phoneNumber = _a.phoneNumber, countryCode = _a.countryCode;
    storeModules.showroomAssistant.UPDATE_USER_DROPOFF_DATA({
        phone: {
            phoneNumber: phoneNumber,
            countryCode: countryCode,
        },
    });
    var confirmOrderMessages = message_confirmOrder();
    var messages = __spreadArray([], confirmOrderMessages, true);
    resetMessageIds();
    return messages;
};
