export var MessageType;
(function (MessageType) {
    MessageType["None"] = "None";
    MessageType["Text"] = "Text";
    MessageType["TextChoice"] = "TextChoice";
    MessageType["Quiz"] = "Quiz";
    MessageType["Video"] = "Video";
    MessageType["Loading"] = "Loading";
    MessageType["Title"] = "Title";
    MessageType["Image"] = "Image";
    MessageType["InputText"] = "InputText";
    MessageType["InputTextMultiple"] = "InputTextMultiple";
    MessageType["InputTextArea"] = "InputTextArea";
    MessageType["InputSelect"] = "InputSelect";
    MessageType["InputSearchSelect"] = "InputSearchSelect";
    MessageType["InputSignature"] = "InputSignature";
    MessageType["InputUpload"] = "InputUpload";
    MessageType["InputPhone"] = "InputPhone";
    MessageType["Confirmation"] = "Confirmation";
    MessageType["Chart"] = "Chart";
    MessageType["Link"] = "Link";
    //Decide if these are needed
    //InputNumber
})(MessageType || (MessageType = {}));
export var MessageSender;
(function (MessageSender) {
    MessageSender["Self"] = "Self";
    MessageSender["Assistant"] = "Assistant";
})(MessageSender || (MessageSender = {}));
export var MessageAnimationTypes;
(function (MessageAnimationTypes) {
    MessageAnimationTypes["confetti"] = "confetti";
})(MessageAnimationTypes || (MessageAnimationTypes = {}));
export var MessageExecuteActionType;
(function (MessageExecuteActionType) {
    MessageExecuteActionType["Timer"] = "Timer";
    MessageExecuteActionType["Input"] = "Input";
})(MessageExecuteActionType || (MessageExecuteActionType = {}));
export var QuizTypes;
(function (QuizTypes) {
    QuizTypes["TextOptions"] = "TextOptions";
    QuizTypes["ImageOptionsHorizontal"] = "ImageOptionsHorizontal";
})(QuizTypes || (QuizTypes = {}));
