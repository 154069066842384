var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { getPendingActiveItems } from 'main/api';
import { storeModules } from 'main/store';
import { createLoadingMessage, resetMessageIds } from '../../createMessageHelpers';
import { pendingActiveItemsResponseTransformer } from '../apiHelpers';
export var refreshUserItems = function (nextMessageKey) {
    var messages = [
        createLoadingMessage({
            apiRequest: {
                apiUrl: function () { var _a; return getPendingActiveItems({ warehouseId: (_a = storeModules.main.warehouse) === null || _a === void 0 ? void 0 : _a.id }); },
                responseTransformer: function (data) { return pendingActiveItemsResponseTransformer(data); },
            },
            overrideNextId: nextMessageKey,
        }),
    ];
    resetMessageIds();
    return __spreadArray([], messages, true);
};
