var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import findIndex from 'lodash/findIndex';
import isEqual from 'lodash/isEqual';
import first from 'lodash/first';
import last from 'lodash/last';
import differenceBy from 'lodash/differenceBy';
import isObject from 'lodash/isObject';
import differenceWith from 'lodash/differenceWith';
import toPairs from 'lodash/toPairs';
import fromPairs from 'lodash/fromPairs';
var navigateArray = function (array, currentItem, next, loop) {
    if (next === void 0) { next = true; }
    if (loop === void 0) { loop = true; }
    var useLastElement = next && !loop && isEqual(last(array), currentItem);
    var index = findIndex(array, function (item) { return isEqual(item, currentItem); });
    var nextIndex = next ? index + 1 : index - 1;
    if (useLastElement) {
        return last(array);
    }
    else if (nextIndex < 0) {
        nextIndex = array.length - 1;
    }
    else if (nextIndex >= array.length) {
        nextIndex = 0;
    }
    return array[nextIndex];
};
export var nextInArray = function (array, currentItem, loop) {
    if (loop === void 0) { loop = true; }
    var useLastElement = !loop && isEqual(last(array), currentItem);
    return useLastElement ? last(array) : navigateArray(array, currentItem, true, loop);
};
export var previousInArray = function (array, currentItem, loop) {
    if (loop === void 0) { loop = true; }
    var useFirstElement = !loop && isEqual(first(array), currentItem);
    return useFirstElement ? first(array) : navigateArray(array, currentItem, false, loop);
};
export var toggleItemInArray = function (array, item) {
    var newArray = __spreadArray([], array, true);
    var index = newArray.indexOf(item);
    if (index === -1) {
        newArray.push(item);
    }
    else {
        newArray.splice(index, 1);
    }
    return newArray;
};
export var toggleItemsInArray = function (array, items) {
    var newArray = __spreadArray([], array, true);
    items.forEach(function (item) {
        newArray = toggleItemInArray(array, item);
    });
    return newArray;
};
export var removeItemFromArray = function (array, item) {
    var newArray = __spreadArray([], array, true);
    var index = findIndex(array, function (currentItem) { return isEqual(item, currentItem); });
    if (index !== -1) {
        newArray.splice(index, 1);
    }
    return newArray;
};
export var addItemToArray = function (array, item) {
    var newArray = __spreadArray(__spreadArray([], array, true), [item], false);
    return newArray;
};
export var replaceItemInArray = function (array, item, newItem) {
    var newArray = __spreadArray([], array, true);
    var index = findIndex(array, function (currentItem) { return isEqual(item, currentItem); });
    if (index !== -1) {
        newArray[index] = newItem;
    }
    return newArray;
};
export var replaceItemInArrayWithId = function (array, item, newItem) {
    var newArray = __spreadArray([], array, true);
    var index = findIndex(array, function (currentItem) { return isEqual(item.id, currentItem.id); });
    if (index !== -1) {
        newArray[index] = newItem;
    }
    return newArray;
};
export var isArrayOfNumbers = function (arr) {
    return arr.every(function (item) { return typeof item === 'number'; });
};
export var isArrayOfObjects = function (arr) {
    return arr.every(function (item) { return isObject(item); });
};
export var isInArray = function (arr, item) {
    return arr.find(function (currentItem) { return isEqual(item, currentItem); }) !== undefined;
};
export var getDifferencesInArray = function (items, newArray, keyToFilter) {
    if (keyToFilter === void 0) { keyToFilter = 'id'; }
    var firstArray = items.length > newArray.length ? items : newArray;
    var secondArray = items.length > newArray.length ? newArray : items;
    return differenceBy(firstArray, secondArray, keyToFilter);
};
export var hasArrayOrderChanged = function (items, newArray, keyToFilter) {
    if (keyToFilter === void 0) { keyToFilter = 'id'; }
    var firstArray = items.map(function (item) { return item[keyToFilter]; });
    var secondArray = newArray.map(function (item) { return item[keyToFilter]; });
    return !isEqual(firstArray, secondArray);
};
export var getAllItemsInBetweenTwo = function (items, firstItem, lastItem) {
    var newAray = __spreadArray([], items, true);
    var indexOfFirst = newAray.indexOf(firstItem);
    var indexOfSecond = newAray.indexOf(lastItem) + 1;
    var result = newAray.slice(indexOfFirst, indexOfSecond);
    return result;
};
export var getDifferenceBetweenTwoObjects = function (object1, object2) {
    return fromPairs(differenceWith(toPairs(object2), toPairs(object1), isEqual));
};
export var chunkArray = function (arr, chunkSize) {
    var result = [];
    for (var i = 0; i < arr.length; i += chunkSize) {
        var chunk = arr.slice(i, i + chunkSize);
        result.push(chunk);
    }
    return result;
};
