import { RouteNames } from './routeNames';
export var mechanicalMatcherRoutes = [
    {
        path: "/",
        name: RouteNames.Index,
        component: function () { return import('../views/Index.vue'); },
        meta: {
            title: 'LAPIS',
        },
    },
    {
        path: "/".concat(RouteNames.Login),
        name: RouteNames.Login,
        component: function () { return import('../views/Login.vue'); },
        meta: {
            title: 'Login',
        },
    },
    {
        path: "/".concat(RouteNames.Logout),
        name: RouteNames.Logout,
        component: function () { return import('../views/Logout.vue'); },
        meta: {
            title: 'Logout',
        },
    },
];
export default mechanicalMatcherRoutes;
