import isEqual from 'lodash/isEqual';
export var DATA_ITEM_KEY = 'data-item-key';
var DATA_INDEX = 'data-index';
export var itemOrderChanged = function (items, currentItems) {
    return !isEqual(items, currentItems);
};
export var extractDataId = function (gridItem, id) {
    if (id === void 0) { id = DATA_ITEM_KEY; }
    return gridItem === null || gridItem === void 0 ? void 0 : gridItem.getAttribute(id);
};
export var extractDataIdFromGridItem = function (gridItem) {
    return extractDataId(gridItem === null || gridItem === void 0 ? void 0 : gridItem.getElement());
};
export var extractDataIndexFromGridItem = function (gridItem) {
    return extractDataId(gridItem === null || gridItem === void 0 ? void 0 : gridItem.getElement(), DATA_INDEX);
};
