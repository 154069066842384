export var isMobileDevice = function () {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};
export var openNewTab = function (url) {
    return window.open(url, '_blank');
};
export var redirectToUrl = function (url) {
    window.location.href = url;
};
export var refreshPage = function () { return location.reload(); };
