var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { generateQrCode } from 'main/api';
import { storeModules, PaymentMethods, MessageAnimationTypes } from 'main/store';
import { createAssistantMessage, createNoneMessage, createChoiceMessage, createLoadingMessage, createInputTextMultiple, resetMessageIds, createImageMessage, createConfirmationMessage, } from '../..';
import { DropoffKeys } from './config';
import isEmpty from 'lodash/isEmpty';
import { message_chooseAddress, message_chooseItems, message_choosePayment, message_choosePhone, message_signToDropoff, message_chooseAcceptedOfferTypes, } from './dropoffSelectMessages';
import { message_confirm_NumberOfDropoffItems } from './dropoffConfirmMessages';
import { createLinkMessage } from '../../createMessageHelpers';
import { overrideUserDataWithUpdatedData, transformDropoffToQRCode } from '../apiHelpers';
import { refreshUserItems } from '../shared';
import { initialShowroomDropoffMessages } from './initialMessages';
import { initialShowroomPickupMessages } from '../pickup';
export var messages_loadedPendingActiveItems = function () {
    if (isEmpty(storeModules.main.existingQuotes)) {
        return [
            createAssistantMessage({
                text: 'You currently do not have any items pending dropoff. Please submit your items on our website and try again here',
            }),
        ];
    }
    return message_chooseItems();
};
export var message_dropoffItems = function (_a) {
    var selectedValue = _a.selectedValue;
    storeModules.showroomAssistant.UPDATE_USER_DROPOFF_DATA({
        dropoffItems: selectedValue.map(function (_a) {
            var code = _a.code;
            return code;
        }),
    });
    var numberOfSelected = selectedValue.length;
    if (!numberOfSelected) {
        return message_dropoffItems_no();
    }
    var addressMessages = message_chooseAddress();
    var acceptedDropoffMessages = message_chooseAcceptedOfferTypes(addressMessages[0].id);
    var nextMessageAfterConfirm = acceptedDropoffMessages[0] || addressMessages[0];
    var confirmMessages = message_confirm_NumberOfDropoffItems({ selectedValue: selectedValue }, nextMessageAfterConfirm.id);
    var messages = __spreadArray(__spreadArray(__spreadArray([], confirmMessages, true), acceptedDropoffMessages, true), addressMessages, true);
    resetMessageIds();
    return messages;
};
var message_dropoffItems_no = function () {
    var dropoffMessages = initialShowroomDropoffMessages();
    var messages = __spreadArray([
        createAssistantMessage({ text: "No items selected" }),
        createAssistantMessage({ text: 'What would you like to do today?' }),
        //TODO replace with initial messages
        createChoiceMessage({
            choices: [
                {
                    text: 'Drop off item',
                    nextMessageId: dropoffMessages[0].id,
                },
                {
                    text: {
                        text: 'Start a new quote',
                        url: 'https://sell.fashionphile.com/sell-your-bag',
                    },
                },
            ],
        }),
        createNoneMessage()
    ], dropoffMessages, true);
    resetMessageIds();
    return messages;
};
export var message_paymethodMethodInput = function (_a) {
    var _b;
    var selectedValue = _a.selectedValue;
    storeModules.showroomAssistant.UPDATE_USER_DROPOFF_DATA({
        paymentMethod: { paymentMethod: selectedValue.code },
    });
    var createChosenFieldsMessage = function () {
        var _a;
        var chosenFields = (_a = storeModules.main.paymentMethods.find(function (method) { return method.value === selectedValue.code; })) === null || _a === void 0 ? void 0 : _a.fields;
        var inputs = (chosenFields === null || chosenFields === void 0 ? void 0 : chosenFields.map(function (_a) {
            var name = _a.name, value = _a.value, type = _a.type, options = _a.options;
            return ({
                label: name,
                key: value,
                type: type,
                options: options,
            });
        })) || [];
        return createInputTextMultiple({ inputs: inputs, overrideNextId: DropoffKeys.confirmPaymentMethodChange });
    };
    if ([PaymentMethods.ACH, PaymentMethods.PAYPAL].includes(selectedValue.code)) {
        var chosenMessage = createAssistantMessage({ text: "You selected ".concat(selectedValue.label) });
        return [chosenMessage, createChosenFieldsMessage()];
    }
    else if (selectedValue.code === PaymentMethods.NEIMAN_MARCUS_GIFT_CARD) {
        return [
            createAssistantMessage({
                text: "You selected Neiman Marcus gift card (+10% Bonus). Your gift card will be emailed to ".concat(((_b = storeModules.user.userData) === null || _b === void 0 ? void 0 : _b.emailAddress) || 'your email', " once your item is authenticated in 3-5 business days."),
            }),
            createChosenFieldsMessage(),
        ];
    }
    else if (selectedValue.code === PaymentMethods.STORE_CREDIT) {
        return [
            createAssistantMessage({
                text: "You selected Store Credit. We will issue your credit to your FASHIONPHILE Account once your item is authenticated.",
                overrideNextId: DropoffKeys.confirmPaymentMethodChange,
            }),
        ];
    }
    else if (selectedValue.code === PaymentMethods.CHECK) {
        return [
            createAssistantMessage({
                text: "You selected ".concat(selectedValue.label, ". We will issue your check and mail it to you once your item is authenticated at one of our Authentication Centers."),
                overrideNextId: DropoffKeys.confirmPaymentMethodChange,
            }),
        ];
    }
    return __spreadArray([
        createAssistantMessage({ text: 'Invalid payment method selected' }),
        createLoadingMessage()
    ], message_choosePayment(), true);
};
export var message_confirmSignature = function (_a) {
    var image = _a.image;
    storeModules.showroomAssistant.UPDATE_USER_DROPOFF_DATA({ signature: image });
    var messages = [
        createLoadingMessage({
            apiRequest: {
                apiUrl: function () {
                    var _a;
                    return generateQrCode(transformDropoffToQRCode(storeModules.showroomAssistant.userDropoffData, (_a = storeModules.main.warehouse) === null || _a === void 0 ? void 0 : _a.id));
                },
                responseTransformer: function (data) {
                    overrideUserDataWithUpdatedData(storeModules.showroomAssistant.userDropoffData);
                    storeModules.showroomAssistant.SET_DROPOFF_CODE(data);
                },
            },
            overrideNextId: DropoffKeys.hasGeneratedQRCode,
        }),
    ];
    resetMessageIds();
    return messages;
};
export var message_hasGeneratedQRCode = function () {
    var refreshUserMessages = refreshUserItems(DropoffKeys.checkForPickUpItems);
    var messages = __spreadArray([
        createAssistantMessage({
            text: 'Your dropoff request has been received!',
            animation: {
                type: MessageAnimationTypes.confetti,
            },
        }),
        createLoadingMessage(),
        createAssistantMessage({
            text: 'Payment is typically sent 2-4 days after your item is authenticated.',
        }),
        createImageMessage({
            imageSrc: String(storeModules.showroomAssistant.dropoffCode),
            useBgImage: false,
            isSVG: true,
            text: "Please show this QR code and bring items with you to the front desk.",
            overrideNextId: refreshUserMessages[0].id,
        })
    ], refreshUserMessages, true);
    resetMessageIds();
    return messages;
};
export var message_confirmOrder = function () {
    var doubleCheckedMessages = messages_checkThatOrderIsGood();
    if (!isEmpty(doubleCheckedMessages))
        return doubleCheckedMessages;
    var signDropoffMessages = message_signToDropoff();
    var messages = __spreadArray([
        createAssistantMessage({
            text: "Let's confirm your dropoff",
        }),
        createConfirmationMessage({ overrideNextId: signDropoffMessages[0].id })
    ], signDropoffMessages, true);
    resetMessageIds();
    return messages;
};
export var message_checkPickUpItems = function () {
    var messages = [];
    if (!isEmpty(storeModules.main.pickUpItems)) {
        var pickUpItemMessages = initialShowroomPickupMessages();
        messages = __spreadArray(__spreadArray(__spreadArray([], messages, true), [
            createChoiceMessage({
                choices: [{ text: 'Continue' }],
            }),
            createAssistantMessage({
                text: 'Looks like you have some items to pick up today. Would you like to do that now?',
            }),
            createChoiceMessage({
                choices: [{ text: "No" }, { text: "Yes, pick up items", nextMessageId: pickUpItemMessages[0].id }],
            })
        ], false), pickUpItemMessages, true);
    }
    resetMessageIds();
    return __spreadArray([], messages, true);
};
export var message_endDropoff = function () {
    var _a;
    return [
        createAssistantMessage({ text: 'Thanks for using our Showroom Assistant! We hope to see you again soon. ' }),
        createLinkMessage({
            url: ((_a = storeModules.main.warehouse) === null || _a === void 0 ? void 0 : _a.shopURL) || 'https://www.fashionphile.com',
            buttonText: 'Start Shopping',
            useLinkIcon: true,
        }),
        createNoneMessage(),
    ];
};
var messages_checkThatOrderIsGood = function () {
    //items
    if (!storeModules.showroomAssistant.userDropoffData.dropoffItems.length) {
        return message_chooseItems();
    }
    //address
    if (!storeModules.showroomAssistant.userDropoffData.addressData) {
        return message_chooseAddress();
    }
    //payment method
    if (!storeModules.showroomAssistant.userDropoffData.paymentMethod) {
        return message_choosePayment();
    }
    //phone
    if (!storeModules.showroomAssistant.userDropoffData.phone) {
        return message_choosePhone();
    }
    return [];
};
