import { inputHasText } from 'vue2/helpers';
import isEmpty from 'lodash/isEmpty';
export var createAddressString = function (address, useCountry) {
    if (useCountry === void 0) { useCountry = false; }
    var streetAddress = [address.address_1, address.address_2].filter(inputHasText).join(' ');
    var streetAndCity = [streetAddress, address.city].filter(inputHasText).join(', ');
    var stateAndPostalCode = [address.state, address.postal_code].filter(inputHasText).join(' ');
    var addressString = [streetAndCity, stateAndPostalCode].filter(inputHasText).join(', ');
    var adddressWithCountry = "".concat(addressString, " ").concat(useCountry ? address.country : '');
    return adddressWithCountry.trim();
};
export var formatAddressStringForStore = function (address) {
    return {
        address_1: address.address_1,
        address_2: address.address_2,
        city: address.city,
        state: address.state,
        postal_code: address.postal_code,
    };
};
export var hasAddressOnFile = function (sellerAddress) {
    return !isEmpty(sellerAddress) && inputHasText(createAddressString(sellerAddress));
};
export var hasPhoneOnFile = function (sellerAddress) {
    return !isEmpty(sellerAddress) && inputHasText(extractPhoneFromSellerAddress(sellerAddress));
};
export var extractPhoneFromSellerAddress = function (sellerAddress) {
    return (sellerAddress === null || sellerAddress === void 0 ? void 0 : sellerAddress.phone) || (sellerAddress === null || sellerAddress === void 0 ? void 0 : sellerAddress.raw_phone);
};
export var hasPaymentOnFile = function (userObject) {
    var _a;
    return !isEmpty(userObject) && inputHasText((_a = userObject === null || userObject === void 0 ? void 0 : userObject.sellerPayment) === null || _a === void 0 ? void 0 : _a.method);
};
