var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component } from 'vue-property-decorator';
import { getElementPosition } from '../helpers';
/*
HOW TO USE THIS MIXIN

Execute dragMenuInit() and you should be good to go
*/
export var dragClass = 'draggable';
var Draggable = /** @class */ (function (_super) {
    __extends(Draggable, _super);
    function Draggable() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.newPosX = 0;
        _this.newPosY = 0;
        _this.oldPosX = 0;
        _this.oldPosY = 0;
        _this.originalPosition = null;
        _this.originalPostionType = null;
        _this.dragClass = dragClass;
        _this.isInitialized = false;
        return _this;
    }
    Draggable.prototype.$_draggable_elementMoveMouseDown = function (e) {
        var isDragElement = e.target === this.dragElement;
        var isDraggable = e.target.classList.contains(dragClass);
        if (isDragElement || isDraggable) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            this.oldPosX = e.clientX;
            this.oldPosY = e.clientY;
            window.onmouseup = this.$_draggable_elementMoveMouseUp;
            window.onmousemove = this.$_draggable_elementDrag;
            this.originalPosition = getElementPosition(this.dragElement);
            this.$_draggable_handleOffset(true);
        }
    };
    Draggable.prototype.$_draggable_elementDrag = function (e, saveOldPos) {
        if (saveOldPos === void 0) { saveOldPos = true; }
        e = e || window.event;
        e.preventDefault();
        //ensure that mouse is being held down (sometimes the mouseup event is missed when you are dragging on the edge very quickly)
        if (!e.buttons) {
            this.$_draggable_elementMoveMouseUp();
            return;
        }
        this.newPosX = this.oldPosX - e.clientX;
        this.newPosY = this.oldPosY - e.clientY;
        this.oldPosX = saveOldPos ? e.clientX : this.oldPosX;
        this.oldPosY = saveOldPos ? e.clientY : this.oldPosY;
        // set the element's new position:
        this.dragElement.style.top = "".concat(this.dragElement.offsetTop - this.newPosY, "px");
        this.dragElement.style.left = "".concat(this.dragElement.offsetLeft - this.newPosX, "px");
    };
    Draggable.prototype.$_draggable_elementMoveMouseUp = function () {
        // stop moving when mouse button is released
        window.onmouseup = null;
        window.onmousemove = null;
        this.$_draggable_handleOffset(false);
    };
    Draggable.prototype.$_draggable_handleOffset = function (initialize) {
        var dragOffset = 100;
        // set intial position of drag element
        this.dragElement.style.top = "".concat(this.dragElement.offsetTop, "px");
        this.dragElement.style.left = "".concat(this.dragElement.offsetLeft, "px");
        this.dragElement.style.right = "initial";
        this.dragElement.style.bottom = "initial";
        //set large padding so quick mouse movements are always detected
        this.dragElement.style.padding = initialize ? "".concat(dragOffset, "px") : '';
        //create fake move event to counter act padding
        var clientXOffset = initialize ? -dragOffset : dragOffset;
        var clientYOffset = initialize ? -dragOffset : dragOffset;
        var mouseEvent = new MouseEvent('onmousemove', {
            clientX: this.oldPosX + clientXOffset,
            clientY: this.oldPosY + clientYOffset,
            buttons: 1,
        });
        this.$_draggable_elementDrag(mouseEvent, false);
    };
    Draggable.prototype.$_resetDrag = function () {
        if (this.dragElement) {
            this.dragElement.style.position = this.originalPostionType || '';
            this.dragElement.style.cursor = '';
        }
        this.isInitialized = false;
    };
    Draggable.prototype.$_dragInit = function (element) {
        var topPos = element.offsetTop;
        var leftPos = element.offsetLeft;
        this.originalPostionType = element.style.position;
        this.dragElement = element;
        this.dragElement.style.cursor = 'move';
        this.dragElement.style.position = 'fixed';
        this.dragElement.style.top = "".concat(topPos, "px");
        this.dragElement.style.left = "".concat(leftPos, "px");
        this.dragElement.style.right = "initial";
        this.dragElement.style.bottom = "initial";
        this.dragElement.onmousedown = this.$_draggable_elementMoveMouseDown;
        this.originalPosition = getElementPosition(this.dragElement);
        this.isInitialized = true;
    };
    Draggable = __decorate([
        Component
    ], Draggable);
    return Draggable;
}(Vue));
export default Draggable;
