var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import isElement from 'lodash/isElement';
import isArray from 'lodash/isArray';
import floor from 'lodash/floor';
export var extractElement = function (element) {
    var _a;
    var returnElement;
    if (isElement(element)) {
        returnElement = element;
    }
    if (element && isArray(element)) {
        if (isElement(element[0])) {
            returnElement = element[0];
        }
        if (!returnElement) {
            returnElement = (_a = element[0]) === null || _a === void 0 ? void 0 : _a.$el;
        }
    }
    return returnElement;
};
export var getElementPosition = function (element) {
    return { top: element.style.top, left: element.style.left };
};
export var getCoordinates = function (elem) {
    var box = elem.getBoundingClientRect();
    var body = document.body;
    var docEl = document.documentElement;
    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;
    var top = box.top + scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;
    return { top: top, left: left, height: box.height, width: box.width };
};
export var createDownload = function (href, name) {
    var link = document.createElement('a');
    link.download = name;
    link.href = href;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
export var toDataURL = function (url) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, fetch(url)
                .then(function (response) { return response.blob(); })
                .then(function (blob) {
                return new Promise(function (resolve, reject) {
                    var reader = new FileReader();
                    reader.onloadend = function () { return resolve(reader.result); };
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                });
            })];
    });
}); };
export var doesCSSClassExists = function (element, className) {
    return element.classList.contains(className);
};
export var getMaxWidthForElements = function (element, minImageWidth, gutterWidth) {
    if (!element)
        return minImageWidth;
    var dynamicOffset = 1;
    var offsetWidth = element.offsetWidth;
    var gridItemSize = minImageWidth + (gutterWidth + dynamicOffset) * 2;
    var maxOnTheRow = floor(offsetWidth / gridItemSize);
    var leftOver = offsetWidth % (maxOnTheRow * gridItemSize);
    var additionalSize = leftOver / maxOnTheRow;
    return minImageWidth + additionalSize;
};
export var getAbsoluteHeight = function (el) {
    var styles = window.getComputedStyle(el);
    var margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);
    return Math.ceil(el.offsetHeight + margin);
};
export var checkForImageLoad = function (imageUrl, callback) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve) {
                var image = new Image();
                image.addEventListener('load', function () {
                    callback && callback(image);
                    resolve(true);
                });
                image.src = imageUrl;
            })];
    });
}); };
export var imageURLToDataURL = function (src) { return __awaiter(void 0, void 0, void 0, function () {
    var blob_1, dataUrl, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, fetch(src).then(function (r) { return r.blob(); })];
            case 1:
                blob_1 = _a.sent();
                return [4 /*yield*/, new Promise(function (resolve) {
                        var reader = new FileReader();
                        reader.onload = function () { return resolve(reader.result); };
                        reader.readAsDataURL(blob_1);
                    })];
            case 2:
                dataUrl = _a.sent();
                return [2 /*return*/, dataUrl];
            case 3:
                error_1 = _a.sent();
                console.error(error_1);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var classIsFoundInParent = function (target, className) {
    var _a;
    var collectParentNodes = function (t, collection) {
        if (collection === void 0) { collection = []; }
        var parent = t.parentElement;
        var hasParent = !!(parent === null || parent === void 0 ? void 0 : parent.parentElement);
        if (!hasParent)
            return collection;
        return collectParentNodes(parent, __spreadArray(__spreadArray([], collection, true), [parent], false));
    };
    return (_a = collectParentNodes(target)) === null || _a === void 0 ? void 0 : _a.some(function (domNode) { var _a; return (_a = domNode === null || domNode === void 0 ? void 0 : domNode.classList) === null || _a === void 0 ? void 0 : _a.contains(className); });
};
