export var timeout = function (ms) {
    if (ms === void 0) { ms = 0; }
    return new Promise(function (resolve) { return setTimeout(resolve, ms); });
};
export var interval = function (ms, callback, performImmediate) {
    if (ms === void 0) { ms = 1000; }
    if (performImmediate === void 0) { performImmediate = false; }
    performImmediate && callback();
    return setInterval(function () { return callback(); }, ms);
};
