export var StilettoLoadingIcons;
(function (StilettoLoadingIcons) {
    StilettoLoadingIcons["deleteImages"] = "deleteImages";
    StilettoLoadingIcons["saveForLater"] = "saveForLater";
    StilettoLoadingIcons["completeProduct"] = "completeProduct";
    StilettoLoadingIcons["splitImages"] = "splitImages";
    StilettoLoadingIcons["downloadImages"] = "downloadImages";
    StilettoLoadingIcons["editDetails"] = "editDetails";
    StilettoLoadingIcons["swapImages"] = "swapImages";
    StilettoLoadingIcons["unselectAllImages"] = "unselectAllImages";
    StilettoLoadingIcons["swapAllImages"] = "swapAllImages";
    StilettoLoadingIcons["editGradient"] = "editGradient";
    StilettoLoadingIcons["editCrop"] = "editCrop";
    StilettoLoadingIcons["editWhiteBalance"] = "editWhiteBalance";
    StilettoLoadingIcons["editEraser"] = "editEraser";
    StilettoLoadingIcons["makeFeaturedImage"] = "makeFeaturedImage";
    StilettoLoadingIcons["uploadNewImages"] = "uploadNewImages";
    StilettoLoadingIcons["flagProduct"] = "flagProduct";
})(StilettoLoadingIcons || (StilettoLoadingIcons = {}));
export var StilettoEditTypes;
(function (StilettoEditTypes) {
    StilettoEditTypes["editGradient"] = "editGradient";
    StilettoEditTypes["editCrop"] = "editCrop";
    StilettoEditTypes["editBrightness"] = "editBrightness";
    StilettoEditTypes["editGamma"] = "editGamma";
    StilettoEditTypes["editWhiteBalance"] = "editWhiteBalance";
    StilettoEditTypes["editEraser"] = "editEraser";
    StilettoEditTypes["renderFullResoltion"] = "renderFullResoltion";
    StilettoEditTypes["reset"] = "reset";
    StilettoEditTypes["undo"] = "undo";
})(StilettoEditTypes || (StilettoEditTypes = {}));
export var StilettoLoadingActionEditImage;
(function (StilettoLoadingActionEditImage) {
    StilettoLoadingActionEditImage["uploadingImage"] = "uploadingImage";
    StilettoLoadingActionEditImage["reordering"] = "reordering";
    StilettoLoadingActionEditImage["deleteImage"] = "deleteImage";
})(StilettoLoadingActionEditImage || (StilettoLoadingActionEditImage = {}));
export var StilettoLoadingActionGlobal;
(function (StilettoLoadingActionGlobal) {
    StilettoLoadingActionGlobal["splitImages"] = "splitImages";
    StilettoLoadingActionGlobal["submitProduct"] = "submitProduct";
    StilettoLoadingActionGlobal["deleteImages"] = "deleteImages";
    StilettoLoadingActionGlobal["saveForLater"] = "saveForLater";
    StilettoLoadingActionGlobal["uploadAllImages"] = "uploadAllImages";
    StilettoLoadingActionGlobal["uploadNewImages"] = "uploadNewImages";
    StilettoLoadingActionGlobal["makeFeaturedImage"] = "makeFeaturedImage";
    StilettoLoadingActionGlobal["reorderImages"] = "reorderImages";
    StilettoLoadingActionGlobal["flagProduct"] = "flagProduct";
    StilettoLoadingActionGlobal["resolveProduct"] = "resolveProduct";
    StilettoLoadingActionGlobal["escalatedProduct"] = "escalatedProduct";
})(StilettoLoadingActionGlobal || (StilettoLoadingActionGlobal = {}));
